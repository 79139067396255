import { useEffect, useState } from "react";

//api calls
import {
  getClinician,
  insertClinician,
  updateClinician,
} from "../../Transactions";

//page components
import Clinician from "./Clinician";
import Person from "./Person";
import ProfessionalBodies from "./ProfessionalBodies";
import Roles from "./Roles";
import ContactDetails from "../../../Contacts/SectionComponents/ContactDetails";

//static data
import { contactModel } from "../../../Contacts/Utils";
import {
  userGroupOptions,
  professionalBodyOptions,
  personTemplate,
  clinicianTempalte,
} from "./Utils";

//functions
import { handleInputChange } from "../../../../../../SharedComponents/Utils/InputUtils";

//page management
import { CallStates } from "../../../../../../SharedComponents/AppStates/Utils";
import PageState from "../../../../../../SharedComponents/AppStates/PageState";

//button components
import { Button } from "../../../../../../SharedComponents/ButtonComponents/Button";

//treat clinicianDetails like summary and have it update with each other constant, this is how the back end takes it
const ClinicianDetails = ({
  clinicianToEdit = null,
  facilityID,
  setModalIsOpen,
  setParentCallState,
  api,
}) => {
  const [clinician, setClinician] = useState(null);
  const [person, setPerson] = useState(null);
  const [contactMechanism, setContactMechanism] = useState(null);
  const [DBS, setDBS] = useState(null);
  const [professionalBodies, setProfessionalBodies] = useState([]);
  const [availableProfessionalBodies, setAvailableProfessionalBodies] =
    useState(professionalBodyOptions);
  const [roles, setRoles] = useState([]);
  //set the above to any incoming data from a loaded clincian

  const [callState, setCallState] = useState(CallStates.INITIAL);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (clinicianToEdit) {
      getClinician(
        clinicianToEdit.ClinicianID,
        api,
        setCallState,
        setModalIsOpen
      )
        .then((data) => {
          setClinician(data.Clinician);
          setPerson(data.Person);
          setContactMechanism(data.ContactMechanism);

          setRoles(data.UserGroups);

          //extract the dbs from the list of professional bodies,
          if (data.AdditionalProfessionalBodies) {
            data.AdditionalProfessionalBodies.forEach((professionalBody) => {
              if (professionalBody.ProfessionalBodyId === 4840) {
                setDBS(professionalBody);
              }
              setProfessionalBodies(
                data.AdditionalProfessionalBodies.filter(
                  (item) => item.ProfessionalBodyId !== 4840
                )
              );
            });
          }

          setCallState(CallStates.LOADED);
        })
        .catch((e) => {
          setErrorMessage(e.message);
          setCallState(CallStates.ERROR);
        });
    } else {
      setPerson(personTemplate);
      setClinician(clinicianTempalte);
      setContactMechanism(contactModel().ContactDetails);

      setCallState(CallStates.LOADED);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const clinicianDetails = {
      FacilityID: facilityID,
      Clinician: clinician,
      Person: person,
      ContactMechanism: contactMechanism,
      DBS: DBS,
      AdditionalProfessionalBodies: professionalBodies,
      UserGroups: roles,
    };

    if (clinicianDetails.Person.PartyID) {
      updateClinician(api, clinicianDetails, facilityID, setCallState)
        .then(() => {
          setCallState(null); //clean up the call state
          setParentCallState(CallStates.SAVED); //set parent call state so it reloads with the updated clinician
          setModalIsOpen(false); //close the modal
        })
        .catch((e) => {
          setErrorMessage(e.message);
          setCallState(CallStates.ERROR);
        });
    } else {
      insertClinician(api, clinicianDetails, facilityID, setCallState)
        .then(() => {
          setCallState(null);
          setParentCallState(CallStates.SAVED); //set parent call state so it reloads with the new clinician
          setModalIsOpen(false);
        })
        .catch((e) => {
          setErrorMessage(e.message);
          setCallState(CallStates.ERROR);
        });
    }
  };

  return (
    <PageState callState={callState} errorMessage={errorMessage}>
      <form
        onSubmit={(event) => handleSubmit(event)}
        style={{ margin: "20px" }}
      >
        {person && (
          <Person
            person={person}
            handleChange={(e) => handleInputChange(e, person, setPerson)}
          />
        )}
        {contactMechanism && (
          <ContactDetails
            readOnly={false}
            contactDetails={contactMechanism}
            handleContactDetailsChange={(details) =>
              setContactMechanism(details)
            }
          />
        )}
        {clinician && (
          <Clinician
            clinician={clinician}
            handleChange={(e) => handleInputChange(e, clinician, setClinician)}
          />
        )}
        {roles && person && (
          <Roles
            parentRoles={roles}
            setParentRoles={setRoles}
            availableOptions={userGroupOptions}
            disabled={person.PartyID ?? false}
          />
        )}
        {professionalBodies && person && (
          <ProfessionalBodies
            clinicianProfessionalBodies={professionalBodies}
            clinicianDBSDetails={DBS}
            setClinicianProfessionalBodies={setProfessionalBodies}
            setClinicianDBSDetails={setDBS}
            professionalBodiesOptions={availableProfessionalBodies}
            required={
              person.JobTitle === "Physiotherapist" ||
              person.JobTitle === "Mental Health Practitioner"
            }
          />
        )}
        <div style={{ padding: "0.5rem 0rem" }}>
          <Button label='Save clinician' handleClick={() => {}} type='submit' />
        </div>
      </form>
    </PageState>
  );
};
export default ClinicianDetails;
