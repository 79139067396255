import { CallStates } from "./Utils";
import { RotatingIprsIconWithText, IprsIconWithText } from "./CallStates";

const PageState = ({ callState, errorMessage, children }) => {
  if (callState === CallStates.LOADING || callState === CallStates.INITIAL) {
    return <RotatingIprsIconWithText displayText='Loading...' />;
  } else if (callState === CallStates.SAVING) {
    return <RotatingIprsIconWithText displayText='Saving...' />;
  } else if (callState === CallStates.SAVED) {
    return <IprsIconWithText displayText='Saved!' />;
  } else if (callState === CallStates.ERROR) {
    return <IprsIconWithText displayText='Error.' message={errorMessage} />;
  } else if (callState === CallStates.SENT) {
    return <IprsIconWithText displayText='Sent!' />;
  } else if (callState === CallStates.SENDING) {
    return <IprsIconWithText displayText='Sending...' message={errorMessage} />;
  } else if (callState === CallStates.LOADED) {
    return children;
  }
};
export default PageState;
