import "../Styles/SharedGenericStyles.css"

export const CheckboxInputWithLabel = ({
  inputLabel,
  value,
  name,
  disabled = false,
  handleChange,
}) => {
  return (
    <div className='clinic-profile-InLine container-100-justified-content'>
      <div>
        <label>{inputLabel}: </label>
      </div>
      <div className='container-50-align-left'>
        <input
          type='checkbox'
          name={name}
          checked={value}
          onChange={(e) => handleChange(e)}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export const CheckboxInput = ({
  value,
  name,
  handleChange,
  disabled = false,
}) => {
  return (
    <div>
      <input
        type='checkbox'
        name={name}
        checked={value}
        onChange={(e) => handleChange(e)}
        disabled={disabled}
      />
    </div>
  );
};
