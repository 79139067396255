import { getFullFilePath } from "../utils/getFullFilePath.js";

export const apiRequestInsertUserFile = async (api, pathName, guid, fileName, partyId, addNewFileInFileTree) => {
    const params = {
        FullFilePath: getFullFilePath(pathName, fileName),
        GUID: guid,
        UserPartyID: partyId
    };

    try {
        const response = await api.transact("InsertUserFile", params);
        addNewFileInFileTree({
            FullFilePath: getFullFilePath(pathName, fileName),
            GUID: guid
        });
    } catch (error) {
        console.error(error);
        throw error; // rethrow for handling outside
    }
}