import React from "react";

const ClientSettingSaveButton = ({
    isChanged,
    isDefault,
    editButtonHandler
}) => {

    const clickHandler = (event, dlt) => {
        event.preventDefault();
        editButtonHandler(dlt);
    };

    return (
        <>
            {
                // Add button should be displayed when Default is true and change has been made in the input field
                !isDefault ? (
                    <>
                        <button
                            // Edit should be disabled when no change is found.
                            disabled={!isChanged}
                            className="clientSettingListTableButton"
                            onClick={event => clickHandler(event, false)}
                        >
                            Edit
                        </button>
                        <button
                            className="clientSettingListTableButton"
                            onClick={event => clickHandler(event, true)}
                        >
                            Reset
                        </button>
                    </>
                ) : (
                    <button
                        disabled={!isChanged}
                        className="clientSettingListTableButton"
                        onClick={event => clickHandler(event, false)}
                    >
                        Add
                    </button>
                )
            }
        </>
    );
};

export default ClientSettingSaveButton;
