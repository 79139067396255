import "./SearchInput.css";

export const SearchInput = ({ data, setFilteredData, filterProperties }) => {
  const handleFilterChange = (value) => {
    const filteredData = data.filter((item) => {
      return filterProperties.some((property) => {
        const propValue = item[property]?.toLowerCase();
        return propValue && propValue.includes(value.toLowerCase());
      });
    });
    setFilteredData(filteredData);
  };

  return (
    <div>
      <input
        className='search-box'
        type='text'
        placeholder='Enter search text here...'
        onChange={(e) => handleFilterChange(e.target.value)}
      />
    </div>
  );
};
