import React, { useEffect, useState, useRef, useCallback }          from "react";
import { TwoSwitch, LoadingIndicator } from "../../lib/iprs-react-library/src/index";

import './styles.css';
import BlockingMessage, { getBlockingMessage } from "./BlockingMessage";
import getParamsFromURL from "./getParamsFromURL";
import CorrespondenceForm from "./correspondence/CorrespondenceForm";
import TabbedFormWrapper from "./TabbedFormWrapper";
import areFieldsValid from "./validationFunction";

const autosavePeriod = 
    function () {
        const autosavePeriodInt = Number.parseInt(process.env.REACT_APP_EFORM_AUTO_SAVE_PERIOD);
        const autosavePeriodFilter = Number.isNaN(autosavePeriodInt)? (5 * 60): autosavePeriodInt;
        return autosavePeriodFilter >= 30? autosavePeriodFilter: 30;
    }();

const CORRESPONDENCE_CNTRL_TYPE = 'correspondence';

const Eform = ({api, getURLPathArray, getURLSearchParam }) => {
    
    const urlInfo                                   = getParamsFromURL( getURLPathArray, getURLSearchParam );

    return <TwoSwitch test={urlInfo.urlHasInfo} >
        <EformInner api={api} urlInfo={urlInfo} />
        <p>Not enough information in the URL</p>
    </TwoSwitch>
}

// Prevent the user leaving the case page with unsaved data
const beforeUnloadHandler = (event) => {
    event.preventDefault();
    // Included for legacy support, e.g. Chrome/Edge < 119
    event.returnValue = true;
  };

const EformInner = ({api, urlInfo}) => {
    
    const { caseid, reportid, isInitial, isPharos }                               
                                                            = urlInfo;

    const [tabsFieldDefinitions, setTabsFieldDefinitions]   = useState([]);

    const [errorMsg, setErrorMsg]                           = useState('Loading');

    const [submitted, setSubmitted]                         = useState(null);

    const [dataIsValid ,    setDataIsValid]                 = useState(false);

    // Adding isPharos allows the forms to be different depending on the system we are dealing with
    const [formFieldData, setFormFieldData]                 = useState();
    const setFormFieldDataToForForm = (newData) => {
        window.addEventListener("beforeunload", beforeUnloadHandler);
        const newFormFieldData = {...formFieldData, ...newData};
        setFormFieldData(newFormFieldData);
        const formValid = areFieldsValid(tabsFieldDefinitions, newFormFieldData);
        setDataIsValid(formValid);
    }

    const reportStatus                                      =  formFieldData?.Report?.ReportStatus?? 0;

    const tabsFieldDefinitionsToUse = ( isPharos && 2 === reportStatus )? [ ...tabsFieldDefinitions, {
        "name": "correspondence",
        "text": "Correspondence",
        "Question": [
            {
                name: CORRESPONDENCE_CNTRL_TYPE,
                text: CORRESPONDENCE_CNTRL_TYPE,
                controlType: CORRESPONDENCE_CNTRL_TYPE
            }
        ]
    }] : tabsFieldDefinitions;

    const [currentTab, setCurrentTab]       = useState('');

    const [listOfTabs, setListOfTabs] = useState([])

    const [currentTabIndex, setCurrentTabIndex] = useState(null);

    // When tabsFieldDefinition is loaded, listOfTabs array will
    // be populated with name of tabs.
    useEffect(()=>{
        if(tabsFieldDefinitions !== []){
            let tabList = tabsFieldDefinitions.map(tab=> tab.name)
            setListOfTabs(tabList)
        }
    },[tabsFieldDefinitions])

    // When listOfTabs is not an empty array, currentTab will be set,
    // default state value of currentTabsNumber is 0 and will 
    // select the first tab when this Eform component is first loaded
    useEffect(()=>{
        if(listOfTabs.length !== 0){
            if(currentTabIndex === null){
                setCurrentTab(listOfTabs[0])
            }
        }
    },[currentTabIndex, listOfTabs]);

    // useEffect to change the currentTabIndex when user clicks tab button
    // so the prev / next buttons are synched with tabs being displayed
    useEffect(()=>{
        if(listOfTabs !== [] && currentTab !== ''){
            if(listOfTabs.indexOf(currentTab) !== currentTabIndex){
                setCurrentTabIndex(listOfTabs.indexOf(currentTab));
            }
        }

    },[currentTabIndex, currentTab, listOfTabs, formFieldData])

    // PREV / NEXT BUTTONS
    // function to change the currentTab and currentTabIndex
    const changeCurrentTabAndIndex = (newTabIndex) => {
        setCurrentTab(listOfTabs[newTabIndex]);
        setCurrentTabIndex(newTabIndex);
    }

    // Handler for prev / next buttons
    const prevNextButtonHandler = e => {
        e.preventDefault();
        const action = e.target.name;

        if(action === 'prev'){
            const newTabIndex = currentTabIndex > 0 ? currentTabIndex - 1 : 0;
            changeCurrentTabAndIndex(newTabIndex)
        } else if ('next') {
            const newTabIndex = currentTabIndex !== currentTabIndex - 1 ? currentTabIndex + 1 : 0;
            changeCurrentTabAndIndex(newTabIndex)
        }

        // Whenever current tab is changed, saveForm() function will
        // run to make the API request.
        if(formFieldData !== {}){
            saveFormHandler(); // disable for now - may have a spinner for this
        }
    }
    
    // Submit Handler
    const onSubmit = () => {
        
        const params = {
            "CaseID": caseid,
            "ReportID": reportid,
            "Data": formFieldData
        }

        setErrorMsg('Submitting Form');
        api.transact("EformComplete", params)
        .then(r => r.apiResult)
        .then(r => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
            const tabsFieldDefinitions = r.Report.Page.Tab?? [];
            setTabsFieldDefinitions(tabsFieldDefinitions);
            const newFormFieldData = r.Report.Answers?? {};
            setFormFieldData(newFormFieldData);
            const formValid = areFieldsValid(tabsFieldDefinitions, newFormFieldData);
            setDataIsValid(formValid);
            setErrorMsg('You have submitted this form');
            setSubmitted(true);
        })
        .catch(err => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
            setErrorMsg(err.message);
            handleError(api, setErrorMsg);
        });
    }

    // useState for spinner.
    const [spinnerSwitch, setSpinnerSwitch] = useState(false)

    // function use to make API request to save the form.
    // spinnerSwitch state will be turned to true when this function is called.
    // When response is received, it turns the spinnerSwitch to false.
    const saveForm = () => {
        
        setSpinnerSwitch(true)

        const messageID = isInitial ? 'NetworkEformInitialSave' :  'NetworkEformFollowUpSave';

        const params = {
            "CaseID": caseid,
            "ReportID": reportid,
            "Data": formFieldData
        }

        api.transact(messageID, params)
        .then(r => r.apiResult)
        .then(r => {
            applyReceivedFormData(r);

            setErrorMsg('');
            setSpinnerSwitch(false);
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        })
        .catch(err => {

            if(err.networkOK && err?.apiResult?.Report){
                applyReceivedFormData(err.apiResult);
            }else{
                loadForm(); // fixes error
            }

            setErrorMsg(err.message);
            setSpinnerSwitch(false);
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        });
    }

    const applyReceivedFormData = useCallback((r) => {
        const caseType = r.CaseType?.toLowerCase();
        if('msk' === caseType){
            /* we don't handle MSK at the moment and the only way
            to avoid loading is to use a differenmt URL for which the
            proxy looks out */
            window.location.replace( window.location.href + '&caseType=MSK' );
        }
        const report = r?.Report;

        if(typeof report === 'undefined' || report === null){
            throw new Error('No report in response');
        }else{
            const tabsFieldDefinitions = r.Report.Page.Tab?? [];
            setTabsFieldDefinitions(tabsFieldDefinitions);
            const newFormFieldData = r.Report.Answers?? {};
            setFormFieldData(newFormFieldData);
            const formValid = areFieldsValid(tabsFieldDefinitions, newFormFieldData);
            setDataIsValid(formValid);

            setErrorMsg('');
        }

        

    }, [setTabsFieldDefinitions, setFormFieldData, setErrorMsg]);
    
    const loadForm = useCallback(() => {

        const params = {caseid, reportid}; // note case is correct
    
        const messageID = isInitial? 
            'NetworkEformInitialLoad':
            'NetworkEformFollowUpLoad';
            
        setErrorMsg('Loading Form');
        api.transact(messageID, params)
            .then(r => r.apiResult)
            .then(r => applyReceivedFormData(r))
            .catch(err => {
                setErrorMsg(err.message);
                handleError(api, setErrorMsg);
            });
    }, [applyReceivedFormData, caseid, reportid, api, isInitial]);

    useEffect(() => {
        loadForm();
    }, [loadForm]);

    // State used to reset the timer when form is saved.
    const [resetOrder, setResetOrder] = useState(false);

    useEffect(()=>{
        setResetOrder(true);
    },[currentTab])

    // handler function to save the form and also prevent default behavior
    const saveFormHandler = (e) => {
        e?.preventDefault();
        saveForm();
        setResetOrder(true);
    }

    // Submit button should be disabled unless the required fields are filled with valid inputs.
    const blockingMessage = getBlockingMessage(isPharos, reportStatus, isInitial, submitted, errorMsg);

    // variable to store boolean value that is returned by conditional statement using blockingMessage and dataIsValid.
    const disableSubmit = (blockingMessage?.message?? false) || dataIsValid !== true ? true : false;

    const isBeforeLastTab = isPharos?
        (currentTabIndex + 1 < listOfTabs.length):
        (currentTabIndex + 2 < listOfTabs.length);  // Fudge as there's a hidden tab in portal
    
    // JSX component that renders the next / prev buttons.
    const buttons = (
        <>
            <div className="tabPrevNextButtons">
                {/* Prev button only shows when currentTabIndex is greater than 0
                * which means when the first tab is selected 'Previous' button should be hidden
                */}
                {currentTabIndex > 0
                    ? <button name='prev' onClick={prevNextButtonHandler} className="button">Previous</button> 
                    : <></>
                }

                {/* When the selected tab is not the last tab in the list, Next button will 
                * be displayed and if it is the last button, it will be swapped with 'Submit' button 
                */}
                <TwoSwitch test={isBeforeLastTab} >
                    <button name='next' onClick={prevNextButtonHandler} className="button">Next</button>
                    <button disabled={disableSubmit} onClick={() => onSubmit()} className="button">Submit</button>
                </TwoSwitch>
            </div>
            {/* displays countdown timer and save link at the bottom of prev/next/submit buttons.
                Timer component takes 'countdownTime' props which is used to set the interval time. */}
            <div className="saveButtonAndTimer">
                <TwoSwitch test={!blockingMessage}>
                        <p>{'Auto-saving in '}<Timer countdownTime={autosavePeriod} saveForm={saveForm} resetOrders={{resetOrder, setResetOrder}} />,&nbsp;<a className="saveLink" href="." onClick={saveFormHandler}>{'click here'}</a>&nbsp;to save now</p>
                </TwoSwitch>
            </div>
        </>
    )

    // Tab Click handler function: it sets the currentTab state to clicked tab
    // and also saves the form 
    const tabClickHandler = (tabName) => {
        if(tabName !== currentTab){
            setCurrentTab(tabName)
            if(formFieldData !== {}){
                saveForm(); // disable for now - may have a spinner for this
            }
        }
    }

    const overrideComponent = fieldDefinition => {
        if (CORRESPONDENCE_CNTRL_TYPE === fieldDefinition.controltype){
            return props => <CorrespondenceForm {...{api, caseid, reportid}} />
        }else{
            return null;
        }
    }

    const formFieldDataToUse = {...formFieldData, __frontend:{ Pharos: isPharos } };
    return (
        <>
            <TwoSwitch test={spinnerSwitch} ><Spinner /></TwoSwitch>
            <TwoSwitch test={submitted} >
                <BlockingMessage {...{...blockingMessage}} />
                <>
                    <TwoSwitch test={blockingMessage}>
                        <BlockingMessage {...{...blockingMessage}} />
                    </TwoSwitch>
                    <TabbedFormWrapper
                        tabsFieldDefinitions={tabsFieldDefinitionsToUse}
                        currentFormTab={currentTab}
                        onTabClick={e => tabClickHandler(e.target.name)}
                        formFieldData={formFieldDataToUse}
                        setFormFieldData={setFormFieldDataToForForm}
                        setDataIsValid={setDataIsValid}
                        validating={true} 
                        createOptionalOverrideFieldComponent={overrideComponent} />
                    {buttons}
                </>
            </TwoSwitch>
        </>
    )
}

// Custom hook to use setInterval with react.
// The closure inside setInterval() will only have access to whatever variables and values were
// available when it got instantiated. To make fresh values to be available every time the interval runs
// variable that is mutable by react needs to be created. To do so, useRef() is used.
// useInterval is a wrapper function that will be called inside the useInterval() which will
// never change the function itself passed to setInterval but the value enclosed ref will always 
// be up to date when it's called.
 
const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(()=>{
        savedCallback.current = callback;
    },[callback]);

   useEffect(()=>{
        const tick = () => {
            savedCallback.current();
        }

        let id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
}

// Timer component that is placed under the Prev/Next/Submit buttons.
const Timer = ({countdownTime, saveForm, resetOrders}) => {

    const [seconds, setSeconds] = useState(countdownTime);
    const [convertedTime, setConvertedTime] = useState('0:00');

    const {resetOrder, setResetOrder} = resetOrders;

    // Using custom useInterval hook instead of using setInterval.
    useInterval(()=>{
        setSeconds(seconds - 1);
    }, 1000);

    const secondsToMinutesConversion = (time) => {
        let minutes = time / 60;
        let seconds = time % 60 < 10 ? '0' + (time % 60).toString() : time % 60;
        
        const newTime = Math.floor(minutes) + ':' + seconds;

        setConvertedTime(newTime);
    }

    const countDownReset = useCallback( () => {
        setSeconds(countdownTime);
    }, [setSeconds, countdownTime]);

    useEffect(()=>{
        if(resetOrder === true){
            countDownReset()
            setResetOrder(false)
        }
    },[resetOrder, setResetOrder, countDownReset])

    useEffect(()=>{
        if(seconds <= 0){
            
            saveForm();
            countDownReset();
        }
        secondsToMinutesConversion(seconds);
    },[seconds, countdownTime, saveForm, countDownReset])

    return <>{convertedTime}</>
}


// Spinner component.
const Spinner = () => {

    const loaderCssOverride = {
        borderColor: "rgb(255 210 239 / 66%) rgb(255 210 239 / 66%) #dd6cae"
    }

    return <div className="loader">
        <div className="loaderBackground">
            <LoadingIndicator loading={true} color={'#BD5196'} speed={0.5} size={42} cssOverride={loaderCssOverride} />
            <p style={{ marginTop: '26px', fontSize: '16px', fontWeight: '600', color: '#646363' }}>Please wait...</p>
        </div>
    </div>;
}

const handleError = (api, setErrorMsg) => {
    api.validateLoggedIn()
        .then(v=>console.log('Test login OK', v))
        .catch(e=> setErrorMsg('Sorry: Your login has expired. Please logout and relogin to this site'));
}

export {EformInner};
export default Eform;
