import { formatDate } from "../../../../../../SharedComponents/Utils/DateUtils";
import { DateInput } from "../../../../../../SharedComponents/InputComponents/DateInput";

import "../../../../../../SharedComponents/Styles/SharedTableStyles.css";
import { TextInput } from "../../../../../../SharedComponents/InputComponents/TextInput";

const FacilityInsuranceTable = ({ handleChange, facility }) => {
  const {
    ELAmount,
    ELPolicyNo,
    ELInsurerName,
    ELExpiryDate,
    PIAmount,
    PIPolicyNo,
    PIInsurerName,
    PIExpiryDate,
    PLAmount,
    PLPolicyNo,
    PLInsurerName,
    PLExpiryDate,
  } = facility;
  return (
    <div className="react-shared-table">
    <table className='background-color '>
        <thead>
            <tr>
            <th></th>
            <th>Employers Liability Insurance</th>
            <th className='pli-th'>
                Public Liability Insurance*&dagger;
            </th>
            <th className='mmi-th'>
                Medical Malpractice*&dagger;
            </th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>Amount (&pound;)</td>
            <td>
                <TextInput
                name="ELAmount"
                value={ELAmount}
                handleChange={handleChange}
                />
            </td>
            <td>
                <TextInput
                name="PLAmount"
                value={PLAmount}
                handleChange={handleChange}
                />
            </td>
            <td>
                <TextInput
                name="PIAmount"
                value={PIAmount}
                handleChange={handleChange}
                />
            </td>
            </tr>
            <tr>
            <td>Policy Number</td>
            <td>
                <TextInput
                name="ELPolicyNo"
                value={ELPolicyNo}
                handleChange={handleChange}
                />
            </td>
            <td>
                <TextInput
                name="PLPolicyNo"
                value={PLPolicyNo}
                handleChange={handleChange}
                />
            </td>
            <td>
                <TextInput
                name="PIPolicyNo"
                value={PIPolicyNo}
                handleChange={handleChange}
                />
            </td>
            </tr>
            <tr>
            <td>Insurer Name</td>
            <td>
                <TextInput
                name="ELInsurerName"
                value={ELInsurerName}
                handleChange={handleChange}
                />
            </td>
            <td>
                <TextInput
                name="PLInsurerName"
                value={PLInsurerName}
                handleChange={handleChange}
                />
            </td>
            <td>
                <TextInput
                name="PIInsurerName"
                value={PIInsurerName}
                handleChange={handleChange}
                />
            </td>
            </tr>
            <tr>
            <td>Expiry Date</td>
            <td>
                <DateInput
                name="ELExpiryDate"
                value={formatDate(ELExpiryDate ?? "")}
                handleChange={handleChange}
                />
            </td>
            <td>
                <DateInput
                name="PLExpiryDate"
                value={formatDate(PLExpiryDate ?? "")}
                handleChange={handleChange}
                />
            </td>
            <td>
                <DateInput
                name="PIExpiryDate"
                value={formatDate(PIExpiryDate ?? "")}
                handleChange={handleChange}
                />
            </td>
            </tr>
        </tbody>
        </table>
        <p style={{paddingTop: "0.6rem", paddingLeft:"0.6rem"}}>* Expected level of cover for MSK claims: £5,000,000 (five million pounds)</p>
        <p style={{paddingTop: "0.6rem", paddingLeft:"0.6rem"}}>&dagger; Expected level of cover for Mental Health claims: £1,000,000 (one million pounds)</p>
    </div>
  );
};
export default FacilityInsuranceTable;
