import { useState, useEffect } from "react";

//api calls
import { getFeesData, deleteFee } from "./Transactions";

//page components
import FeesList from "./SectionComponents/FeesList";
import Fee from "./SectionComponents/Fee";
import { ExpandCollapseSection } from "../../../../SharedComponents/ButtonComponents/CollapseExpandButton";
import { Button } from "../../../../SharedComponents/ButtonComponents/Button";

//styling
import "../../../../SharedComponents/Styles/SharedGenericStyles.css";
import "../../../../SharedComponents/Styles/FadeStyles.css";

//modal components
import Modal from "../../../../SharedComponents/ModalComponents/Modal";

//static data
import { feeModel } from "./Utils";
import { fee } from "../../../../SharedComponents/ModalComponents/Utils";

//page mangement
import { CallStates } from "../../../../SharedComponents/AppStates/Utils";
import PageState from "../../../../SharedComponents/AppStates/PageState";

const Fees = ({ api, facilityID}) => {
  const [fees, setFees] = useState(null);
  const [feeHistory, setFeeHistory] = useState(null);
  const [serviceCharges, setServiceCharges] = useState(null);
  const [clients, setClients] = useState(null);

  const [feeToEdit, setFeeToEdit] = useState(null);
  const [feeToAdd, setFeeToAdd] = useState(feeModel(facilityID));
  const [isEdit, setIsEdit] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(null);
  const [callState, setCallState] = useState(CallStates.INITIAL);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (callState === CallStates.INITIAL || callState === CallStates.SAVED)
      getFeesData(api, facilityID, setCallState)
        .then((data) => {
          setFees(data.CurrentFees);
          setFeeHistory(data.FeeHistory);
          setServiceCharges(data.ServiceCharges);
          setClients(data.ClientList);
          setCallState(CallStates.LOADED);
        })
        .catch((e) => {
          setErrorMessage(e.message);
          setCallState(CallStates.ERROR);
        });
  }, [callState]);

  useEffect(() => {
    if (feeToEdit) {
      setIsEdit(true);
      setModalIsOpen(true);
    } else {
      setIsEdit(false);
    }
  }, [feeToEdit]);

  const closeModal = () => {
    setFeeToEdit(null);
    setModalIsOpen(false);
  };

  const toggleExpanded = () => {
    if (isExpanded !== null) {
      setIsExpanded((prevState) => !prevState);
    } else {
      setIsExpanded(true);
    }
  };

  return (
    <PageState callState={callState} errorMessage={errorMessage}>
      <div className='fade-in' style={{ padding: "2rem" }}>
        {serviceCharges && clients && (
          <div className='padding2'>
            <div style={{ marginBottom: "0.5rem" }}>
              <Button
                label='New rate'
                handleClick={() => setModalIsOpen(true)}
              />
            </div>
            <FeesList
              fees={fees}
              serviceChargeList={serviceCharges}
              clientList={clients}
              setFeeToEdit={setFeeToEdit}
              handleRemove={(fee) =>
                deleteFee(api, fee, setCallState).catch((e) => {
                  setErrorMessage(e.message);
                  setCallState(CallStates.ERROR);
                })
              }
            />
          </div>
        )}
        {feeHistory && serviceCharges && clients && (
          <div className='padding2'>
            <div className='container-25 clinic-profile-headers'>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h2 style={{ paddingBottom: "5px", paddingRight: "10px" }}>
                  Fee History
                </h2>
                <ExpandCollapseSection
                  handleClick={() => toggleExpanded()}
                  isExpanded={isExpanded}
                />
              </div>
            </div>

            {isExpanded !== null && (
              <div className={isExpanded ? "fade-in" : "fade-out"}>
                <FeesList fees={feeHistory} />
              </div>
            )}
          </div>
        )}
        <Modal isOpen={modalIsOpen} onClose={() => closeModal()} size={fee}>
          <div style={{ padding: "2rem 1rem 0rem 1rem", width: "50rem" }}>
            {serviceCharges && clients && !isEdit && (
              <Fee
                feeFromParent={feeToAdd}
                api={api}
                serviceChargeList={serviceCharges}
                clientList={clients}
                facilityID={facilityID}
                setErrorMessage={setErrorMessage}
                setCallState={setCallState}
                closeModal={closeModal}
              />
            )}
            {serviceCharges && clients && isEdit && (
              <Fee
                feeFromParent={feeToEdit}
                api={api}
                serviceChargeList={serviceCharges}
                clientList={clients}
                facilityID={facilityID}
                setErrorMessage={setErrorMessage}
                setCallState={setCallState}
                closeModal={closeModal}
              />
            )}
          </div>
        </Modal>
      </div>
    </PageState>
  );
};
export default Fees;
