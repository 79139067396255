export const mergeClosures = (existingClosures, newClosures) => {
  const mergedArray = existingClosures
    .concat(newClosures)
    .reduce((acc, obj) => {
      const existingObj = acc.find(
        (item) => item.DateFrom === obj.DateFrom && item.DateTo === obj.DateTo
      ); //this is a safety measure to make sure we don't double up on closures
      if (!existingObj) {
        acc.push(obj);
      }
      return acc;
    }, []);

  return mergedArray;
};

//this makes sure an additional closure doesn't overlap with an existing one (or start/finish within an existing one)
export const validateClosure = (closure, existingClosures) => {
  const isValidWithExisting = existingClosures.every(
    (item) => !doesOverlap(closure, item)
  );

  return isValidWithExisting;
};

const doesOverlap = (closure, item) => {
  if (item.ActionFlag === 4) return false;
  const itemDateFrom = new Date(item.DateFrom);
  const itemDateTo = new Date(item.DateTo);
  const closureDateFrom = new Date(closure.DateFrom);
  const closureDateTo = new Date(closure.DateTo);

  return closureDateFrom < itemDateTo && closureDateTo > itemDateFrom;
};

export const flagClinicAsSetAsDisused = (event, setClinicSetToDisused) => {
  if(event.target.name === "Disused") {
    event.target.checked ? setClinicSetToDisused(true) : setClinicSetToDisused(false);
  }
  return;
}