import React, { useState, useEffect, useCallback, useMemo } from "react";
import FileTreeUploadButton from "./FileTreeUploadButton.jsx";
import FileRow from "./FileRow.jsx";
import DuplicateFileUploadRow from "./DuplicateFileUploadRow.jsx";
import AddDirectoryInFileTree from "./AddDirectoryInFileTree.jsx";
import NewDirectoryInputRow from "./NewDirectoryInputRow.jsx";

// Custom hook to manage directories and files
const useDirectoryState = (directory) => {
    const [prevDirectory, setPrevDirectory] = useState([]);
    const [newDirectories, setNewDirectories] = useState([]);
    const [newFiles, setNewFiles] = useState([]);

    useEffect(() => {
        setPrevDirectory(directory.Directories || []);
    }, [directory]);

    return {
        prevDirectory,
        newDirectories,
        newFiles,
        setNewDirectories,
        setNewFiles,
    };
};

// DirectoryRow Component
const DirectoryRow = React.memo(
    ({
        directory,
        api,
        parentPath,
        fetchUpdatedFileTree,
        partyId,
        currentUrl,
        collapse,
    }) => {
        const [expanded, setExpanded] = useState(false);
        const [showNewDirectoryInputRow, setShowNewDirectoryInputRow] = useState(false);
        const [showDuplicateFileRow, setShowDuplicateFileRow] = useState(false);
        const [fileUpdateObj, setFileUpdateObj] = useState({});

        const {
            prevDirectory,
            newDirectories,
            newFiles,
            setNewDirectories,
            setNewFiles,
        } = useDirectoryState(directory);

        useEffect(() => {
            if (!collapse) {
                setExpanded(true);
            }
        }, [collapse]);

        const toggleExpand = (e) => {
            e.preventDefault();
            setExpanded(!expanded);
        };

        const nodePath = useMemo(() => {
            return directory.Name === "root" && parentPath === ""
                ? ""
                : `${parentPath}/${directory.Name}`;
        }, [directory.Name, parentPath]);

        const addNewDirectoryInThisDirectory = useCallback(() => {
            setShowNewDirectoryInputRow(true);
        }, []);

        const checkDuplicateDirectoryName = useCallback(
            (directoryNameToAdd) => {
                return (
                    prevDirectory.some((obj) => obj.Name === directoryNameToAdd) ||
                    newDirectories.some((obj) => obj.Name === directoryNameToAdd)
                );
            },
            [prevDirectory, newDirectories]
        );

        const addNewDirectoryInFileTree = useCallback(
            (directoryName) => {
                if (checkDuplicateDirectoryName(directoryName)) {
                    return "That name already exists in this directory.";
                }
                setNewDirectories((dirs) => [
                    { Name: directoryName, Directories: [], Files: [] },
                    ...dirs,
                ]);
                return "";
            },
            [checkDuplicateDirectoryName, setNewDirectories]
        );

        const addNewFileInFileTree = useCallback(
            (NewFileNode) => {
                setNewFiles((files) => [...files, NewFileNode]);
            },
            [setNewFiles]
        );

        const toggleShowDuplicateFileRow = (bool = false, pathName, files) => {
            setFileUpdateObj({ pathName, files });
            setShowDuplicateFileRow(bool);
        };

        return (
            <div className="iprsPortalFileTreeDirectoryItem" key={nodePath}>
                <div className="iprsPortalFileTreeDirectoryIconAndNameDiv">
                    <div className="iprsPortalFileTreeDirectoryIconDiv">
                        <div
                            className="iprsPortalFileTreeDirectioryIconsAndName"
                            onClick={toggleExpand}
                        >
                            {expanded ? (
                                <svg width="20" height="20">
                                    <use href="#icon-chevron-down" />
                                </svg>
                            ) : (
                                <svg width="20" height="20">
                                    <use href="#icon-chevron-right" />
                                </svg>
                            )}
                            <svg width="20" height="20">
                                <use href="#icon-folder" />
                            </svg>
                            <div className="iprsPortalFileTreeNameForDirectoryAndFile">
                                <strong>{directory.Name}</strong>
                            </div>
                        </div>
                    </div>
                    {currentUrl && (
                        <>
                            <FileTreeUploadButton
                                api={api}
                                pathName={nodePath}
                                addNewFileInFileTree={addNewFileInFileTree}
                                partyId={partyId}
                                toggleShowDuplicateFileRow={toggleShowDuplicateFileRow}
                            />
                            <AddDirectoryInFileTree
                                nodePath={nodePath}
                                addNewDirectoryInThisDirectory={addNewDirectoryInThisDirectory}
                            />
                        </>
                    )}
                </div>

                {showDuplicateFileRow && <DuplicateFileUploadRow fileUpdateObj={fileUpdateObj} />}
                {showNewDirectoryInputRow && (
                    <NewDirectoryInputRow addNewDirectoryInFileTree={addNewDirectoryInFileTree} />
                )}

                {expanded && (
                    <div style={{ paddingLeft: "20px" }}>
                        {newDirectories.map((dir, i) => (
                            <DirectoryRow
                                key={`${parentPath}/${dir.Name}-newDirectoryRows-${i}`}
                                directory={dir}
                                api={api}
                                parentPath={nodePath}
                                partyId={partyId}
                                fetchUpdatedFileTree={fetchUpdatedFileTree}
                                currentUrl={currentUrl}
                                collapse={collapse}
                            />
                        ))}

                        {directory.Directories.map((dir, i) => (
                            <DirectoryRow
                                key={`${parentPath}${dir.Name}prevDirectoryRows-${i}`}
                                directory={dir}
                                api={api}
                                parentPath={nodePath}
                                partyId={partyId}
                                fetchUpdatedFileTree={fetchUpdatedFileTree}
                                currentUrl={currentUrl}
                                collapse={collapse}
                            />
                        ))}

                        {newFiles.map((file, i) => (
                            <FileRow
                                key={`${parentPath}${file.Name}${file.GUID}-newFileRows-${i}`}
                                file={file}
                                api={api}
                                partyId={partyId}
                                fetchUpdatedFileTree={fetchUpdatedFileTree}
                                currentUrl={currentUrl}
                            />
                        ))}

                        {directory.Files.map((file, i) => (
                            <FileRow
                                key={`${parentPath}${file.Name}${file.GUID}-prevFileRows-${i}`}
                                file={file}
                                api={api}
                                partyId={partyId}
                                fetchUpdatedFileTree={fetchUpdatedFileTree}
                                currentUrl={currentUrl}
                            />
                        ))}
                    </div>
                )}
            </div>
        );
    },
    (prevProps, nextProps) => {
        return prevProps.directory === nextProps.directory;
    }
);

export default DirectoryRow;
