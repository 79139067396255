//input components
import { TextInputWithLabel } from "../../../../../../SharedComponents/InputComponents/TextInput";
import { DateInputWithLabel } from "../../../../../../SharedComponents/InputComponents/DateInput";
import { CheckboxInputWithLabel } from "../../../../../../SharedComponents/InputComponents/CheckboxInput";
import { SelectInputWithLabel } from "../../../../../../SharedComponents/InputComponents/SelectInput";

//static data
import {
  personTitleOptions,
  personGenderOptions,
  jobTitleOptions,
} from "./Utils";

const Person = ({ person, handleChange }) => {
  const {
    PersonalTitle = "",
    FirstName = "",
    MiddleNames = "",
    LastName = "",
    BirthDate = "",
    Gender = "",
    CanSpeakAnotherLanguage = false,
    AdditionalLanguages = "",
    JobTitle = "",
  } = person;

  return (
    <div>
      {person && (
        <>
          <SelectInputWithLabel
            inputLabel='Title'
            name='PersonalTitle'
            value={PersonalTitle}
            options={personTitleOptions}
            labelProperty='name'
            valueProperty='value'
            handleChange={handleChange}
          />
          <TextInputWithLabel
            inputLabel='Forename'
            name='FirstName'
            value={FirstName}
            handleChange={handleChange}
            required={true}
          />
          <TextInputWithLabel
            inputLabel='Middle names'
            name='MiddleNames'
            value={MiddleNames}
            handleChange={handleChange}
          />
          <TextInputWithLabel
            inputLabel='Surname'
            name='LastName'
            value={LastName}
            handleChange={handleChange}
            required={true}
          />
          <DateInputWithLabel
            inputLabel='Date of birth'
            name='BirthDate'
            value={BirthDate}
            handleChange={handleChange}
          />
          <SelectInputWithLabel
            inputLabel='Gender'
            name='Gender'
            value={Gender}
            options={personGenderOptions}
            labelProperty='name'
            valueProperty='value'
            handleChange={handleChange}
          />
          <CheckboxInputWithLabel
            inputLabel='Can speak another language?'
            name='CanSpeakAnotherLanguage'
            value={CanSpeakAnotherLanguage}
            handleChange={handleChange}
          />
          {CanSpeakAnotherLanguage && (
            <TextInputWithLabel
              inputLabel='Additional languages'
              name='AdditionalLanguages'
              value={AdditionalLanguages}
              handleChange={handleChange}
            />
          )}
          <SelectInputWithLabel
            inputLabel='Job title'
            name='JobTitle'
            value={JobTitle}
            options={jobTitleOptions}
            labelProperty='name'
            valueProperty='value'
            handleChange={handleChange}
            required={true}
          />
        </>
      )}
    </div>
  );
};
export default Person;
