import collapse from "../../assets/button-icons/collapse.png";
import expand from "../../assets/button-icons/expand.png";
import "./Style.css";

export const ExpandSection = ({ handleClick }) => {
  return (
    <div onClick={handleClick}>
      <img
        className='expand-collapse-icon-resize'
        src={expand}
        alt={"collapse section"}
      />
    </div>
  );
};

export const CollapseSection = ({ handleClick }) => {
  return (
    <div onClick={handleClick}>
      <img
        className='expand-collapse-icon-resize'
        src={collapse}
        alt={"collapse section"}
      />
    </div>
  );
};

export const ExpandCollapseSection = ({ handleClick, isExpanded }) => {
  return (
    <div onClick={handleClick}>
      <img
        className={`expand-collapse-icon-resize ${
          isExpanded ? "rotate90" : ""
        }`}
        src={expand}
        alt={isExpanded ? "collapse section" : "expand section"}
      />
    </div>
  );
};
