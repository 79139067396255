//input components
import { TimeInput } from "../../../../../../SharedComponents/InputComponents/DateInput.jsx";
import { CheckboxSliderInput } from "../../../../../../SharedComponents/InputComponents/CheckboxSliderInput.jsx";

//functions
import { firstItemToEndOfArray } from "../../../../../../SharedComponents/Utils/ArrayUtils.js";
import {
  extractTime,
  isValidISOString,
  timeToDatetime,
} from "../../../../../../SharedComponents/Utils/DateUtils.js";

//page management
import { NoTableDataToDisplay } from "../../../../../../SharedComponents/Tables/NoData.jsx";

//static data
import { daysOfWeek } from "./Utils.js";

const OpeningTimes = ({ openingHours, handleChange }) => {
  openingHours.sort((a, b) => a.DayOfWeek - b.DayOfWeek);
  openingHours = firstItemToEndOfArray(openingHours);

  const handleTimeChange = (event, index) => {
    const customEvent = {
      target: {
        name: event.target.name,
        value: timeToDatetime(event.target.value),
      },
    };

    handleChange(customEvent, index);
  };

  //handleClosedChange works in reverse as it makes more visual sense for the slider being on and the clinic being open
  const handleClosedChange = (event, index) => {
    const customEvent = {
      target: {
        name: event.target.name,
        value: !event.target.checked,
      },
    };

    handleChange(customEvent, index);
  };

  return (
    <div className="react-shared-table">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Opens</th>
            <th>Closes</th>
          </tr>
        </thead>
        <tbody className='omit-last-column-background-color-td omit-rounding-last-td-bottom-right'>
          {openingHours.length > 0 && (
            <>
              {openingHours.map((item, index) => (
                <tr key={index}>
                  <td>
                    {
                      daysOfWeek.find((day) => item.DayOfWeek === day.value)
                        .name
                    }
                  </td>
                  {!item.Closed && (
                    <>
                      <td>
                        <TimeInput
                          name='StartTime'
                          value={
                            isValidISOString(item.StartTime)
                              ? extractTime(item.StartTime)
                              : item.StartTime
                          }
                          handleChange={(e) => handleTimeChange(e, index)}
                        />
                      </td>
                      <td>
                        <TimeInput
                          name='FinishTime'
                          value={
                            isValidISOString(item.FinishTime)
                              ? extractTime(item.FinishTime)
                              : item.FinishTime
                          }
                          handleChange={(e) => handleTimeChange(e, index)}
                        />
                      </td>
                    </>
                  )}
                  {item.Closed && (
                    <>
                      <td colSpan={2} style={{ textAlign: "center" }}>
                        Closed
                      </td>
                    </>
                  )}
                  <CheckboxSliderInput
                    name='Closed'
                    value={!item.Closed}
                    handleChange={(e) => handleClosedChange(e, index)}
                  />
                </tr>
              ))}
            </>
          )}
          {openingHours.length < 1 && (
            <NoTableDataToDisplay
              missingDataDescription='opening times'
              numberOfColumnsToSpan={3}
            />
          )}
        </tbody>
      </table>
    </div>
  );
};
export default OpeningTimes;
