import { useState, useEffect } from "react";

//button components
import { Button } from "../../../../../SharedComponents/ButtonComponents/Button";

//label components
import { CheckboxDisabled } from "../../../../../SharedComponents/FormComponents/FormLabel";

//input components
import { SearchInput } from "../../../../../SharedComponents/InputComponents/SearchInput";

//styling
import "../../../../../SharedComponents/Styles/SharedTableStyles.css"

const FacilityLevelClinicians = ({
  clinicians,
  handleRemoveClick,
  handleEditClick,
  handleResetPasswordClick,
}) => {
  const [initialClinicians, setInitialClinicians] = useState(null);
  const [filteredClinicians, setFilteredClinicians] = useState(null);
  const searchProperties = ["FirstName", "LastName", "UserName"];

  useEffect(() => {
    setInitialClinicians(clinicians);
    setFilteredClinicians(clinicians);
  }, [clinicians]);

  if (initialClinicians === null) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="react-shared-table">
        <div style={{ marginBottom: "0.5rem" }}>
          <SearchInput
            data={initialClinicians}
            setFilteredData={setFilteredClinicians}
            filterProperties={searchProperties}
          />
        </div>

        <table className='background-color'>
          <thead>
            <tr>
              <th>Forename</th>
              <th>Surname</th>
              <th>Username</th>
              <th>Able to treat minors</th>
              <th>HQ</th>
              <th>Site</th>
              <th> </th>
              <th> </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {filteredClinicians &&
              filteredClinicians.map((item, index) => (
                <tr key={index}>
                  <td>{item.FirstName}</td>
                  <td>{item.LastName}</td>
                  <td>{item.UserName}</td>
                  <td>
                    <CheckboxDisabled
                      displayValue={item.AbleToTreatMinors}
                    ></CheckboxDisabled>
                  </td>
                  <td>
                    <CheckboxDisabled displayValue={item.HQ}></CheckboxDisabled>
                  </td>
                  <td>
                    <CheckboxDisabled
                      displayValue={item.Site}
                    ></CheckboxDisabled>
                  </td>
                  <td>
                    <Button
                      label='Remove from clinic'
                      handleClick={(e) => handleRemoveClick(e, item)}
                    />
                  </td>
                  <td>
                    <Button
                      label='Edit clinician'
                      handleClick={(e) => handleEditClick(e, item)}
                    />
                  </td>
                  <td>
                    <Button
                      label='Reset password'
                      handleClick={(e) =>
                        handleResetPasswordClick(e, item.UserName)
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
};
export default FacilityLevelClinicians;
