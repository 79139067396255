import { CallStates } from "../../../../SharedComponents/AppStates/Utils";

export const getInsuranceLegalDetails = (api, facilityID, setCallState) => {
  return api
    .transact("GetClinicInsuranceLegalDetails", { FacilityID: facilityID })
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const saveInsuranceLegalDetails = (api, facility, setCallState) => {
  return api
    .transact("SaveClinicInsuranceLegalDetails", { Facility: facility })
    .then((response) => {
      setCallState(CallStates.SAVED);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
