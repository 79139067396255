import React, { useEffect, useRef, useState } from "react";
import * as tableExport from "tableexport";

const ExcelExport = ({
  children,
  fileName = "table-export",
  sheetName = "sheet",
}) => {
  const tableRef = useRef(null);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    let exporter;

    if (tableRef.current) {
      //Set up TableExport options
      const options = {
        filename: fileName,
        formats: ["csv"],
        sheetname: sheetName,
      };

      //Initialize TableExport on the table element
      exporter = new tableExport.TableExport(tableRef.current, options);

      window.exporter = exporter;
    }

    return () => {
      //Clean up the previous TableExport instance
      if (exporter) {
        exporter.remove();
      }
    };
  }, [fileName, children]);

  useEffect(() => {
    //Check if there are any data rows in the table
    const hasDataRows =
      tableRef.current &&
      tableRef.current.querySelector("tbody") &&
      tableRef.current.querySelector("tbody").querySelectorAll("tr").length > 0;

    //Update the state to show or hide the button
    setShowButton(hasDataRows);
  }, [children]);

  //Seems yucky having the styles all plonked in the element but overriding the out of the box buttons was harder than it should have been.
  //Button will only show if there is data to populate the export.
  return (
    <div>
      <style>
        {`
          .tableexport-caption button.button-default.csv {
            background-color: #ededed;
            border-color: #9db0a9;
            border-width: 1px;
            border-radius: 8px;
            border-style: solid;
            font-weight: bold;
            text-decoration: none;
            color: #d75094;
            padding-left: 10px;
            padding-right: 10px;
            cursor: pointer;
            height: 30px;
            width: 150px;
            vertical-align: middle;
            display: ${showButton ? "inline-block" : "none"};
          }
        `}
      </style>
      <div ref={tableRef}>{children}</div>
    </div>
  );
};

export default ExcelExport;
