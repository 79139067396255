//input components
import { CheckboxInputWithLabel } from "../../../../../../SharedComponents/InputComponents/CheckboxInput";
import { DateInputWithLabel } from "../../../../../../SharedComponents/InputComponents/DateInput";
import { SelectInputWithLabel } from "../../../../../../SharedComponents/InputComponents/SelectInput";


const OnHoldDetails = ({
  onHoldInfo,
  onHoldReasons,
  handleOnHoldInfoChange,
}) => {
  const { OnHold, DateTo } = onHoldInfo;
  //this can be renamed to onhold details as the facility and practice stuff is moved to amenities
  return (
    <div className=''>
      <CheckboxInputWithLabel
        inputLabel='On hold'
        name='OnHold'
        value={OnHold}
        handleChange={handleOnHoldInfoChange}
      />
      {OnHold && (
        <>
          <SelectInputWithLabel
            name='OnHoldReasonID'
            inputLabel='Reason'
            value={onHoldInfo.OnHoldReasonID}
            options={onHoldReasons}
            labelProperty='Reason'
            valueProperty='OnHoldReasonID'
            handleChange={handleOnHoldInfoChange}
            required={OnHold}
          />
          <DateInputWithLabel
            inputLabel='Review date'
            name='DateTo'
            value={DateTo ?? ""}
            handleChange={handleOnHoldInfoChange}
            required={OnHold}
          />
        </>
      )}
    </div>
  );
};
export default OnHoldDetails;
