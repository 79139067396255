import React, { useEffect, useState } from "react";

//tab components
import Summary from "../ClinicManagementTabs/Summary/Summary.jsx";
import Amenities from "../ClinicManagementTabs/Amenities/Amenities.jsx";
import InsuranceLegal from "../ClinicManagementTabs/InsuranceLegal/InsuranceLegal.jsx";
import Fees from "../ClinicManagementTabs/Fees/Fees.jsx";
import Contacts from "../ClinicManagementTabs/Contacts/Contacts.jsx";
import Services from "../ClinicManagementTabs/ServicesAndTreatmentSpecialties/Services.jsx";
import Clinicians from "../ClinicManagementTabs/Clinicians/Clinicians.jsx";
import Patients from "../ClinicManagementTabs/Patients/Patients.jsx";
import Contract from "../ClinicManagementTabs/Contract/Contract.jsx";
import Correspondence from "../ClinicManagementTabs/Correspondence/Correspondence.jsx";

//api calls
import { getOrganisationID } from "./Transactions.js";

//page management
import PageState from "../../../SharedComponents/AppStates/PageState.jsx";
import { CallStates } from "../../../SharedComponents/AppStates/Utils.js";

import "../../../SharedComponents/Styles/SharedGenericStyles.css";
import "./Style.css"

const TabContainer = ({ api, FacilityID }) => {
  const [organisationID, setOrganisationID] = useState(null);
  const [selectedTab, setSelectedTab] = useState("summary");
  const [callState, setCallState] = useState(CallStates.INITIAL);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (FacilityID) {
      if (callState === CallStates.INITIAL || callState === CallStates.SAVED) {
        getOrganisationID(FacilityID, api, setCallState)
          .then((data) => {
            setOrganisationID(data);
            setCallState(CallStates.LOADED);
          })
          .catch((error) => {
            setErrorMessage(error.message);
            setCallState(CallStates.ERROR);
          });
      }
    }
  }, []);

  const handleClick = (event, tabName) => {
    event.preventDefault();
    setSelectedTab(tabName);
  };

  return (
    <PageState callState={callState} errorMessage={errorMessage}>
        <div>
            <div className='tab-buttons'>
                <button
                className={`tab ${selectedTab === "summary" ? "active" : ""}`}
                onClick={(e) => handleClick(e, "summary")}
                >
                Summary
                </button>
                <button
                className={`tab ${selectedTab === "amenities" ? "active" : ""}`}
                onClick={(e) => handleClick(e, "amenities")}
                >
                Clinic Accessibility & Facilities
                </button>
                <button
                className={`tab ${selectedTab === "contacts" ? "active" : ""}`}
                onClick={(e) => handleClick(e, "contacts")}
                >
                Contacts
                </button>
                <button
                className={`tab ${selectedTab === "insuranceLegal" ? "active" : ""}`}
                onClick={(e) => handleClick(e, "insuranceLegal")}
                >
                Insurance/Legal
                </button>
                <button
                className={`tab ${selectedTab === "fees" ? "active" : ""}`}
                onClick={(e) => handleClick(e, "fees")}
                >
                Clinic Rates
                </button>
                <button
                className={`tab ${selectedTab === "services" ? "active" : ""}`}
                onClick={(e) => handleClick(e, "services")}
                >
                Services
                </button>
                <button
                className={`tab ${selectedTab === "clinicians" ? "active" : ""}`}
                onClick={(e) => handleClick(e, "clinicians")}
                >
                Clinicians
                </button>
                <button
                className={`tab ${selectedTab === "patients" ? "active" : ""}`}
                onClick={(e) => handleClick(e, "patients")}
                >
                Patients
                </button>
                <button
                className={`tab ${selectedTab === "contract" ? "active" : ""}`}
                onClick={(e) => handleClick(e, "contract")}
                >
                Contract
                </button>
                <button
                className={`tab ${selectedTab === "correspondence" ? "active" : ""}`}
                onClick={(e) => handleClick(e, "correspondence")}
                >
                Correspondence
                </button>
            </div>
        </div>
      
      {organisationID && (
        <div className="react-shared">
          {selectedTab === "summary" && (
            <Summary api={api} facilityID={FacilityID} />
          )}
          {selectedTab === "amenities" && (
            <Amenities api={api} facilityID={FacilityID} />
          )}
          {selectedTab === "insuranceLegal" && (
            <InsuranceLegal api={api} facilityID={FacilityID} />
          )}
          {selectedTab === "fees" && (
            <Fees api={api} facilityID={FacilityID}/>
          )}
          {selectedTab === "contacts" && (
            <Contacts api={api} facilityID={FacilityID} />
          )}
          {selectedTab === "services" && (
            <Services api={api} facilityID={FacilityID} />
          )}
          {selectedTab === "clinicians" && (
            <Clinicians api={api} facilityID={FacilityID} />
          )}
          {selectedTab === "patients" && (
            <Patients api={api} facilityID={FacilityID} />
          )}
          {selectedTab === "contract" && (
            <Contract api={api} organisationID={organisationID} />
          )}
          {selectedTab === "correspondence" && (
            <Correspondence api={api} facilityID={FacilityID} />
          )}
        </div>
      )}
    </PageState>
  );
};

export default TabContainer;
