
import React from "react";
import ReactDOM from "react-dom";
import ClientSettingEditor from "../../components/ClientSettingsEditor/ClientSettingEditor";
import addMainMenuItem from "./AddMainMenuItem";

const addClientSettingsEditor = (api) =>
{
    // Client Settings
    const clientSettingsButtonHandler = () => {
        const contentDiv = document.getElementById('ApplicationContent');
        if (null != contentDiv) {
            contentDiv.innerHTML = "";
            ReactDOM.render(
                <React.StrictMode>
                    <ClientSettingEditor api={api} />
                </React.StrictMode>,
                contentDiv
            );
        }
    }

    addMainMenuItem(4, clientSettingsButtonHandler, "Client Settings"); 
}

export default addClientSettingsEditor;