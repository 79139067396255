import "../Styles/SharedGenericStyles.css";
import "./RadioInputStyles.css";
import { useState, useEffect } from "react";

//This component handles accepting a boolean value as the value param and makes it useable for a yes/no radio button
//it will also handle a string value of true or false if accurately passed
export function RadioInputBoolean({
  name,
  value,
  trueLabel = "True",
  falseLabel = "False",
  handleChange,
  disabled = false,
}) {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleRadioChange = (e) => {
    const newValue = e.target.value;
    setSelectedValue(newValue);

    const booleanValue = newValue === "true";

    const customEvent = {
      ...e,
      target: {
        ...e.target,
        name: name,
        value: booleanValue,
        checked: booleanValue,
      },
    };
    handleChange(customEvent);
  };

  return (
    <div className='react-shared-flex-center'>
      <div className='react-shared-flex-cell'>
        <input
          type='radio'
          name={name}
          value='true'
          checked={selectedValue?.toString().toLowerCase() === "true"}
          onChange={handleRadioChange}
          disabled={disabled}
        />
        <span>{trueLabel}</span>
        <input
          type='radio'
          name={name}
          value='false'
          checked={selectedValue?.toString().toLowerCase() === "false"}
          onChange={handleRadioChange}
          disabled={disabled}
        />
        <span>{falseLabel}</span>
      </div>
    </div>
  );
}

export function RadioInputBooleanWithLabel({
  inputLabel,
  name,
  value,
  trueLabel = "True",
  falseLabel = "False",
  handleChange,
  disabled = false,
}) {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleRadioChange = (e) => {
    const newValue = e.target.value;
    setSelectedValue(newValue);

    const booleanValue = newValue === "true";

    const customEvent = {
      ...e,
      target: {
        ...e.target,
        name: name,
        value: booleanValue,
        checked: booleanValue,
      },
    };
    handleChange(customEvent);
  };

  return (
    <div className='react-shared-flex-center'>
      <div className='react-shared-flex-cell'>
        <label>{inputLabel}: </label>
      </div>
      <div className='react-shared-flex-cell'>
        <input
          type='radio'
          name={name}
          value='true'
          checked={selectedValue?.toString() === "true"}
          onChange={handleRadioChange}
          disabled={disabled}
        />
        <span className='RadioText'>{trueLabel}</span>
        <input
          type='radio'
          name={name}
          value='false'
          checked={selectedValue?.toString() === "false"}
          onChange={handleRadioChange}
          disabled={disabled}
        />
        <span className='RadioText'>{falseLabel}</span>
      </div>
    </div>
  );
}
