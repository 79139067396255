import { useEffect, useState } from "react";

//page components
import Address from "./Address";
import ElectronicAddress from "./ElectronicAddress";
import PhoneNumber from "./PhoneNumber";

//functions
import { handleInputChange } from "../../../../../SharedComponents/Utils/InputUtils";

//static data
import { contactModel } from "../Utils";

const ContactDetails = ({
  contactDetails,
  handleContactDetailsChange,
  disabled = false,
  showSendEmailButton = false,
  showWebAddress = false,
  handleSendEmailButtonClick = null,
}) => {
  const [billingAddress, setBillingAddress] = useState(null);
  const [businessAddress, setBusinessAddress] = useState(null);
  const [businessPhone, setBusinessPhone] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [webAddress, setWebAddress] = useState(null);

  useEffect(() => {
    if (contactDetails.BillingAddress) {
      setBillingAddress(contactDetails.BillingAddress);
    } else {
      setBillingAddress(contactModel().ContactDetails.BillingAddress);
    }
    if (contactDetails.BusinessAddress) {
      setBusinessAddress(contactDetails.BusinessAddress);
    } else {
      setBusinessAddress(contactModel().ContactDetails.BusinessAddress);
    }
    if (contactDetails.BusinessPhone) {
      setBusinessPhone(contactDetails.BusinessPhone);
    } else {
      setBusinessPhone(contactModel().ContactDetails.BusinessPhone);
    }
    if (contactDetails.EmailAddress) {
      setEmailAddress(contactDetails.EmailAddress);
    } else {
      setEmailAddress(contactModel().ContactDetails.EmailAddress);
    }
    if (showWebAddress) {
      if (contactDetails.WebAddress) {
        setWebAddress(contactDetails.WebAddress);
      } else {
        setWebAddress(contactModel().ContactDetails.WebAddress);
      }
    }
  }, []);

  useEffect(() => {
    handleContactDetailsChange({
      BillingAddress: billingAddress,
      BusinessAddress: businessAddress,
      BusinessPhone: businessPhone,
      EmailAddress: emailAddress,
      WebAddress: webAddress,
    });
  }, [billingAddress, businessAddress, businessPhone, emailAddress, webAddress]);

  return (
    <>
      {billingAddress && (
        <div>
          <Address
            addressDetails={billingAddress}
            readOnly={disabled}
            handleChange={(e) =>
              handleInputChange(e, billingAddress, setBillingAddress)
            }
          />
        </div>
      )}
      {businessAddress && (
        <div>
          <Address
            addressDetails={businessAddress}
            readOnly={disabled}
            handleChange={(e) =>
              handleInputChange(e, businessAddress, setBusinessAddress)
            }
          />
        </div>
      )}
      {emailAddress && (
        <div>
          <ElectronicAddress
            emailDetails={emailAddress}
            sendEmailButton={showSendEmailButton}
            handleSendEmailClick={handleSendEmailButtonClick}
            handleChange={(e) =>
              handleInputChange(e, emailAddress, setEmailAddress)
            }
          />
        </div>
      )}
      {businessPhone && (
        <div>
          <PhoneNumber
            phoneDetails={businessPhone}
            readOnly={disabled}
            handleChange={(e) =>
              handleInputChange(e, businessPhone, setBusinessPhone)
            }
          />
        </div>
      )}
      {webAddress && (
        <div>
          <ElectronicAddress
            webAddressDetails={webAddress}
            handleChange={(e) =>
              handleInputChange(e, webAddress, setWebAddress)
            }
          />
        </div>
      )}
    </>
  );
};
export default ContactDetails;
