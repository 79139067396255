import React, { useEffect, useRef, useState } from "react";
import {
    LoadingIndicator,
    TwoSwitch
} from "../../lib/iprs-react-library/src/index";
import JsonEditorReactWrapper from "./JsonEditorReactWrapper";

/**
 * @typedef {Object} WebReferralEditorParams
 * @property {string|int} clientPartyID - The ID of the client party.
 * @property {string} configTitle - The title of the configuration.
 * @property {number} versionNumber - The version number.
 * @property {Function} goBack - Function to go back.
 */

/**
 * React component to display a JSON editor for a web referral configuration.
 * This will use the API to load and save the configuration.
 * @param {WebReferralEditorParams} params
 * @returns {JSX.Element} A React component
 */
const WebReferralConfigJsonEditor = ({
    api,
    selectedConfigDetails,
    clientPartyID,
    configTitle,
    versionNumber,
    goBack
}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [form, setForm] = useState({});

    useEffect(() => {
        setLoading(true);
        getReferralClientConfig(api, clientPartyID, configTitle, versionNumber)
            .then(r => {
                setForm(r.ConfigJSON);
                setLoading(false);
                setError(null);
            })
            .catch(e => {
                console.log(e);
                setLoading(false);
                setError(e.message);
            });
    }, [api, clientPartyID, configTitle, versionNumber]);

    const handleSetForm = updatedForm => {
        if (!updatedForm) {
            setError("No form data");
            return;
        }

        setLoading(true);
        saveReferralClientConfig(api, clientPartyID, configTitle, updatedForm)
            .then(r => {
                goBack(); // don't update form or set loading false
            })
            .catch(e => {
                setLoading(false);
                setError(e.message);
                console.log(e);
            });
    };

    return (
        <div>
            <LoadingIndicator loading={loading} />
            <TwoSwitch test={error}>
                <p>{error}</p>
                <JsonEditorWithSaveAndCancelButtons
                    selectedConfigDetails={selectedConfigDetails}
                    form={form}
                    setForm={handleSetForm}
                    cancelHandler={goBack}
                />
            </TwoSwitch>
        </div>
    );
};

const saveReferralClientConfig = (api, clientPartyID, configTitle, config) =>
    api
        .transact("SaveReferralClientConfig", {
            ClientPartyID: clientPartyID,
            ConfigTitle: configTitle,
            ConfigObject: config
        })
        .then(r => r.apiResult);

const getReferralClientConfig = (
    api,
    clientPartyID,
    configTitle,
    versionNumber
) =>
    api
        .transact("GetReferralClientConfig", {
            ClientPartyID: clientPartyID,
            ConfigTitle: configTitle,
            VersionNumber: versionNumber
        })
        .then(r => r.apiResult);

/**
 * Internal component to display a JSON editor with save and cancel buttons.
 * @param {object} params
 * @returns {JSX.Element} A React component
 */
const JsonEditorWithSaveAndCancelButtons = ({
    selectedConfigDetails,
    form,
    setForm,
    cancelHandler
}) => {
    const content = {
        json: form ?? "No Content",
        text: undefined
    };

    const readOnly = false;

    const refEditor = useRef(null);

    const handleEditorChange = () => {
        const editor = refEditor?.current?.get();
        const json = editor?.json ?? JSON.parse(editor?.text);

        setForm(json);
    };

    const handleCancel = e => {
        console.log("Cancel Handler");
        e.preventDefault();
        cancelHandler();
    };

    return (
        <div className="WebReferralConfigJsonEditorDiv">
            <h3>{selectedConfigDetails.clientName}</h3>
            <p>Config Title: {selectedConfigDetails.ConfigTitle} <br/> Version: {selectedConfigDetails.VersionNumber}</p>
            <button onClick={handleEditorChange}>Submit</button>
            <button onClick={handleCancel}>Cancel</button>
            <JsonEditorReactWrapper
                refEditor={refEditor}
                content={content}
                readOnly={readOnly}
                mode="text"
                navigationBar={false}
                statusBar={false}
                mainMenuBar={false}
                onError={e => console.log(e)}
            />
            <button onClick={handleEditorChange}>Submit</button>
            <button onClick={handleCancel}>Cancel</button>
        </div>
    );
};

export default WebReferralConfigJsonEditor;
