import React, {useState, useEffect, useCallback} from 'react';
//import ConfigEditorForm from './ConfigEditorForm'
import WebReferralConfigJsonEditor from './WebReferralConfigJsonEditor';
import ConfigVersionsListToggleWrapper from './ConfigVersionsListToggleWrapper';

/**
 * @typedef {object} WebReferralConfigListAndEditProps
 * @property {object} api
 */

/**
 * React Component to list web referral configurations and 
 * load the editor for a selected configuration.
 * @param {WebReferralConfigListAndEditProps} param 
 * @returns {JSX.Element} A React component 
 */
const WebReferralConfigListAndEdit = ({api}) => {
    const [formList, setFormList] = useState([]);
    const [error, setError] = useState('');
    const [selectedConfigDetails, setSelectedConfigDetails] = useState('');

    const retrieveForm = (versionNumber, configTitle, clientPartyID, clientName) => {
        console.log(versionNumber, configTitle, clientPartyID, clientName);

        setSelectedConfigDetails({
            "ClientPartyID": clientPartyID,
            "ConfigTitle": configTitle,
            "VersionNumber": versionNumber,
            "clientName": clientName
        });
    }

    /**
    * Callback function used by useEffect below which is used to generate the list of
    * clients and their forms.
    * The list is served in format like below.
    * [
    *    {"Configs": [
    *           {
    *           "Versions":[
    *                   "VersionNumber": 0
    *                   "VersionDate": "0001-01-01T00:00:00"
    *               ],
    *           "CreatedByUserName" : null
    *           }
    *       ],
    *       "ClientPartyID": 44983,
    *      "ClientName": "Abermed"
    *    },
    *    {...},
    *    {...}
    *  ]
    * Below "generateList" function will create the div with list of forms which is displayed
    * using the date as its title.
    * Currently, the form that is being used for the form that will be displayed for the
    * users fill is set to be "Default" and the index ID is 0.
    */
    const generateList = useCallback((list) => {
    
        // First .map() to generate the items in the first config lists for clients
        const generatedList = list.map((item, index)=>{

            // This local function will process the item given by
            // using for loops to iterate the array and make the list
            // of forms per config title for every clients given in the list
            // argument given to the list parameter of this function. 
            const multipleFormsWithName = () => {

                let configVersions = [];
                
                for(let i=0; i < item.Configs.length; i++){
                    console.log('item.Configs',item.Configs[i])

                    let configVersionsPerConfigTitle = [];

                    console.log(item.Configs[i].Versions)
                    for(let j=0; j < item.Configs[i].Versions.length; j++){
                        console.log(item.Configs[i].Versions[j])
                        configVersionsPerConfigTitle.push(
                            <li onClick={()=>retrieveForm(
                                j, 
                                item.Configs[i].ConfigTitle === "Null" ? "Default" : item.Configs[i].ConfigTitle, 
                                item.ClientPartyID, 
                                item.ClientName)}
                            >
                                <span>{item.Configs[i].Versions[j].VersionDate}</span>
                                <span> - (created by: {item.Configs[i].Versions[j].CreatedByUserName})</span>
                            </li>);
                    }

                    configVersions.push(
                        <div className="formListItemDivToggleVersionsConfigTitle">
                            <h6><span>+ </span>{item.Configs[i].ConfigTitle !== null ? item.Configs[i].ConfigTitle : "Null"}</h6>
                            <ul>
                                {configVersionsPerConfigTitle}
                            </ul>
                        </div>
                        
                    )
                }
                
                return (<>{configVersions}</>)
            }

            return (
                <div className="formListItemDiv" key={index} value={item.ClientPartyID}>
                    <div className="formListItemTitleAndButtonDiv">
                        <div className="formListItemTitleAndButtonDivTitleInfo">
                            <h3>{item.ClientName}</h3>
                            <hr/>
                            <p><span style={{fontSize: "13px"}}>ClientPartyID: {item.ClientPartyID}</span></p>
                        </div>

                        {/* This button will make the API call and go straight to the editer with JSON loaded to it. */}
                        <button 
                            onClick={()=>retrieveForm(0, "Default", item.ClientPartyID, item.ClientName)}
                            className="formListItemViewButton">View</button>
                    </div>
                    
                    <ConfigVersionsListToggleWrapper>
                        <div className="formVersions">{multipleFormsWithName()}</div> 
                    </ConfigVersionsListToggleWrapper>
                </div>
            )
        })

        setFormList(generatedList)
    },[])

    // API object will change when this "WebReferralConfigListAndEdit" component is running which
    // will cause below useEffect to run.
    // This will happen at the start of the component when it is checking
    // for the token. This useEffect will detect the change and will run itself
    // and it makes api request to the "ListReferralClientConfig" endpoint. 
    // Then it retrieves the list of the configs and generateList() function 
    // will execute with the api response given to it as an argument.
    useEffect(()=>{
        if(api.isLoggedIn() === true && selectedConfigDetails === ''){
            api.transact("ListReferralClientConfig", {})
                .then(r => {
                    generateList(r.apiResult);
                    //setFormList(r.apiResult);
                    setError(null);
                })
                .catch(e => {
                    setError(JSON.stringify('ListReferralClientConfig api error: ' + e.message));
                });
        }else if( api.isLoggedIn() === false){
            setError('Authentication failed, api.isLoggedIn(): ',  api.isLoggedIn())
        }
    }, [api, selectedConfigDetails, generateList]);

    return (
        <div id="webReferralConfigListDiv">
            {error !== '' ? <div>{error}</div>: <></>}
            <div>
                <h2>Web Referral Config List</h2>
                {selectedConfigDetails === '' ? <p>Choose the form you want to edit.</p> : <p>Click save to apply changes.</p>}
                {selectedConfigDetails === '' ? 
                    formList : 
                    <WebReferralConfigJsonEditor 
                        api={api}
                        selectedConfigDetails={selectedConfigDetails}
                        clientPartyID={selectedConfigDetails.ClientPartyID}
                        configTitle={selectedConfigDetails.ConfigTitle}
                        versionNumber={selectedConfigDetails.VersionNumber}
                        goBack={()=>{console.log('going back');setSelectedConfigDetails('')}}
                    />
                } 
            </div>
        </div>
    )
}

export default WebReferralConfigListAndEdit;