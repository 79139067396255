import React, { useEffect, useState } from "react";
import ClientSettingSaveButton from "./ClientSettingSaveButton";

const ClientSettingsTableContentRow = ({ api, setting, clientID }) => {
    useEffect(() => {
        setValue(setting.SettingValue);
        setInitialValue(setting.SettingValue);
    }, [setting]); // refresh if reload

    const { SettingKey, SettingValue, SettingDescription, DefaultValue } =
        setting;

    const [initialValue, setInitialValue] = useState(SettingValue);
    const [value, setValue] = useState(SettingValue);
    const [clientListApiError, setClientListApiError] = useState("");

    const isChanged = value !== initialValue;
    const isDefault = initialValue === DefaultValue;

    const editButtonHandler = dlt => {
        const param = {
            SettingKey: SettingKey,
            ClientID: clientID,
            SettingValue: value,
            Delete: dlt
        };

        api.transact("SaveBrandingInfo", param)
            .then(r => {
                setInitialValue(value);
                setClientListApiError("");
                if (dlt) {
                    setValue(DefaultValue);
                    setInitialValue(DefaultValue);
                }
            })
            .catch(e => {
                setClientListApiError(e.message);
            });
    };

    return (
        <>
            <tr className="clientSettingListTableBodyRows">
                <td className="clientSettingListTableBody DefaultColumn">
                    {isDefault ? "✓" : "✕"}
                </td>
                <td className="clientSettingListTableBody">
                    <input
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </td>
                <td className="clientSettingListTableBody">
                    {SettingDescription ?? SettingKey}
                </td>
                <td>
                    <ClientSettingSaveButton
                        isChanged={isChanged}
                        isDefault={isDefault}
                        editButtonHandler={editButtonHandler}
                    />
                    {clientListApiError && <span>{clientListApiError}</span>}
                </td>
            </tr>
        </>
    );
};

export default ClientSettingsTableContentRow;
