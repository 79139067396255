import React, { useState } from "react";
import { LoadingIndicator } from "../../../index.js";
import { handleDuplicateFile } from "./utils/handleDuplicateFile.js";
import { apiRequestInsertUserFile } from "./api/insertUserFile.jsx";

// This component renders an upload button for directories.
const FileTreeUploadButton = ({
    api,
    pathName,
    addNewFileInFileTree,
    toggleShowDuplicateFileRow,
    partyId,
}) => {
    const [loading, setLoading] = useState(false);

    // Function that will handle file upload when upload button is clicked and file is selected.
    const uploadButtonHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Extracting files array from the event object.
        const files = e.target.files;

        // Conditional statement to end the function at this point if the files array
        // length is 0, because it doesn't need to make the API request if there
        // is no files to upload in the event object.
        if (files.length === 0) {
            setLoading(false); // Reset loading state if no files are selected
            return;
        }

        try{
            const response = await api.uploadFiles(files);
            const apiResult = response.apiResult;

            for(const result of apiResult){
                if(result.GUID){
                    await apiRequestInsertUserFile(api, pathName, result.GUID, result.Name, partyId, addNewFileInFileTree);
                }
            }
        } catch (error) {
            handleDuplicateFile(error.apiText, pathName, files, toggleShowDuplicateFileRow);
        } finally {
            e.target.value = "";
            setLoading(false);
        }
    };

    return (
        <label className="iprsPortalFileTreeDictionaryItemButton">
            {loading ? (
                <LoadingIndicator loading={true} color={"white"} />
            ) : (
                <>
                    <svg width="22" height="22">
                        <use href="#icon-arrow-upload" />
                    </svg>
                    <span className="iprsPortalFileTreeDictionaryItemDownloadText">
                        Upload
                    </span>
                </>
            )}
            <input
                type="file"
                onChange={uploadButtonHandler}
                disabled={loading}
                style={{ display: "none" }}
                multiple
            />
        </label>
    );
};

export default FileTreeUploadButton;