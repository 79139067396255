import openInNewTabIcon from "../../assets/button-icons/open-in-new-tab.png";

export const OpenUrlInNewTab = ({ url }) => {
  const openUrl = () => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "https://" + url;
    }
    window.open(url, "_blank");
  };

  return (
    <img
      src={openInNewTabIcon}
      alt='Open link in new tab'
      style={{
        width: "auto",
        height: "25px",
        objectFit: "contain",
        cursor: "pointer",
        opacity: "0.7",
      }}
      onClick={() => openUrl()}
    />
  );
};
