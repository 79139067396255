export const small = {
  height: "150px",
  width: "450px",
};

export const fee = {
  height: "200px",
  width: "750px",
};

export const large = {
  height: "500px",
  width: "750px",
};

export const email = {
  height: "700px",
  width: "850px",
};
