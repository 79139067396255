export const troubleLoadingData = () => {
  return "Something went wrong!\nWe had some trouble loading the data for this page.";
};

export const pleaseEnterNumber = () => {
  return "Something went wrong!\nPlease enter a number.";
};

export const incorrectDataFormat = () => {
  return "Something went wrong!\nThere was some trouble processing the data on this page.";
};

export const ErrorMessage = {
    FILE_UPLOAD_FAILURE: "Failed to upload file.",
    SAVE_FAILURE: "Failed to save.",
    SEND_FAILURE: "Failed to send."
}
