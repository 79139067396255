import { CallStates } from "../../../components/SharedComponents/AppStates/Utils";

export const saveExcelData= (api, invoicelist, setCallState) => {
    setCallState(CallStates.SAVING);
  
    return api
      .transact("InvoicePaymentScheduler", invoicelist)
      .then((response) => {
        setCallState(CallStates.SAVED);
        return response.apiResult;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };

  export const fetchFailedInvoices= (api) => {
    return api
      .transact("GetFailedInvoiceList")
      .then((response) => {
        return response.apiResult;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };