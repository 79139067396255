import "../Styles/SharedTableStyles.css"

export const NoTableDataToDisplay = ({
  missingDataDescription,
  numberOfColumnsToSpan,
}) => {
  return (
    <tr className='no-data-background'>
      <td
        colSpan={numberOfColumnsToSpan}
        style={{
          textAlign: "center",
          padding: "20px",
        }}
      >
        No {missingDataDescription} data to display.
      </td>
    </tr>
  );
};
