// Utility function to generate download URL
export const getDownloadUrl = (guid, api) => {
    console.log("Inside getDownloadUrl, Token:", api.getToken());
    return `${process.env.REACT_APP_IPRS_WEB_API_FILE_DOWNLOAD_URL}?id=${guid}&Token=${api.getToken()}`;
};
// Utility function to download a file
export const downloadFile = (guid, api, fileName) => {
    const url = getDownloadUrl(guid, api);
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};