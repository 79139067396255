import { useEffect, useRef } from "react";
import { JSONEditor } from 'vanilla-jsoneditor';

/**
 * A wrapper for the JSONEditor to use it as a React component.
 * See external docs for vanilla-jsoneditor
 * @param {Object} externalProps - The props passed to the component.
 * @returns {JSX.Element} A React component
 */
const JsonEditorReactWrapper = externalProps => {

    const { refEditor, ...props } = externalProps;
    const refContainer = useRef(null);

    useEffect(() => {
        refEditor.current = new JSONEditor({
            target: refContainer.current,
            props: {}
        });

        return () => {
            // destroy editor
            if (refEditor.current) {
                console.log("destroy editor");
                refEditor.current.destroy();
                refEditor.current = null;
            }
        };
    });

    // update props
    useEffect(() => {
        if (refEditor.current) {
            refEditor.current.updateProps(props);
        }
    }, [props, refEditor]);

    return (
        <div>
            <div ref={refContainer}></div>
        </div>
    );
};

export default JsonEditorReactWrapper;
