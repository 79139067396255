import React, { useState, useEffect } from "react";

const ClientListDropdown = ({ api, clientID, setClientID }) => {
    const [clientListApiError, setClientListApiError] = useState("");

    const [clientList, setClientList] = useState([]);

    useEffect(() => {
        const param = {};
        setClientListApiError("");
        api.transact("GetBookingSiteConfigList", param)
            .then(r => r.apiResult)
            .then(r => r.AllClients)
            .then(allClients => {
                setClientList(allClients);
            })
            .catch(e => {
                setClientListApiError(e.message);
            });
    }, [api, setClientList, setClientListApiError]);

    return (
        <>
            <label htmlFor="dropdown" className="ClientSettingEditorDivLabel">
                <span>Client:</span>
                <select
                    onChange={e => setClientID(e.target.value)}
                    value={clientID}
                >
                    <option value="0" key={0}>
                        Please select a client.
                    </option>
                    {clientList.map(client => (
                        <option
                            value={client.ClientPartyID}
                            key={client.ClientPartyID}
                        >
                            {client.FriendlyName}
                        </option>
                    ))}
                </select>
            </label>
            <p>{clientListApiError}</p>
        </>
    );
};

export default ClientListDropdown;
