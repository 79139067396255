import {
  convertUtcToLocaleDateTime,
  calculateTimeDifferenceBetweenDatesInHours,
} from "../../../../../../SharedComponents/Utils/DateUtils";

export const convertDatesToDateType = (facilityHours) => {
  facilityHours.forEach((hours) => {
    hours.StartTime = convertUtcToLocaleDateTime(hours.StartTime);
    hours.FinishTime = convertUtcToLocaleDateTime(hours.FinishTime);
  });
  return facilityHours;
};

export const calculateDaysOpen = (openingHours) => {
  let totalDaysOpenFor4Hours = 0;
  openingHours.forEach((day) => {
    let hours = calculateTimeDifferenceBetweenDatesInHours(
      day.StartTime,
      day.FinishTime
    );
    if (hours >= 4) {
      totalDaysOpenFor4Hours += 1;
    }
  });

  return totalDaysOpenFor4Hours;
};

export const calculateTotalHoursOpen = (openingHours) => {
  let totalHours = 0;
  openingHours.forEach((day) => {
    totalHours += calculateTimeDifferenceBetweenDatesInHours(
      day.StartTime,
      day.FinishTime
    );
  });

  return totalHours;
};

export const daysOfWeek = [
  { name: "Sunday", value: 0 },
  { name: "Monday", value: 1 },
  { name: "Tuesday", value: 2 },
  { name: "Wednesday", value: 3 },
  { name: "Thursday", value: 4 },
  { name: "Friday", value: 5 },
  { name: "Saturday", value: 6 },
];
