export const fetchUserFileTree = async (api, partyId) => {
    const params = {
        UserPartyID: partyId,
    };
    try {
        const response = await api.transact("GetUserFilesFileTree", params)
        return { data: [response.apiResult], error: null};
    } catch (error) {
        return { data: null, error };
    }
}