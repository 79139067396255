import React from "react";
import UserFileTree from "./FileTreeComponents/UserFileTree.jsx";
import IprsPortalSvg from "./IprsPortalSvg.jsx";

const IprsPortalMIReports = ({api,PartyID,currentUrl}) => {
    const fileTreeNameStylingObj = {
        marginLeft: "1rem", 
        fontSize:"18px", 
        marginBottom: "14px",
        fontWeight: "600"
    }

    const fileTreeDivStylingObj = {
        marginBottom: "26px"
    }

    return (
        <div className="main__section">

            <div className="IprsPortalMIReportExplorerDiv">
                <div>
                    {/* This will load the SVGs that will be called by UserFileTree components. */}
                    <IprsPortalSvg />

                    {/* <div>File Explorer View</div> */}
                    <div style={fileTreeDivStylingObj}>
                        <div style={fileTreeNameStylingObj}>
                            <span>Your Files test</span>
                        </div>
                        <div>
                            <UserFileTree 
                                partyId={PartyID}
                                api={api}
                                fetchUpdatedFileTree={() => {}}
                                currentUrl={currentUrl}
                            />
                        </div>
                    </div>

                    <div>
                        <div style={fileTreeNameStylingObj}>
                            <span>Shared Files</span>
                        </div>
                        <div>
                            <UserFileTree 
                                partyId={520000}
                                api={api}
                                fetchUpdatedFileTree={() => {}}
                                currentUrl={currentUrl}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default IprsPortalMIReports;