import { useState, useEffect } from "react";

//input components
import { TextInputWithLabel } from "../../../../../SharedComponents/InputComponents/TextInput";

//functions
import { handleInputChange } from "../../../../../SharedComponents/Utils/InputUtils";

const PersonDetails = ({ person, handlePersonChange }) => {
  const [scopedPerson, setScopedPerson] = useState(person);

  useEffect(() => {
    handlePersonChange(scopedPerson);
  }, [scopedPerson]);

  return (
    <>
      {scopedPerson && (
        <div>
          <TextInputWithLabel
            inputLabel='Forename'
            name='FirstName'
            value={scopedPerson.FirstName}
            handleChange={(e) =>
              handleInputChange(e, scopedPerson, setScopedPerson)
            }
          />
          <TextInputWithLabel
            inputLabel='Surname'
            name='LastName'
            value={scopedPerson.LastName}
            handleChange={(e) =>
              handleInputChange(e, scopedPerson, setScopedPerson)
            }
          />
        </div>
      )}
    </>
  );
};
export default PersonDetails;
