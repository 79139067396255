import "../Styles/SharedGenericStyles.css"
import { useState, useEffect } from "react";
import { parseAsFloat } from "../Utils/InputUtils";
import { pleaseEnterNumber } from "../Utils/ErrorMessages";

export const CurrencyInput = ({
  value = 0,
  name,
  handleChange,
  disabled = false,
}) => {
  const [floatValue, setFloatValue] = useState(null);

  useEffect(() => {
    const customEvent = {
      target: {
        name: name,
        value: floatValue,
      },
    };
    handleChange(customEvent);
  }, [floatValue]);

  const handleInputChange = (e) => {
    try {
      if (e.target.value === "") {
        e.target.value = 0;
      }
      const parsedValue = parseFloat(e.target.value);
      setFloatValue(parsedValue);
    } catch (error) {
      console.error(error.message);
      // Until better error handling is sorted
      alert(pleaseEnterNumber());
    }
  };

  return (
    <>
      {!isNaN(value) && (
        <input
          type='number'
          value={value}
          name={name}
          onChange={(e) => handleInputChange(e)}
          disabled={disabled}
        />
      )}
    </>
  );
};

export const CurrencyInputWithLabel = ({
  inputLabel,
  value = 0,
  name,
  handleChange,
  disabled = false,
}) => {
  const [floatValue, setFloatValue] = useState(parseFloat(value));

  useEffect(() => {
    const customEvent = {
      target: {
        name: name,
        value: floatValue,
      },
    };
    handleChange(customEvent);
  }, [floatValue]);

  const handleInputChange = (e) => {
    try {
      if (e.target.value === "") {
        e.target.value = 0;
      }
      const parsedValue = parseFloat(e.target.value);
      setFloatValue(parsedValue);
    } catch (error) {
      console.error(error.message);
      // Until better error handling is sorted
      alert(pleaseEnterNumber());
    }
  };

  return (
    <div className='clinic-profile-InLine container-100-justified-content'>
      {!isNaN(value) && (
        <>
          <label>{inputLabel} (£): </label>
          <div className='container-50-align-left'>
            <input
              type='number'
              value={value}
              name={name}
              onChange={(e) => handleInputChange(e)}
              disabled={disabled}
            />
          </div>
        </>
      )}
    </div>
  );
};
