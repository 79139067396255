import { CallStates } from "../../../../SharedComponents/AppStates/Utils";

export const getContacts = (api, facilityID, setCallState) => {
  return api
    .transact("GetClinicContacts", { facilityID: facilityID })
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      return response.apiResult;
    })

    .catch((e) => {
      return Promise.reject(e);
    });
};

export const saveContacts = (api, contacts, setCallState) => {
  const {
    FacilityID,
    DirectorIsPracticeManager,
    Director,
    PracticeManager,
    Clinician,
    Finance,
    Feedback,
  } = contacts;
  return api
    .transact("SaveClinicContacts", {
      FacilityID: FacilityID,
      DirectorIsPracticeManager: DirectorIsPracticeManager ?? false,
      Director: Director,
      PracticeManager: PracticeManager,
      Clinician: Clinician,
      Finance: Finance,
      Feedback: Feedback,
    })
    .then((response) => {
      setCallState(CallStates.SAVED);
    })

    .catch((e) => {
      return Promise.reject(e);
    });
};
