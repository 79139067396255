import React, { useState, useRef } from "react";
// import excel
import * as XLSX from "xlsx";

//import api calls
import { saveExcelData, fetchFailedInvoices } from "./Transactions";

//page management
import { CallStates } from "../../../components/SharedComponents/AppStates/Utils";

const InvoiceUploadStep = ({
  api,
  setFailedRows,
  moveToNextStep,
  setCurrentStep,
}) => {
  const [excelData, setExcelData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [callState, setCallState] = useState(CallStates.INITIAL);
  const fileInputRef = useRef(null);
  const [showFailedInvoicePage, setshowFailedInvoicePage] = useState(false);
  const [currentPage, setCurrentPage] = useState("ExcelUploadPage");

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const rowCount = XLSX.utils.decode_range(worksheet["!ref"]).e.r + 1;

        const invoiceList = [];
        for (let row = 2; row <= rowCount; row++) {
          const invoice = {
            InvoiceId: worksheet[`A${row}`]?.v || "",
            Amount: parseFloat(worksheet[`B${row}`]?.v) || 0,
          };
          invoiceList.push(invoice);
        }
        setExcelData(invoiceList);
      };
      reader.readAsArrayBuffer(file);
    }
  };
  const viewFailedInvoices = async (event) => {
    try {
      const response = await fetchFailedInvoices(api);
      if (response.InvoiceListItems.length > 0) {
        setFailedRows(response.InvoiceListItems);
        setCurrentPage("failed");
      } else {
        setErrorMessage("No failed invoices available");
      }
    } catch (error) {
      setErrorMessage("Failed to load invoice details");
    }
  };
  // Upload Excel data to API
  const handleSubmitAll = async (event) => {
    event.preventDefault();
    const summary = { invoicelist: excelData, module: "SaveData" };
    try {
      await saveExcelData(api, summary, setCallState);
      setExcelData([]);
      fileInputRef.current.value = "";
      setSuccessMessage("Invoices Uploaded Successfully. Review errors on the Failed Invoices page!")
      // fetching failed invoices
    //   const response = await fetchFailedInvoices(api);
    //   if (response.InvoiceListItems.length > 0) {
    //     setFailedRows(response.InvoiceListItems);
    //     moveToNextStep();
    //   }
    } catch (error) {
      setErrorMessage("Failed to upload invoices");
    }
  };

  return (
    <div className="invoice-upload-container">
           <p>
        <a
          href="#"
          className="invoice-table-link"
          onClick={(e) => {
            e.preventDefault();
            viewFailedInvoices(); setCurrentStep("review");
          }}
        >
          View Failed Invoices
        </a>
      </p>
      <h2>Please Upload Invoice Details Spreadsheet</h2>

      <input
        className="invoice-bulk-file-name"
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
        ref={fileInputRef}
      />
      
      {excelData.length > 0 && (
        <div className="react-ExcelUpload">
        <button onClick={handleSubmitAll}>
          Submit All
        </button>
        </div>
      )}
      {errorMessage && <div className="invoice-bulk-error-message">{errorMessage}</div>}
      {successMessage && <div className="invoice-bulk-message-success">{successMessage}</div>}
   
    </div>
  );
};

export default InvoiceUploadStep;
