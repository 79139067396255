import React, { useEffect, useState } from "react";

//api calls
import { getAmenities, saveAmenities } from "./Transactions";

//page components
import AmenitiesList from "./SectionComponents/AmenitiesList";
import PageState from "../../../../SharedComponents/AppStates/PageState";

//input components
import { CheckboxInputWithLabel } from "../../../../SharedComponents/InputComponents/CheckboxInput";
import { SelectInputWithLabel } from "../../../../SharedComponents/InputComponents/SelectInput";
import TextAreaInputWithLabel from "../../../../SharedComponents/InputComponents/TextAreaInput";

//functions
import {
  handleInputChangeWithinArray,
  handleInputChange,
} from "../../../../SharedComponents/Utils/InputUtils";
import { filterAmenities } from "./Utils";

//static data
import {
  numberOfTreatmentAreasOptions,
  filterTypeOptions,
  carParkingOptions,
  receptionCoverOptions,
} from "./Utils";

//page management
import { CallStates } from "../../../../SharedComponents/AppStates/Utils";

//styles
import "../../../../SharedComponents/Styles/SharedGenericStyles.css";
import { PartitionLine } from "../../../../SharedComponents/PageLayoutComponents/Partition";

//button components
import { Button } from "../../../../SharedComponents/ButtonComponents/Button";

const Amenities = ({ api, facilityID }) => {
  const [amenities, setAmenities] = useState(null);
  const [practice, setPractice] = useState(null);
  const [facility, setFacility] = useState(null);

  const [facilityTypeOptions, setFacilityTypeOptions] = useState(null);

  const [callState, setCallState] = useState(CallStates.INITIAL);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    //do the api call whenever the component is first loaded and also once it's saved to it will refresh the tab essentially.
      if (callState === CallStates.INITIAL || callState === CallStates.SAVED) {
      getAmenities(api, facilityID, setCallState)
        .then((data) => {
          //this will never be an empty list as the SP gets them all whether they're attached to a facility or not
          setAmenities(filterAmenities(data.Amenities));
          setPractice(data.Practice);
          setFacility(data.Facility);
          setFacilityTypeOptions(filterTypeOptions(data.FacilityTypes));
          setCallState(CallStates.LOADED);
          })
        .catch((e) => {
          setErrorMessage(e.message);
          setCallState(CallStates.ERROR);
        });
    }
  }, [callState]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setCallState(CallStates.SAVING);
    saveAmenities(api, amenities, facility, practice, setCallState).catch(
      (e) => {
        setErrorMessage(e.message);
        setCallState(CallStates.ERROR);
      }
    );
  };

  return (
    <PageState callState={callState} errorMessage={errorMessage}>
      <form onSubmit={(event) => handleSubmit(event)} className='fade-in'>
        <div>
          <div className='flex' style={{ padding: "2rem 6rem" }}>
            <div className='container-50'>
              {amenities && (
                <AmenitiesList
                  amenities={amenities}
                  handleChange={(e, index) =>
                    handleInputChangeWithinArray(
                      e,
                      amenities,
                      setAmenities,
                      index
                    )
                  }
                />
              )}
            </div>
            {facility && practice && facilityTypeOptions && (
              <div className='container-50'>
                <SelectInputWithLabel
                  name='FacilityTypeID'
                  inputLabel='Type'
                  value={facility.FacilityTypeID}
                  options={facilityTypeOptions}
                  labelProperty='FacilityTypeDescription'
                  valueProperty='FacilityTypeID'
                  handleChange={(e) =>
                    handleInputChange(e, facility, setFacility)
                  }
                />
                <SelectInputWithLabel
                  name='CarParking'
                  inputLabel='Car Parking'
                  value={practice.CarParking}
                  options={carParkingOptions()}
                  labelProperty='name'
                  valueProperty='value'
                  handleChange={(e) =>
                    handleInputChange(e, practice, setPractice)
                  }
                />
                <SelectInputWithLabel
                  name='ReceptionCover'
                  inputLabel='Reception Cover'
                  value={practice.ReceptionCover}
                  options={receptionCoverOptions()}
                  labelProperty='name'
                  valueProperty='value'
                  handleChange={(e) =>
                    handleInputChange(e, practice, setPractice)
                  }
                />
                <SelectInputWithLabel
                  name='NumberOfTreatmentAreas'
                  inputLabel='Number of treatment areas'
                  value={practice.NumberOfTreatmentAreas}
                  options={numberOfTreatmentAreasOptions()}
                  labelProperty='name'
                  valueProperty='value'
                  handleChange={(e) =>
                    handleInputChange(e, practice, setPractice)
                  }
                />
              </div>
            )}
          </div>
          <PartitionLine style={{ padding: "2rem 0rem" }} />
          {facility && practice && facilityTypeOptions && (
            <div style={{ padding: "2rem 6rem" }}>
              <div className='container-50' style={{ marginTop: "2rem" }}>
                <CheckboxInputWithLabel
                  inputLabel='Clinic directions sent to patient'
                  name='DirectionsSentToPatient'
                  value={practice.DirectionsSentToPatient}
                  handleChange={(e) =>
                    handleInputChange(e, practice, setPractice)
                  }
                />
              </div>
              <TextAreaInputWithLabel
                inputLabel='Is there public transport nearby (e.g. bus route, nearest tube, train station, etc.)'
                name='PublicTransport'
                value={facility.PublicTransport}
                charMax={500}
                handleChange={(e) =>
                  handleInputChange(e, facility, setFacility)
                }
              />
            </div>
          )}
          <div style={{ padding: "4rem" }}>
            <Button label='Save' handleClick={() => {}} type='submit' />
          </div>
        </div>
      </form>
    </PageState>
  );
};
export default Amenities;
