import { CallStates } from "../../../../SharedComponents/AppStates/Utils";

export const getModalities = (api, facilityID, setCallState) => {
  return api
    .transact("GetClinicModalities", { facilityID: facilityID })
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const SaveModalities = (api, modalities, setCallState) => {
  return api
    .transact("SaveClinicModalities", { Modalities: modalities })
    .then((response) => {
      setCallState(CallStates.SAVED);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
