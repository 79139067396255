import { CallStates } from "../../../../SharedComponents/AppStates/Utils";

export const getFeesData = (api, facilityID, setCallState) => {
  return api
    .transact("GetClinicFees", { facilityID: facilityID})
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const saveFee = (api, fee, setCallState) => {
  setCallState(CallStates.SAVING);
  fee.DateFrom = new Date(fee.DateFrom).toISOString();
  if (fee.DateTo) {
    fee.DateTo = new Date(fee.DateTo).toISOString();
  }
  return api
    .transact("SaveClinicFee", { Fee: fee })
    .then((response) => {
      setCallState(CallStates.SAVED);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const deleteFee = (api, fee, setCallState) => {
  setCallState(CallStates.SAVING);
  return api
    .transact("DeleteClinicFee", { Fee: fee })
    .then((response) => {
      setCallState(CallStates.SAVED);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
