import { useState, useEffect } from "react";

//api calls
import { deleteCorrespondence } from "../Transactions";

//button components
import { ListPageNavigationBar } from "../../../../../SharedComponents/ButtonComponents/ListNavigationButtons/NavigationButtons";
import { Button } from "../../../../../SharedComponents/ButtonComponents/Button";

//input components
import { SearchInput } from "../../../../../SharedComponents/InputComponents/SearchInput";

//page management
import { NoTableDataToDisplay } from "../../../../../SharedComponents/Tables/NoData";

//label components
import {
  FormLabel,
  DateLabel,
} from "../../../../../SharedComponents/FormComponents/FormLabel";

//page mangement
import { CallStates } from "../../../../../SharedComponents/AppStates/Utils";

//styling
import "../../../../../SharedComponents/Styles/SharedTableStyles.css"

const CorrespondenceList = ({
  correspondence,
  subjectTypeOptions,
  api,
  setCorrespondenceToEdit,
  setCallState,
  setErrorMessage,
}) => {
  const [initialCorrespondence, setInitialCorrespondence] = useState(null);
  const [filteredCorrespondence, setFilteredCorrespondence] = useState(null);
  const [displayedCorrespondence, setDisplayedCorrespondence] = useState(null);
  const searchProperties = [
    "CorrespondenceDate",
    "CorrespondenceFrom",
    "CorrespondenceTo",
    "CorrespondenceSubject",
    "InsertedBy",
    "SubjectType",
  ];

  useEffect(() => {
    setInitialCorrespondence(correspondence);
    setFilteredCorrespondence(correspondence);
  }, [correspondence]);

  const handleDeleteClick = (event, email) => {
    event.preventDefault();
    deleteCorrespondence(api, email, setCallState)
      .then(() => {
        setCallState(CallStates.SAVED);
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setCallState(CallStates.ERROR);
      });
  };

  return (
    <div className="react-shared-table">
      {initialCorrespondence && (
        <div style={{ marginTop: "1rem" }}>
          <SearchInput
            data={initialCorrespondence}
            setFilteredData={setFilteredCorrespondence}
            filterProperties={searchProperties}
          />
        </div>
      )}
      {filteredCorrespondence && filteredCorrespondence.length > 0 && (
        <div style={{ marginTop: "0.5rem" }}>
          <ListPageNavigationBar
            allItems={filteredCorrespondence}
            itemsPerPage={10}
            setItemsToRender={setDisplayedCorrespondence}
          />
        </div>
      )}
      <table className='background-color'>
        <thead>
          <tr>
            <th>Date</th>
            <th>From</th>
            <th>To</th>
            <th>Subject</th>
            <th>Subject Type</th>
            <th>Inserted By</th>
            {displayedCorrespondence && <th></th>}
            {displayedCorrespondence && <th></th>}
          </tr>
        </thead>
        <tbody>
          {displayedCorrespondence &&
            displayedCorrespondence.map((item, index) => (
              <tr key={index}>
                <td>
                  <DateLabel displayValue={item.CorrespondenceDate} />
                </td>
                <td>
                  <FormLabel displayValue={item.CorrespondenceFrom} />
                </td>
                <td>
                  <FormLabel displayValue={item.CorrespondenceTo} />
                </td>
                <td>
                  <FormLabel displayValue={item.CorrespondenceSubject} />
                </td>
                <td>
                  {item.SubjectTypeID && (
                    <FormLabel
                      displayValue={
                        subjectTypeOptions.find(
                          (option) =>
                            option.SubjectTypeID === item.SubjectTypeID
                        ).Description
                      }
                    />
                  )}
                </td>
                <td>
                  <FormLabel displayValue={item.InsertedBy} />
                </td>
                <td>
                  <Button
                    label='Edit'
                    handleClick={(e) => {
                      e.preventDefault();
                      (setCorrespondenceToEdit(item));
                    }}
                  />
                </td>
                <td>
                  <Button
                    label='Delete'
                    handleClick={(e) => handleDeleteClick(e, item)}
                  />
                </td>
              </tr>
            ))}
          {!displayedCorrespondence && (
            <NoTableDataToDisplay
              missingDataDescription='correspondence'
              numberOfColumnsToSpan={6}
            />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CorrespondenceList;
