import { validate } from "../../lib/iprs-react-library/src/Form/FormFunctions/validationFunctions";

/**
 * This should be in the library properly but I need a fix that works now. The 
 * reactifying of reports work will fix this more perminently. 
 */
const areFieldsValid = (fieldDefinitions, newFieldData) => {

    const objectIndex = 0; // we only support 0 in eForms

    const flatFieldDefinitions = fieldDefinitions.flatMap(fd => fd.Question); 
    const fieldDefinitionsWithoutStaticData = 
    flatFieldDefinitions.filter(field => field.controlType?.toLowerCase() !== 'hidden');

        const formValid =
        fieldDefinitionsWithoutStaticData?.reduce((accumulator, currentValue) => accumulator && validate(currentValue, newFieldData, objectIndex), true);

    return formValid;
}

export default areFieldsValid;