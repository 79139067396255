import React, {useEffect, useState} from 'react'
import F2FSelfReferral from '../pharos/F2FSelfReferral';
import toBoolean from '../../lib/iprs-react-library/src/util/toBoolean';

// This component will show the button if 'GetBrandingInfo' response has
// 'InsertF2FSelfReferralButton' and if it set to true.
const F2FReferralButton = ({api, caseID}) => {

    const [showF2FButton, setShowF2FButton] = useState(false)

    useEffect(()=>{
        const params = {
            "CaseID": caseID
        }
        api.transact('GetBrandingInfo', params)
            .then(r=>{
                console.log(r.apiResult);
                const f2fBoolean = toBoolean(r.apiResult.Settings.InsertF2FSelfReferralButton);

                if(f2fBoolean){
                    setShowF2FButton(true);
                }else{
                    setShowF2FButton(false);
                }
            })
            .catch(e=>{
                console.log(e)
            });
    },[api, caseID])

    return (
        <div className="clinicSearch">
            {showF2FButton ? <F2FSelfReferral api={api} /> : <></>}
        </div>
    )
}

export default F2FReferralButton; 