import { useState, useEffect } from "react";

//api calls
import { getCorrespondence } from "./Transactions";

//page components
import CorrespondenceList from "./SectionComponents/CorrespondenceList";

//button components
import { Button } from "../../../../SharedComponents/ButtonComponents/Button";

//modal components
import Modal from "../../../../SharedComponents/ModalComponents/Modal";
import EmailTemplate from "./SectionComponents/EmailTemplate";

//page management
import PageState from "../../../../SharedComponents/AppStates/PageState";
import { CallStates } from "../../../../SharedComponents/AppStates/Utils";

const Correspondence = ({ facilityID, api }) => {
  const [correspondence, setCorrespondence] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [subjectTypeOptions, setSubjectTypeOptions] = useState([]);
  const [correspondenceToEdit, setCorrespondenceToEdit] = useState(null);

  const [sendCorrespondenceEditorIsOpen, setSendCorrespondenceEditorIsOpen] =
    useState(false);
  const [saveCorrespondenceEditorIsOpen, setSaveCorrespondenceEditorIsOpen] =
    useState(false);

  const [callState, setCallState] = useState(CallStates.INITIAL);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (callState === CallStates.INITIAL || callState === CallStates.SAVED || callState === CallStates.SENT) {
      setSendCorrespondenceEditorIsOpen(false);
      setSaveCorrespondenceEditorIsOpen(false);
      getCorrespondence(facilityID, api, setCallState)
        .then((data) => {
          if (data.correspondence.Correspondence) {
            setCorrespondence(data.correspondence.Correspondence);
          }
          if (data.correspondence.Contacts) {
            setRecipients(data.correspondence.Contacts);
          }
          setSubjectTypeOptions(data.subjectTypes.SubjectTypes);
          setCallState(CallStates.LOADED);
        })
        .catch((e) => {
          setErrorMessage(e.message);
          setCallState(CallStates.ERROR);
        });
    }
  }, [callState]);

  useEffect(() => {
    if (correspondenceToEdit && !saveCorrespondenceEditorIsOpen) {
      setSaveCorrespondenceEditorIsOpen(true);
    }
  }, [correspondenceToEdit]);

  const closeModals = () => {
    setSendCorrespondenceEditorIsOpen(false);
    setSaveCorrespondenceEditorIsOpen(false);
    setCorrespondenceToEdit(null);
  };

  return (
    <PageState callState={callState} errorMessage={errorMessage}>
      <div className='fade-in padding2'>
        <div style={{ display: "flex" }}>
          <div style={{ paddingRight: "0.5rem" }}>
            <Button
              label='Add correspondence'
              handleClick={() => setSaveCorrespondenceEditorIsOpen(true)}
            />
          </div>
          <div>
            <Button
              label='Send new email'
              handleClick={() => setSendCorrespondenceEditorIsOpen(true)}
            />
          </div>
        </div>

        {correspondence && subjectTypeOptions && (
          <CorrespondenceList
            correspondence={correspondence}
            subjectTypeOptions={subjectTypeOptions}
            api={api}
            setCorrespondenceToEdit={setCorrespondenceToEdit}
            setCallState={setCallState}
            setErrorMessage={setErrorMessage}
          />
        )}
        <div>
          <Modal
            isOpen={
              saveCorrespondenceEditorIsOpen || sendCorrespondenceEditorIsOpen
            }
            onClose={() => closeModals()}
          >
            <div
              style={{
                padding: "2rem 1rem 0rem 1rem",
              }}
            >
              {saveCorrespondenceEditorIsOpen && (
                <EmailTemplate
                  api={api}
                  existingEmail={correspondenceToEdit}
                  facilityID={facilityID}
                  setParentErrorMessage={setErrorMessage}
                  setParentCallState={setCallState}
                  isSave={true}
                  closeModal={closeModals}
                />
              )}
              {sendCorrespondenceEditorIsOpen && (
                <EmailTemplate
                  api={api}
                  recipients={recipients}
                  facilityID={facilityID}
                  setParentErrorMessage={setErrorMessage}
                  setParentCallState={setCallState}
                  closeModal={closeModals}
                />
              )}
            </div>
          </Modal>
        </div>
      </div>
    </PageState>
  );
};

export default Correspondence;
