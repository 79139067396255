import "./Style.css";

const ContractText = ({ contractInfo }) => {
  return (
    <div className='contract'>
      <div
        dangerouslySetInnerHTML={{
          __html: contractInfo.ContractText,
        }}
      />
    </div>
  );
};
export default ContractText;
