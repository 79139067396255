import mailIcon from "../../../assets/button-icons/mail.png"

export const SendEmailButton = ({ handleClick }) => {
  return (
    <img
      src={mailIcon}
      alt='Send email'
      style={{
        width: "auto",
        height: "25px",
        objectFit: "contain",
        cursor: "pointer",
      }}
      onClick={handleClick}
    />
  );
};
