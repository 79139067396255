//input components
import { SelectInputWithLabel } from "../../../../../../SharedComponents/InputComponents/SelectInput";
import { DateInputWithLabel } from "../../../../../../SharedComponents/InputComponents/DateInput";
import { CheckboxInputWithLabel } from "../../../../../../SharedComponents/InputComponents/CheckboxInput";

//label components
import { FormLabelWithLabel } from "../../../../../../SharedComponents/FormComponents/FormLabel";

//styling
import "../../../../../../SharedComponents/Styles/SharedGenericStyles.css";

const ClinicDetails = ({
  facility,
  practice,
  practiceRankings,
  handleFacilityChange,
  handlePracticeChange,
  readonly = false,
}) => {
  const { FacilityID, FacilityName, Disused, DisusedDate } = facility;
  const { PracticeRankingId, DateOfApplication } = practice;
  return (
    <>
      <div className='container-100'>
        <div>
          <FormLabelWithLabel label='Practice ID' displayValue={FacilityID} />
          <FormLabelWithLabel
            label='Practice Name'
            displayValue={FacilityName}
          />
          <SelectInputWithLabel
            name='PracticeRankingId'
            inputLabel='Ranking'
            value={PracticeRankingId}
            options={practiceRankings}
            labelProperty='PracticeRankingDescription'
            valueProperty='PracticeRankingID'
            handleChange={handlePracticeChange}
          />
        </div>
        <div className='padding-top-2'>
          <CheckboxInputWithLabel
            inputLabel='Disused'
            name='Disused'
            value={Disused}
            handleChange={handleFacilityChange}
          />
          <DateInputWithLabel
            inputLabel='Disused Date'
            name='DisusedDate'
            value={DisusedDate ?? ""}
            handleChange={handleFacilityChange}
          />
          <DateInputWithLabel
            inputLabel='Date of Application'
            name='DateOfApplication'
            value={DateOfApplication ?? ""}
            handleChange={handlePracticeChange}
          />
        </div>
      </div>
    </>
  );
};
export default ClinicDetails;
