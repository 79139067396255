const createNewListItem = (onClick, title) => {
    const newLi = document.createElement("li");
    const anchor = document.createElement("a");
    anchor.addEventListener("click", onClick);
    anchor.setAttribute("href", "#");
    anchor.innerText = title;
    newLi.appendChild(anchor);
    return newLi;
};

const createSecondLevelMenuIfNotPresent = topLevelLi => {
    let newUl;
    if (topLevelLi.children.length === 1) {
        const anchor = topLevelLi.children[0];
        anchor.setAttribute("class", "dropdown-toggle");
        anchor.setAttribute("data-toggle", "dropdown");
        const anchorTitle = anchor.innerText;
        const enchorHref = anchor.getAttribute("href");
        anchor.setAttribute("href", "#");

        const movedTopMenuLi = createNewListItem(() => {
            window.location.href = enchorHref;
        }, anchorTitle);

        const dropdownIcon = document.createElement("div");
        dropdownIcon.setAttribute("id", "WebControlKitMenuDownArrow");
        anchor.appendChild(dropdownIcon);

        newUl = document.createElement("ul");
        newUl.setAttribute("class", "dropdown-menu");
        newUl.setAttribute("role", "menu");

        newUl.appendChild(movedTopMenuLi);

        topLevelLi.appendChild(newUl);
    } else {
        newUl = topLevelLi.children[1];
    }
    return newUl;
};

const addMainMenuItem = (menuIndex, onClick, title) => {
    const pharosNavBar = document.getElementById("MainHeaderMenu");
    if (pharosNavBar) {
        const topLevelLi = pharosNavBar.children[menuIndex];

        const secondLevelList = createSecondLevelMenuIfNotPresent(topLevelLi);

        const newLi = createNewListItem(onClick, title);

        secondLevelList.appendChild(newLi);
    }
};

export default addMainMenuItem;
