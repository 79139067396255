import { useState, useEffect } from "react";

//api calls
import { getPatients } from "./Transactions";

//input components
import { SearchInput } from "../../../../SharedComponents/InputComponents/SearchInput";

//button components
import ExcelExport from "../../../../SharedComponents/ExportComponents/ExcelExport";

//page management
import PageState from "../../../../SharedComponents/AppStates/PageState";
import { CallStates } from "../../../../SharedComponents/AppStates/Utils";
import { NoTableDataToDisplay } from "../../../../SharedComponents/Tables/NoData";

//label components
import { DateLabel } from "../../../../SharedComponents/FormComponents/FormLabel";
import { FormLabel } from "../../../../SharedComponents/FormComponents/FormLabel";

//functions
import { navigateToCase } from "./Utils";

//styling
import "../../../../SharedComponents/Styles/SharedTableStyles.css"

const Patients = ({ facilityID, api }) => {
  const [initialPatients, setInitialPatients] = useState(null);
  const [filteredPatients, setFilteredPatients] = useState(null);
  const searchProperties = [
    "CaseID",
    "PatientName",
    "LatestClinician",
    "EmployerName",
  ];

  const [callState, setCallState] = useState(CallStates.INITIAL);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (callState === CallStates.INITIAL || callState === CallStates.SAVED) {
      getPatients(facilityID, setCallState, api)
        .then((data) => {
          setInitialPatients(data);
          setFilteredPatients(data);
          setCallState(CallStates.LOADED);
        })
        .catch((e) => {
          setErrorMessage(e.message);
          setCallState(CallStates.ERROR);
        });
    }
  }, []);

  //the button is generated by the tableexport library used in ExcelExport component
  return (
    <PageState callState={callState} errorMessage={errorMessage}>
      <div className='fade-in react-shared-table' style={{ padding: "4rem" }}>
        {filteredPatients && (
          <ExcelExport
            fileName={`${filteredPatients[0]?.FacilityName} (${facilityID}) Patients`}
            sheetName='Patients'
          >
            <div style={{ marginTop: "1rem" }}>
              <SearchInput
                data={initialPatients}
                setFilteredData={setFilteredPatients}
                filterProperties={searchProperties}
              />
            </div>

            <table style={{ marginTop: "0.5rem" }} className='background-color'>
              <thead>
                <tr>
                  <th>CaseID</th>
                  <th>Patient Name</th>
                  <th>Date Referral Received</th>
                  <th>Date Referred to Network</th>
                  <th>Assigned Clinician</th>
                  <th>IA Report Received Date</th>
                  <th>Client Name</th>
                </tr>
              </thead>
              <tbody>
                {filteredPatients &&
                  filteredPatients.map((item, index) => (
                    <tr
                      className='clickable'
                      key={index}
                      onClick={() => navigateToCase(item.CaseId)}
                    >
                            <td>
                                <FormLabel
                                displayValue={item.CaseID}
                                clickable={true}
                                />
                            </td>
                            <td>
                                <FormLabel
                                displayValue={item.PatientName}
                                clickable={true}
                                />
                            </td>
                            <td>
                                <DateLabel
                                displayValue={item.ReferralDate}
                                clickable={true}
                                />
                            </td>
                            <td>
                                <DateLabel
                                displayValue={item.DateToNetwork}
                                clickable={true}
                                />
                            </td>
                            <td>
                                <FormLabel
                                displayValue={item.LatestClinician}
                                clickable={true}
                                />
                            </td>
                            <td>
                                <DateLabel
                                displayValue={item.IAReportRecd}
                                clickable={true}
                                />
                            </td>
                            <td>
                                <FormLabel
                                displayValue={item.EmployerName}
                                clickable={true}
                                />
                            </td>
                    </tr>
                  ))}
                {(!filteredPatients || filteredPatients.length < 1) && (
                  <NoTableDataToDisplay
                    missingDataDescription='patient'
                    numberOfColumnsToSpan={7}
                  />
                )}
              </tbody>
            </table>
          </ExcelExport>
        )}
      </div>
    </PageState>
  );
};
export default Patients;
