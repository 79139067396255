import { useEffect, useState } from "react";

//page components
import ReadOnlyFee from "./ReadOnlyFee";

//input components
import { SearchInput } from "../../../../../SharedComponents/InputComponents/SearchInput";

//page management
import { NoTableDataToDisplay } from "../../../../../SharedComponents/Tables/NoData";

//button components
import { Button } from "../../../../../SharedComponents/ButtonComponents/Button";

//styling
import "../../../../../SharedComponents/Styles/SharedTableStyles.css"

const FeesList = ({ fees, handleRemove, setFeeToEdit = null }) => {
  const [initialFees, setInitialFees] = useState(null);
  const [filteredFees, setFilteredFees] = useState(null);

  const searchProperties = ["ServiceChargeDescription", "OrganisationName"];

  useEffect(() => {
    setInitialFees(fees);
    setFilteredFees(fees);
  }, []);

  return (
    <div className="react-shared-table">
      {initialFees && (
        <div style={{ marginBottom: "0.5rem" }}>
          <SearchInput
            data={initialFees}
            setFilteredData={setFilteredFees}
            filterProperties={searchProperties}
          />
        </div>
      )}
      <table
        style={{ borderCollapse: "collapse" }}
        className='container-75 background-color'
      >
        <thead>
          <tr>
            <th>Service Description</th>
            <th>Client Specific</th>
            <th>Fee</th>
            <th>Date From</th>
            <th>Date To</th>
            {setFeeToEdit && (
              <>
                <th></th>
                <th></th>
              </>
            )}
          </tr>
        </thead>
        {filteredFees && (
          <tbody>
            {filteredFees.map((item, index) => (
              <tr key={index}>
                <>
                  <ReadOnlyFee fee={item} />
                  {setFeeToEdit && (
                    <>
                      <td>
                        <Button
                          label='Edit'
                          handleClick={() => setFeeToEdit(item)}
                        />
                      </td>
                      <td>
                        <Button
                          label='Remove'
                          handleClick={() => handleRemove(item)}
                        />
                      </td>
                    </>
                  )}
                </>
              </tr>
            ))}
          </tbody>
        )}
        {!filteredFees || filteredFees.length < 1 && (
          <NoTableDataToDisplay
            missingDataDescription='fees'
            numberOfColumnsToSpan={setFeeToEdit ? 7 : 5}
          />
        )}
      </table>
    </div>
  );
};
export default FeesList;
