//input components
import { SelectYesNoInputWithLabel } from "../../../../../SharedComponents/InputComponents/SelectInput";

//styling
import "../../../../../SharedComponents/Styles/SharedGenericStyles.css";

const AmenitiesList = ({ amenities, handleChange }) => {
  return (
    <div>
      {amenities.map((item, index) => (
        <div key={index}>
          <SelectYesNoInputWithLabel
            inputLabel={item.AmenityDescription}
            name='Available'
            value={item.Available}
            handleChange={(e) => handleChange(e, index)}
          />
        </div>
      ))}
    </div>
  );
};
export default AmenitiesList;
