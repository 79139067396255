import { CallStates } from "../../../SharedComponents/AppStates/Utils";

export const getOrganisationID = async (facilityID, api, setCallState) => {
  //   return 2001;
  return api
    .transact("GetOrganisationIdByFacilityId", { FacilityID: facilityID })
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
