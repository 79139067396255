import React, { useState } from "react";
import InvoiceUploadStep from "../InvoiceExcelUpload/InvoiceUploadStep";
import FailedInvoiceStep from "../FailedInvoiceReview/FailedInvoiceStep";
import "../../../components/SharedComponents/Styles/ExcelUpload.css";

const InvoiceFormStepper = ({ api }) => {
  const [currentStep, setCurrentStep] = useState("upload"); 
  const [failedRows, setFailedRows] = useState([]); 

 
  const moveToNextStep = () => {
    setCurrentStep("review");
  };

  return (
    <div className="form-stepper-container">
      {currentStep === "upload" ? (
        <InvoiceUploadStep
          api={api}
          setFailedRows={setFailedRows}
          moveToNextStep={moveToNextStep}
          setCurrentStep={setCurrentStep}
        />
      ) : (
        <FailedInvoiceStep
          api={api}
          failedRows={failedRows}
          setFailedRows={setFailedRows}
          setCurrentStep={setCurrentStep}
        />
      )}
    </div>
  );
};

export default InvoiceFormStepper;
