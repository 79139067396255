export const feeModel = (facilityID) => {
  return {
    ActionFlag: 2,
    ServiceChargeId: "",
    ClientPartyId: "",
    Amount: 0,
    DateFrom: "",
    DateTo: "",
    FacilityID: facilityID,
  };
};
