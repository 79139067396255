import { formatDateForDisplay } from "../Utils/DateUtils";
import "../Styles/SharedGenericStyles.css";

const randomClassName = `class-${Math.random().toString(36).substring(7)}`;

export const FormLabelWithLabel = ({ label, displayValue }) => {
  return (
    <div className='react-shared-InLine container-100-justified-content'>
      <div>
        <label>{label}: </label>
      </div>
      <div className='container-50-align-left'>
        <label>{displayValue}</label>
      </div>
    </div>
  );
};

export const FormLabel = ({ displayValue, clickable = false }) => {
  return clickable ? (
    <label
      type='text'
      className={randomClassName}
      style={{ cursor: "pointer" }}
    >
      {displayValue}
    </label>
  ) : (
    <label>{displayValue}</label>
  );
};

export const CurrencyLabel = ({ displayValue }) => {
  return (
    <>
      <label>
        {displayValue.toLocaleString("en-GB", {
          style: "currency",
          currency: "GBP",
        })}
      </label>
    </>
  );
};

export const DateLabel = ({ displayValue, clickable = false }) => {
  if (displayValue !== "") {
    return (
      <div>
        {clickable ? (
          <label style={{ cursor: "pointer" }}>
            {formatDateForDisplay(displayValue)}
          </label>
        ) : (
          <label>{formatDateForDisplay(displayValue)}</label>
        )}
      </div>
    );
  } else {
    return <label></label>;
  }
};

export const DateLabelWithLabel = ({ label, displayValue }) => {
  return (
    <div className='react-shared-InLine'>
      <label>{label}: </label>
      {displayValue && <label>{formatDateForDisplay(displayValue)}</label>}
    </div>
  );
};

export const TextWithLabel = ({ label, displayValue }) => {
  return (
    <div className='react-shared-InLine'>
      <label>{label}: </label>
      {displayValue && <label>{displayValue}</label>}
    </div>
  );
};

export const CheckboxDisabled = ({ displayValue }) => {
  return (
    <>
      {displayValue && (
        <div>
          <input type='checkbox' checked={displayValue} disabled={true} />
        </div>
      )}
    </>
  );
};
