import "./Style.css";
import "../../SharedComponents/Styles/SharedGenericStyles.css";
import iprsGroupCrossLogo from "../../assets/page-state-icons/IprsGroupCrossLogo.png";

export const RotatingIprsIconWithText = ({ displayText }) => {
  return (
    <div className="react-shared">
        <div className='container-centre-column'>
            <div className='background-rounded-corners-100-by-100'>
                <strong style={{ paddingBottom: "10px" }}>{displayText}</strong>
                <img className='rotate' src={iprsGroupCrossLogo} alt='IPRS logo' />
            </div>
        </div>
    </div>
  );
};

export const IprsIconWithText = ({ displayText, message = null }) => {
  return (
    <div className="react-shared">
        <div className='container-centre-column'>
            <div className='background-rounded-corners-100-by-100'>
                <strong style={{ paddingBottom: "10px" }}>{displayText}</strong>
                <img className='static' src={iprsGroupCrossLogo} alt='IPRS logo' />
            </div>
            {message && <p>{message}</p>}
        </div>
    </div>
  );
};
