import React, { useState, useEffect, useCallback } from "react";
import { LoadingIndicator } from "../../../index.js";
import { removeUserFile } from "./api/removeUserFile";

// This component renders a delete button for files.
const FileTreeDeleteButton = ({
    api,
    pathName,
    guid,
    fetchUpdatedFileTree,
    partyId,
}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        if (error) {
            console.error("Error occurred:", error);
        }
    }, [error]);

    const fileDeleteButtonHandler = useCallback(async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const { data, error } = await removeUserFile(api, partyId, guid, pathName);
            if (error) {
                setError(error);
            } else {
                fetchUpdatedFileTree(data);
            }
        } finally {
            setLoading(false);
        }
    }, [api, partyId, guid, pathName, fetchUpdatedFileTree]);

    return (
        <button
            className="iprsPortalFileTreeDictionaryItemButton"
            onClick={fileDeleteButtonHandler}
            disabled={loading}
        >
            {loading ? (
                <LoadingIndicator loading={true} color={"white"} />
            ) : (
                <>
                    <svg width="22" height="22">
                        <use href="#icon-trash" />
                    </svg>
                    <span className="iprsPortalFileTreeDictionaryItemDownloadText">
                        Delete
                    </span>
                </>
            )}
        </button>
    );
};

export default FileTreeDeleteButton;