//sort out all the options that are needed for the clinician form
export const professionalBodyOptions = [
  { ProfessionalBodyId: 4838, name: "HCPC" },
  { ProfessionalBodyId: 4833, name: "AACP" },
  { ProfessionalBodyId: 4834, name: "ACPOHE" },
  { ProfessionalBodyId: 4835, name: "BACP" },
  { ProfessionalBodyId: 4836, name: "GCC" },
  { ProfessionalBodyId: 4837, name: "GOC" },
  { ProfessionalBodyId: 4839, name: "MACP" },
  { ProfessionalBodyId: 4841, name: "CSP" },
  { ProfessionalBodyId: 195223, name: "BABCP" },
  { ProfessionalBodyId: 351126, name: "NCS" },
];

export const DBSTemplate = {
  ProfessionalBodyId: 4840,
  RegistrationDate: "",
  ExpiryDate: "",
  RegistrationNumber: "",
  RowVersion: null,
};

export const personTemplate = {
  PersonTitle: "",
  FirstName: "",
  MiddleNames: "",
  LastName: "",
  Suffix: "",
  Alias: "",
  BirthDate: "",
  Gender: "",
  CanSpeakAnotherLanguage: false,
  AdditionalLanguages: "",
  JobTitle: "",
};

export const clinicianTempalte = {
  AbleToTreatMinors: false,
  ModalityID: "",
  QualificationYear: "",
  FullTime: false,
  InsuranceDocumentChecked: false,
};

export const userGroupOptions = [
  { GroupID: 2020, name: "Network Practice HQ", selected: false },
  { GroupID: 2021, name: "Network Practice Site", selected: false },
];

export const personGenderOptions = [
  { value: "U", name: "Unknown" },
  { value: "M", name: "Male" },
  { value: "F", name: "Female" },
];

export const personTitleOptions = [
  { value: "Mr", name: "Mr" },
  { value: "Miss", name: "Miss" },
  { value: "Mrs", name: "Mrs" },
  { value: "Ms", name: "Ms" },
  { value: "Dr", name: "Dr" },
  { value: "Unknown", name: "Unknown" },
];

export const jobTitleOptions = [
  { value: "Physiotherapist", name: "Physiotherapist" },
  { value: "Mental Health Practitioner", name: "Mental Health Practitioner" },
  { value: "Main Administrator", name: "Main Administrator" },
  { value: "Finance Administrator", name: "Finance Administrator" },
  { value: "Operation Manager", name: "Operation Manager" },
];

export const modalityOptions = [
  { value: 1, name: "Physio" },
  { value: 24, name: "CBT" },
  { value: 25, name: "Counselling" },
  { value: 40, name: "Clinical Psychology" },
];
