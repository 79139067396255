import React, { useState, useEffect } from "react";
import ClientSettingsTableContentRow from "./ClientSettingsTableContentRow";

const ClientSettingsTableContent = ({ api, settings, clientID }) => {
    const tableContent = settings.map(setting => (
        <ClientSettingsTableContentRow
            api={api}
            key={"" + clientID + "_" + setting.SettingKey} // make clear each client is different
            setting={setting}
            clientID={clientID}
        />
    ));

    return (
        <>
            {clientID > 0 ? (
                tableContent
            ) : (
                <tr colSpan="4">No settings available</tr>
            )}
        </>
    );
};

export default ClientSettingsTableContent;
