import React, { useState } from "react";
import { LoadingIndicator } from "../../../index.js";

const AddDirectoryInFileTree = ({ addNewDirectoryInThisDirectory }) => {
    const [loading, setLoading] = useState(false);

    const addDirectoryButtonHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Simulate an async call by awaiting the parent function
        await addNewDirectoryInThisDirectory();

        // Add a short delay to simulate async behavior (only for testing purposes)
        setTimeout(() => {
            setLoading(false);
        }, 100);
    };

    return (
        <button
            className="iprsPortalFileTreeDictionaryItemButton"
            onClick={addDirectoryButtonHandler}
        >
            {loading ? (
                <LoadingIndicator loading={true} color={"white"} />
            ) : (
                <>
                    <svg width="22" height="22">
                        <use href="#icon-arrow-upload" />
                    </svg>
                    <span className="iprsPortalFileTreeDictionaryItemDownloadText">
                        Add Directory
                    </span>
                </>
            )}
        </button>
    );
};

export default AddDirectoryInFileTree