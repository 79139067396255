import { SelectBooleanInput } from "../../../../../../SharedComponents/InputComponents/SelectInput";
import { TextInput } from "../../../../../../SharedComponents/InputComponents/TextInput";
import "../../../../AppUtils/Styles/ContainerStyles.css";
import "../../../../../../SharedComponents/Styles/SharedGenericStyles.css";

const GovernanceRiskSecurity = ({ facility, handleChange }) => {
  const {
    GovPolicyCompliance,
    RiskAssessment,
    ISRegNumber,
    DPP,
    PEEBackgroundChecks,
    DPTrainingProgram,
    SecurityAwarenessTraining,
    CESClinicalPathwayCompliant
  } = facility;
  return (
    <div style={{paddingTop: "2rem"}}>
      <div>
        <div>
          <h3>
            Does the organisation/practice/group agree to comply with the IPRS
            Health Network Governance Policy?
          </h3>
        </div>
        <div>
          <SelectBooleanInput
            name='GovPolicyCompliance'
            value={GovPolicyCompliance}
            handleChange={handleChange}
          />
        </div>
        <div style={{paddingTop : "1rem"}}>
          <h3>
            Are the organisation's physiotherapists directed to use a documented <label style={{fontWeight: "bold", fontSize: "medium"}}>Cauda Equina Syndrome (CES) clinical pathway</label> based on best practice?
          </h3>
        </div>
        <div>
          <SelectBooleanInput
            name='CESClinicalPathwayCompliant'
            value={CESClinicalPathwayCompliant}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className='bottom-container' style={{paddingTop: "2rem"}}>
        <div className='left-section' style={{paddingRight: "2rem"}}>
          <h3>Risk Assessment:</h3>
          <p>
            In accordance with health and safety at work regulations, has a
            premises risk assessment been completed?
          </p>
          <div style={{paddingTop: "1rem"}}>
            <SelectBooleanInput
                name='RiskAssessment'
                value={RiskAssessment}
                handleChange={handleChange}
            />
          </div>
        </div>
        <div className='right-section' style={{paddingLeft: "2rem"}}>
          <h3>Information Security:</h3>
          <p>ICO Registration Number:</p>
          <div style={{paddingTop: "0.7rem", paddingBottom: "0.5rem"}}>
            <TextInput
                name='ISRegNumber'
                value={ISRegNumber}
                handleChange={handleChange}
            />
          </div>
          <p>Does your organisation have a Data Protection Policy?</p>
          <div style={{paddingTop: "0.7rem", paddingBottom: "0.5rem"}}>
            <SelectBooleanInput
                name='DPP'
                value={DPP}
                handleChange={handleChange}
            />
          </div>
          <p>
            Does your organisation have a Pre-employment Screening process for
            employee background checks?
          </p>
          <div style={{paddingTop: "0.7rem", paddingBottom: "0.5rem"}}>
            <SelectBooleanInput
                name='PEEBackgroundChecks'
                value={PEEBackgroundChecks}
                handleChange={handleChange}
            />
          </div>
          <p>
            Has your organisation implemented a Data Protection training
            programme?
          </p>
          <div style={{paddingTop: "0.7rem", paddingBottom: "0.5rem"}}>
            <SelectBooleanInput
                name='DPTrainingProgram'
                value={DPTrainingProgram}
                handleChange={handleChange}
            />
          </div>
          <p>
            Does your organisation provide Security Awareness training to staff?
          </p>
          <div style={{paddingTop: "0.7rem", paddingBottom: "0.5rem"}}>
            <SelectBooleanInput
                name='SecurityAwarenessTraining'
                value={SecurityAwarenessTraining}
                handleChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default GovernanceRiskSecurity;
