import React, { useState } from "react";
import { LoadingIndicator } from "../../../index.js";
import { downloadFile } from "./utils/fileDownloadUtils";

const FileTreeDownloadButton = ({ api, guid, fileName }) => {
    const [loading, setLoading] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleDownloadClick = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await downloadFile(guid, api, fileName); // Await to simulate a delay in download
        } finally {
            setLoading(false);
        }
    };

    const handleHoverEnter = () => setIsHovered(true);
    const handleHoverLeave = () => setIsHovered(false);

    const fileNameStyle = isHovered
        ? { color: "#DE7AAD", textDecoration: "underline" }
        : {};

    return (
        <button
            className="iprsPortalFileTreeDictionaryItemButton"
            onClick={handleDownloadClick}
            disabled={loading}
        >
            {loading ? (
                <LoadingIndicator loading={true} color={"white"} />
            ) : (
                <>
                    <span
                        className="iprsPortalFileTreeFileNameTextAndDownloadLink"
                        onMouseEnter={handleHoverEnter}
                        onMouseLeave={handleHoverLeave}
                        style={fileNameStyle}
                    >
                        {fileName}
                    </span>
                    {isHovered && (
                        <span className="iprsPortalFileTreeDictionaryItemButtonDownloadIcon">
                            <svg
                                width="16"
                                height="16"
                                style={{ fill: fileNameStyle.color || "#000" }}
                            >
                                <use href="#icon-arrow-download" />
                            </svg>
                        </span>
                    )}
                </>
            )}
        </button>
    );
};

export default FileTreeDownloadButton;