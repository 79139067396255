import { useEffect, useState } from "react";

//static data
import { DBSTemplate } from "./Utils";

//input componentss
import { SelectInputWithLabel } from "../../../../../../SharedComponents/InputComponents/SelectInput";
import { TextInputWithLabel } from "../../../../../../SharedComponents/InputComponents/TextInput";
import { DateInputWithLabel } from "../../../../../../SharedComponents/InputComponents/DateInput";

//functions
import {
  handleInputChangeWithinArray,
  handleInputChange,
} from "../../../../../../SharedComponents/Utils/InputUtils";

//button components
import { Button } from "../../../../../../SharedComponents/ButtonComponents/Button";

//styling
import "../../../../../../SharedComponents/Styles/SharedGenericStyles.css";

const ProfessionalBodies = ({
  clinicianProfessionalBodies = [],
  clinicianDBSDetails = null,
  setClinicianProfessionalBodies,
  setClinicianDBSDetails,
  professionalBodiesOptions,
  required = false,
}) => {
  const [professionalBodiesAvailable, setProfessionalBodiesAvailable] =
    useState(clinicianProfessionalBodies.length !== professionalBodiesOptions); //this happens before the empty dependency arary useEffect
  const [selectedProfessionalBodies, setSelectedProfessionalBodies] = useState(
    []
  );
  const [additionalProfessionalBody, setAdditionalProfessionalBody] = useState({
    ProfessionalBodyId: "",
    RegistrationDate: "",
    ExpiryDate: "",
    RegistrationNumber: "",
    addition: true,
  });
  const [DBSDetails, setDBSDetails] = useState(DBSTemplate);

  useEffect(() => {
    if (clinicianDBSDetails) {
      setDBSDetails(clinicianDBSDetails);
    } else {
      setClinicianDBSDetails(DBSDetails);
    }
  }, [clinicianDBSDetails]);

  useEffect(() => {
    if (clinicianProfessionalBodies) {
      setSelectedProfessionalBodies(clinicianProfessionalBodies);
    }
  }, [clinicianProfessionalBodies]);

  useEffect(() => {
    if (
      selectedProfessionalBodies.length === professionalBodiesOptions.length
    ) {
      setProfessionalBodiesAvailable(false);
    } else {
      setProfessionalBodiesAvailable(
        !selectedProfessionalBodies.some(
          (body) => body.ProfessionalBodyId === ""
        )
      );
    }
  }, [selectedProfessionalBodies]);

  const getAvailableOptions = (selectedID) => {
    const filteredOptions = professionalBodiesOptions.filter((option) => {
      if (option.ProfessionalBodyId === selectedID) {
        return true;
      }
      const remainingOptions = !selectedProfessionalBodies.some(
        (body) => body.ProfessionalBodyId === option.ProfessionalBodyId
      );
      return remainingOptions;
    });
    return filteredOptions;
  };

  const handleAddAdditionalProfessionalBody = (event) => {
    event.preventDefault();
    setClinicianProfessionalBodies((prevClinicianProfessionalBodies) => {
      return [...prevClinicianProfessionalBodies, additionalProfessionalBody];
    });
  };

  const handleRemoveAdditionalProfessionalBody = (event, index) => {
    event.preventDefault();
    setClinicianProfessionalBodies(
      (prevClinicianProfessionalBodies) =>
        prevClinicianProfessionalBodies.filter((_, i) => i !== index) //ignore the element itself, only interested in filtering by index here
    );
  };

  return (
    <div>
      {DBSDetails && (
        <div>
          <p>DBS details: </p>
          <TextInputWithLabel
            inputLabel='Registration Number'
            name='RegistrationNumber'
            value={DBSDetails.RegistrationNumber}
            handleChange={(e) =>
              handleInputChange(e, DBSDetails, setClinicianDBSDetails)
            }
            required={required}
          />
          <DateInputWithLabel
            inputLabel='Registration Date'
            name='RegistrationDate'
            value={DBSDetails.RegistrationDate}
            handleChange={(e) =>
              handleInputChange(e, DBSDetails, setClinicianDBSDetails)
            }
            required={required}
          />
          <DateInputWithLabel
            inputLabel='Expiry Date'
            name='ExpiryDate'
            value={DBSDetails.ExpiryDate}
            handleChange={(e) =>
              handleInputChange(e, DBSDetails, setClinicianDBSDetails)
            }
          />
        </div>
      )}
      {selectedProfessionalBodies &&
        selectedProfessionalBodies.map((item, index) => (
          <div key={item.ProfessionalBodyId} style={{ marginTop: "1rem" }}>
            <SelectInputWithLabel
              inputLabel='Professional body'
              name='ProfessionalBodyId'
              value={item.ProfessionalBodyId}
              options={getAvailableOptions(item.ProfessionalBodyId)}
              labelProperty='name'
              valueProperty='ProfessionalBodyId'
              handleChange={(e) =>
                handleInputChangeWithinArray(
                  e,
                  selectedProfessionalBodies,
                  setClinicianProfessionalBodies,
                  index
                )
              }
              required={required}
            />

            <TextInputWithLabel
              inputLabel='Registration Number'
              name='RegistrationNumber'
              value={item.RegistrationNumber}
              handleChange={(e) =>
                handleInputChangeWithinArray(
                  e,
                  selectedProfessionalBodies,
                  setClinicianProfessionalBodies,
                  index
                )
              }
              required={required}
            />
            <DateInputWithLabel
              inputLabel='Registration Date'
              name='RegistrationDate'
              value={item.RegistrationDate}
              handleChange={(e) =>
                handleInputChangeWithinArray(
                  e,
                  selectedProfessionalBodies,
                  setClinicianProfessionalBodies,
                  index
                )
              }
              required={required}
            />
            <DateInputWithLabel
              inputLabel='Expiry Date'
              name='ExpiryDate'
              value={item.ExpiryDate}
              handleChange={(e) =>
                handleInputChangeWithinArray(
                  e,
                  selectedProfessionalBodies,
                  setClinicianProfessionalBodies,
                  index
                )
              }
            />
            {item.addition && (
              <Button
                label='Remove'
                handleClick={(e) =>
                  handleRemoveAdditionalProfessionalBody(e, index)
                }
              />
            )}
          </div>
        ))}
      {professionalBodiesAvailable && (
        <div style={{ marginTop: "1rem" }}>
          <Button
            label='Add Additional Professional Body'
            handleClick={(e) => handleAddAdditionalProfessionalBody(e)}
          />
        </div>
      )}
    </div>
  );
};
export default ProfessionalBodies;
