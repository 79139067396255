// export const typeOptions = () => {
//   return [
//     { name: "N/A", value: 0 },
//     { name: "Above commercial premises", value: 1 },
//     { name: "Within a gym", value: 2 },
//     { name: "Health centre / GP Surgery", value: 3 },
//     { name: "Working from home", value: 4 },
//     { name: "Self-contained premises", value: 5 },
//     { name: "Rented room within another clinic / practice", value: 6 },
//   ];
// };

export const carParkingOptions = () => {
  return [
    { name: "None", value: "N" },
    { name: "Free car park", value: "F" },
    { name: "Paid car park", value: "P" },
    { name: "Free on-street parking", value: "FOS" },
    { name: "Paid on-street parking", value: "POS" },
  ];
};

export const receptionCoverOptions = () => {
  return [
    { name: "Full Time", value: "F" },
    { name: "Part Time", value: "P" },
    { name: "No", value: "N" },
  ];
};

//this is just to make the select options work as they only read strings so there is some conversion needed
export const numberOfTreatmentAreasOptions = () => {
  return [
    { name: "1", value: 1 },
    { name: "2", value: 2 },
    { name: "3", value: 3 },
    { name: "4", value: 4 },
    { name: "5+", value: 5 },
  ];
};

export const filterAmenities = (amenities) => {
  const amenityIDsToFilterOut = [1, 3, 8, 11, 12, 14, 16, 18];
  const filteredAmenities = [];
  amenities.forEach((item) => {
    if (!amenityIDsToFilterOut.includes(item.AmenityID)) {
      filteredAmenities.push(item);
    }
  });
  return filteredAmenities;
};

export const filterTypeOptions = (typeOptions) => {
  const typeIDsToFilterOut = [2001, 2002, 2003, 2007];
  const filteredTypes = [];
  typeOptions.forEach((item) => {
    if (!typeIDsToFilterOut.includes(item.FacilityTypeID)) {
      filteredTypes.push(item);
    }
  });
  return filteredTypes;
};
