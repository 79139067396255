import "./Styles.css";
import "../../../SharedComponents/Styles/SharedGenericStyles.css";
import "../../../SharedComponents/Styles/TextStyles.css";
import { pageNumberArray } from "./Utils";
import { useState, useEffect } from "react";
import { setPageList } from "./Utils";
import first from "../../../assets/button-icons/first.png";
import firstHover from "../../../assets/button-icons/firstHover.png";
import back from "../../../assets/button-icons/back.png";
import backHover from "../../../assets/button-icons/backHover.png";
import forwards from "../../../assets/button-icons/forwards.png";
import forwardsHover from "../../../assets/button-icons/forwardsHover.png";
import last from "../../../assets/button-icons/last.png";
import lastHover from "../../../assets/button-icons/lastHover.png";

export const NavigateToFirst = ({ currentPage, setDestinationPage }) => {
  return (
    <div>
      {currentPage > 1 ? (
        <img
          src={first}
          alt='Navigate to first page'
          onMouseOver={(e) => (e.currentTarget.src = firstHover)}
          onMouseOut={(e) => (e.currentTarget.src = first)}
          className='navigateToFirstPage'
          onClick={(e) => {
            setDestinationPage(1)((e.currentTarget.src = first));
          }}
        ></img>
      ) : (
        <img
          src={first}
          alt='Navigate to first page'
          className='navigateToFirstPageDisabled'
          disabled
        ></img>
      )}
    </div>
  );
};

export const NavigateToPrevious = ({ currentPage, setDestinationPage }) => {
  return (
    <div>
      {currentPage > 1 ? (
        <img
          src={back}
          alt='Navigate to first page'
          onMouseOver={(e) => (e.currentTarget.src = backHover)}
          onMouseOut={(e) => (e.currentTarget.src = back)}
          className='navigateBackwards'
          onClick={(e) => {
            setDestinationPage(currentPage - 1)((e.currentTarget.src = back));
          }}
        ></img>
      ) : (
        <img
          src={back}
          alt='Navigate to first page'
          className='navigateBackwardsDisabled'
          disabled
        ></img>
      )}
    </div>
  );
};

export const PageNumbers = ({
  allItems,
  itemsPerPage,
  currentPage,
  setLastPage,
  handleClick,
}) => {
  const [pageNumbers, setPageNumbers] = useState(null);
  const [activePage, setActivePage] = useState(null);

  useEffect(() => {
    setPageNumbers(pageNumberArray(allItems.length, itemsPerPage, setLastPage));
  }, [allItems, itemsPerPage]);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);

  return (
    <div
      className='react-shared-InLine'
      style={{ position: "relative", top: "-0.4rem" }}
    >
      {pageNumbers &&
        activePage &&
        pageNumbers.map((item, index) => (
          <a
            key={index}
            className={
              item !== activePage ? "clickable-underlined-text" : "current-page"
            }
            onClick={() => handleClick(item)}
            style={{ padding: "0rem 0.1rem" }}
          >
            {item}
          </a>
        ))}
    </div>
  );
};

export const NavigateToNext = ({
  currentPage,
  finalPageNumber,
  setDestinationPage,
}) => {
  return currentPage !== finalPageNumber ? (
    <img
      src={forwards}
      alt='Navigate to first page'
      onMouseOver={(e) => (e.currentTarget.src = forwardsHover)}
      onMouseOut={(e) => (e.currentTarget.src = forwards)}
      className='navigateForwards'
      onClick={(e) => {
        setDestinationPage(currentPage + 1)((e.currentTarget.src = forwards));
      }}
    ></img>
  ) : (
    <img
      src={forwards}
      alt='Navigate to first page'
      className='navigateForwardsDisabled'
      disabled
    ></img>
  );
};

export const NavigateToLast = ({
  currentPage,
  finalPageNumber,
  setDestinationPage,
}) => {
  return currentPage !== finalPageNumber ? (
    <img
      src={last}
      alt='Navigate to first page'
      onMouseOver={(e) => (e.currentTarget.src = lastHover)}
      onMouseOut={(e) => (e.currentTarget.src = last)}
      className='navigateToLastPage'
      onClick={(e) => {
        setDestinationPage(finalPageNumber)((e.currentTarget.src = last));
      }}
    ></img>
  ) : (
    <img
      src={last}
      alt='Navigate to first page'
      className='navigateToLastPageDisabled'
      disabled
    ></img>
  );
};

export const ListPageNavigationBar = ({
  allItems,
  itemsPerPage,
  setItemsToRender,
}) => {
  const [lastPage, setLastPage] = useState(null);
  const [renderedPage, setRenderedPage] = useState(1); //page numbers start from 1 and don't index at 0 as they're labelled numbers (perhaps easier to follow)
  const [destinationPage, setDestinationPage] = useState(1);

  useEffect(() => {
    setRenderedPage(destinationPage);
    setPageList(allItems, setItemsToRender, itemsPerPage, destinationPage);
  }, [allItems, setItemsToRender, itemsPerPage, destinationPage]);

  const handlePageClick = (pageNo) => {
    setDestinationPage(pageNo);
  };

  if (!renderedPage) {
    return <p>Loading...</p>;
  } else {
    return (
      <div className='react-shared-InLine'>
        <>
          <NavigateToFirst
            currentPage={renderedPage}
            setDestinationPage={setDestinationPage}
          />
          <NavigateToPrevious
            currentPage={renderedPage}
            setDestinationPage={setDestinationPage}
          />
        </>
        <div>
          <PageNumbers
            allItems={allItems}
            itemsPerPage={itemsPerPage}
            currentPage={renderedPage}
            setLastPage={setLastPage}
            handleClick={handlePageClick}
          />
        </div>

        {lastPage && (
          <>
            <NavigateToNext
              currentPage={renderedPage}
              finalPageNumber={lastPage}
              setDestinationPage={setDestinationPage}
            />
            <NavigateToLast
              currentPage={renderedPage}
              finalPageNumber={lastPage}
              setDestinationPage={setDestinationPage}
            />
          </>
        )}
      </div>
    );
  }
};
