import React from "react";
import ReactDOM from "react-dom";
import WebReferralConfigListAndEdit from "../../components/WebReferralConfigEditor/WebReferralConfigListAndEdit";
import addMainMenuItem from "./AddMainMenuItem";

const addWebReferralConfigEditor = (api, getURLSearchParam) =>
{

    const webReferralConfigEditorButtonHandler = () => {
        const contentDiv = document.getElementById('ApplicationContent');
        if (null != contentDiv) {
            ReactDOM.render(
                <React.StrictMode>
                    <WebReferralConfigListAndEdit
                        api={api}
                        getURLSearchParam={getURLSearchParam}
                    />
                </React.StrictMode>,
                contentDiv
            );
        }
    }

    addMainMenuItem(4, webReferralConfigEditorButtonHandler, "Web Referral Config Editor");
}

export default addWebReferralConfigEditor;