import { useState, useEffect } from "react";

//api calls
import { getClinicians, passwordReset, saveClinicians } from "./Transactions";

//page components
import FacilityLevelClinicians from "./SectionComponents/FacilityLevelClinicians";
import OrganisationLevelClinicians from "./SectionComponents/OrganisationLevelClinicians";

//functions
import { addItem, removeItemByIdentifierKey } from "../../../../SharedComponents/Utils/ArrayUtils";

//modal components
import Modal from "../../../../SharedComponents/ModalComponents/Modal";
import ClinicianDetails from "./SectionComponents/ClinicianDetails/ClinicianDetails";

//button components
import { Button } from "../../../../SharedComponents/ButtonComponents/Button";

//page management
import PageState from "../../../../SharedComponents/AppStates/PageState";
import { CallStates } from "../../../../SharedComponents/AppStates/Utils";

//styling
import { PartitionLine } from "../../../../SharedComponents/PageLayoutComponents/Partition";

const Clinicians = ({ facilityID, api }) => {
  const [facilityClinicians, setFacilityClinicians] = useState(null);
  const [organisationClinicians, setOrganisationClinicians] = useState(null);
  const [clinicianToEdit, setClinicianToEdit] = useState(null);

  const [callState, setCallState] = useState(CallStates.INITIAL);
  const [errorMessage, setErrorMessage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (callState === CallStates.INITIAL || callState === CallStates.SAVED) {
      getClinicians(facilityID, api, setCallState)
        .then((data) => {
          setFacilityClinicians(data.ExistingClinicianList);
          setOrganisationClinicians(data.AvailableClinicianList);
          setCallState(CallStates.LOADED);
        })
        .catch((e) => {
          setErrorMessage(e.message);
          setCallState(CallStates.ERROR);
        });
    }
  }, [callState]);

  const removeClinicianFromFacility = (event, clinician) => {
    event.preventDefault();
    removeItemByIdentifierKey(
      facilityClinicians,
      setFacilityClinicians,
      clinician,
      "ClinicianID"
    );
    addItem(organisationClinicians, setOrganisationClinicians, clinician);
    return;
  };

  const addClinicianToFacility = (event, clinician) => {
    event.preventDefault();
    removeItemByIdentifierKey(
      organisationClinicians,
      setOrganisationClinicians,
      clinician,
      "ClinicianID"
    );
    addItem(facilityClinicians, setFacilityClinicians, clinician);
  };

  const handleEditClinicianClick = (event, clinician) => {
    event.preventDefault();
    setClinicianToEdit(clinician);
    setModalIsOpen(true);
  };

  const cancelClinicianEdit = (event) => {
    event.preventDefault();
    setClinicianToEdit(null);
    setModalIsOpen(false);
  };

  const handleNewClinicianClick = (event) => {
    event.preventDefault();
    setClinicianToEdit(null);
    setModalIsOpen(true);
  };

  const handleSaveCliniciansClick = (event) => {
    event.preventDefault();
    saveClinicians(
      facilityID,
      facilityClinicians,
      organisationClinicians,
      setCallState,
      api
    )
      .then(() => {
        setCallState(CallStates.SAVED);
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setCallState(CallStates.ERROR);
      });
  };

  const handleResetPasswordClick = (event, username) => {
    event.preventDefault();
    passwordReset(api, username, setCallState)
      .then(() => {
        alert("Password reset!");
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setCallState(CallStates.ERROR);
      });
  };

  return (
    <PageState callState={callState} errorMessage={errorMessage}>
      <div className='fade-in' style={{ padding: "2rem" }}>
        <div className='' style={{ padding: "2rem 2rem 1rem 2rem" }}>
          <Button
            label='New clinician'
            handleClick={(e) => handleNewClinicianClick(e)}
          />
        </div>
        {facilityClinicians && (
          <div style={{ padding: "0rem 2rem 0rem 2rem" }}>
            <FacilityLevelClinicians
              clinicians={facilityClinicians}
              handleRemoveClick={removeClinicianFromFacility}
              handleEditClick={handleEditClinicianClick}
              handleResetPasswordClick={handleResetPasswordClick}
            />
          </div>
        )}
        <PartitionLine style={{ padding: "2rem 0rem" }} />
        {organisationClinicians && (
          <div style={{ padding: "0rem 2rem" }}>
            <OrganisationLevelClinicians
              clinicians={organisationClinicians}
              handleAddClick={addClinicianToFacility}
            />
          </div>
        )}
        <div className='padding2'>
          <Button
            label='Save clinicians'
            handleClick={(e) => handleSaveCliniciansClick(e)}
          />
        </div>

        <div>
          <Modal isOpen={modalIsOpen} onClose={(e) => cancelClinicianEdit(e)}>
            <div
              className='container-100'
              style={{
                padding: "2rem 1rem 0rem 3rem",
              }}
            >
              <ClinicianDetails
                clinicianToEdit={clinicianToEdit}
                facilityID={facilityID}
                api={api}
                setModalIsOpen={setModalIsOpen}
                setParentCallState={setCallState}
              />
            </div>
          </Modal>
        </div>
      </div>
    </PageState>
  );
};
export default Clinicians;
