import { CallStates } from "../../../../SharedComponents/AppStates/Utils";
import sanitizeHtml from "sanitize-html";
import { getSubjectTypes } from "./SectionComponents/EmailTemplateTransactions";
import { ErrorMessage } from "../../../../SharedComponents/Utils/ErrorMessages";

export const getCorrespondence = (facilityID, api, setCallState) => {
  setCallState(CallStates.LOADING);
  let correspondence = { correspondence: [], subjectTypes: [] };
  return api
    .transact("GetClinicCorrespondence", { FacilityID: facilityID })
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      correspondence.correspondence = response.apiResult;
      return getSubjectTypes(api, setCallState);
    })
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      correspondence.subjectTypes = response;
      return correspondence;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const sendCorrespondence = (
  api,
  email,
  facilityID,
  files,
  setCallState,
  setErrorMessage
) => {
  sanitizeHtml(email.Body);
  if (files.length > 0) {
    return (
      api
        .uploadFiles(files)
        .then((data) => {
          email.AttachmentList = data.apiResult;
          return api.transact("SendClinicCorrespondence", {
            Correspondence: email,
          })
          .then((response) => {
          setCallState(CallStates.SUCCESS);
          return response.apiResult;
          })
          .catch((e) => {
          e.message = ErrorMessage.SEND_FAILURE;
          return Promise.reject(e);
          });
        })
        .catch((e) => {
            if(e.message === ErrorMessage.SEND_FAILURE) {
                setErrorMessage(ErrorMessage.SEND_FAILURE)
            }
            else {
                e.message = ErrorMessage.FILE_UPLOAD_FAILURE + " " + ErrorMessage.SEND_FAILURE;
                setErrorMessage(ErrorMessage.FILE_UPLOAD_FAILURE + " " + ErrorMessage.SEND_FAILURE)
            }
          return Promise.reject(e);
        })
        
    );
  } else {
    return api
      .transact("SendClinicCorrespondence", {
        Correspondence: email,
      })
      .then((response) => {
        setCallState(CallStates.SUCCESS);
        return response.apiResult;
      })
      .catch((e) => {
        setErrorMessage(ErrorMessage.SEND_FAILURE);
        return Promise.reject(e);
      });
  }
};

export const saveCorrespondence = async (
  api,
  email,
  facilityID,
  files = null,
  setCallState,
  setErrorMessage
) => {
  sanitizeHtml(email.Body);
  if (files.length > 0) {
    return (
      api
        .uploadFiles(files)
        .then((data) => {
          email.AttachmentList = data.apiResult;
          return api.transact("SaveClinicCorrespondence", {
            Correspondence: email,
          })
          .then((response) => {
          setCallState(CallStates.SUCCESS);
          return response.apiResult;
          })
          .catch((e) => {
          e.message = ErrorMessage.SAVE_FAILURE;
          return Promise.reject(e);
          });
        })
        .catch((e) => {
            if(e.message === ErrorMessage.SAVE_FAILURE) {
                setErrorMessage(ErrorMessage.SAVE_FAILURE);
            }
            else {
                e.message = ErrorMessage.FILE_UPLOAD_FAILURE + " " + ErrorMessage.SAVE_FAILURE;
                setErrorMessage(ErrorMessage.FILE_UPLOAD_FAILURE + " " + ErrorMessage.SAVE_FAILURE);
            }
          return Promise.reject(e);
        })
        
    );
  } else {
    return api
      .transact("SaveClinicCorrespondence", {
        Correspondence: email,
      })
      .then((response) => {
        setCallState(CallStates.SUCCESS);
        return response.apiResult;
      })
      .catch((e) => {
        setErrorMessage(ErrorMessage.SAVE_FAILURE);
        return Promise.reject(e);
      });
  }
};

export const deleteCorrespondence = (api, email, setCallState) => {
  return api
    .transact("DeleteClinicCorrespondence", { Correspondence: email })
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
