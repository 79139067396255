import React, { useEffect, useState } from "react";
import { handleFileUpload } from "./Utils";
import "../../SharedComponents/Styles/SharedGenericStyles.css";

const FileUpload = ({ handleChange }) => {
  return (
    <div className='padding10'>
      <input type='file' onChange={handleChange} multiple />
    </div>
  );
};

export default FileUpload;
