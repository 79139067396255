import { useState, useEffect } from "react";

//api calls
import { saveFee } from "../Transactions";

//input components
import { SelectInputWithLabel } from "../../../../../SharedComponents/InputComponents/SelectInput";
import { handleInputChange } from "../../../../../SharedComponents/Utils/InputUtils";
import { CurrencyInputWithLabel } from "../../../../../SharedComponents/InputComponents/CurrencyInput";
import { DateInputWithLabel } from "../../../../../SharedComponents/InputComponents/DateInput";

//button components
import { Button } from "../../../../../SharedComponents/ButtonComponents/Button";

//page management
import { CallStates } from "../../../../../SharedComponents/AppStates/Utils";

const Fee = ({
  api,
  serviceChargeList,
  clientList,
  feeFromParent,
  setErrorMessage,
  setCallState,
  closeModal,
}) => {
  const [fee, setFee] = useState(feeFromParent);
  const [serviceCharges, setServiceCharges] = useState(serviceChargeList);
  const [clients, setClients] = useState(clientList);

  const handleSubmit = (event) => {
    event.preventDefault();
    saveFee(api, fee, setCallState)
      .then(() => {
        setCallState(CallStates.SAVED); //set parent call state so it reloads the tab
        closeModal();
      })
      .catch((e) => {
        setErrorMessage(e.message);
        setCallState(CallStates.ERROR);
      });
  };

  return (
    <form onSubmit={(event) => handleSubmit(event)} className='container-50'>
      {fee && (
        <>
          <SelectInputWithLabel
            inputLabel='ServiceCharge'
            name='ServiceChargeId'
            value={fee.ServiceChargeId}
            options={serviceCharges}
            labelProperty='ServiceChargeDescription'
            valueProperty='ServiceChargeID'
            handleChange={(e) => handleInputChange(e, fee, setFee)}
          />
          <SelectInputWithLabel
            inputLabel='Client'
            name='ClientPartyId'
            value={fee.ClientPartyId}
            options={clients}
            labelProperty='OrganisationName'
            valueProperty='PartyId'
            handleChange={(e) => handleInputChange(e, fee, setFee)}
          />
          <CurrencyInputWithLabel
            inputLabel='Amount'
            name='Amount'
            value={fee.Amount}
            handleChange={(e) => handleInputChange(e, fee, setFee)}
          />
          <DateInputWithLabel
            inputLabel='Date from'
            name='DateFrom'
            value={fee.DateFrom ?? ""}
            handleChange={(e) => handleInputChange(e, fee, setFee)}
          />
          <DateInputWithLabel
            inputLabel='Date to'
            name='DateTo'
            value={fee.DateTo ?? ""}
            handleChange={(e) => handleInputChange(e, fee, setFee)}
          />
          <div style={{ marginTop: "1rem" }}>
            <Button label='Save rate' handleClick={() => {}} type='submit' />
          </div>
        </>
      )}
    </form>
  );
};
export default Fee;
