//label components
import { DateLabel } from "../../../../../../SharedComponents/FormComponents/FormLabel";

//page management
import { NoTableDataToDisplay } from "../../../../../../SharedComponents/Tables/NoData";

//page components
import { Button } from "../../../../../../SharedComponents/ButtonComponents/Button";

const Closures = ({ closures, handleRemove }) => {
  const _handleRemove = (index) => {
    const customEvent = {
      target: {
        name: "ActionFlag",
        value: 4,
      },
    };

    handleRemove(customEvent, index);
  };

  const hasActiveClosures =
    closures.length > 0 && closures.some((item) => item.ActionFlag !== 4);

  const hasUnsavedRemovals =
    closures.length > 0 &&
    closures.some((item) => item.ActionFlag === 4 && item.Saved === undefined);

  return (
    <div className="react-shared-table">
      <table>
        <thead>
          <tr>
            <th>Closed From</th>
            <th>Closed To</th>
          </tr>
        </thead>
        <tbody className='omit-last-column-background-color-td omit-rounding-last-td-bottom-right'>
          {hasActiveClosures && (
            <>
              {closures.map((item, index) => (
                <tr key={index}>
                  {closures.length > 0 && item.ActionFlag !== 4 && (
                    <>
                      <td>
                        <DateLabel displayValue={item.DateFrom} />
                      </td>
                      <td>
                        <DateLabel displayValue={item.DateTo} />
                      </td>
                      <td style={{ display: "flex", padding: 0 }}>
                        <p style={{ marginLeft: "0.5rem" }}>
                          <Button
                            label='Remove'
                            handleClick={() => _handleRemove(index)}
                          />
                        </p>
                        <p
                          style={{
                            marginLeft: "0.5rem",
                            position: "relative",
                            top: "0.3rem",
                          }}
                        >
                          {item.Unsaved ? "Unsaved" : null}
                        </p>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </>
          )}
          {hasActiveClosures === false && (
            <NoTableDataToDisplay
              missingDataDescription='closure'
              numberOfColumnsToSpan={2}
            />
          )}
        </tbody>
      </table>
      {hasUnsavedRemovals && <b>Save to confirm removal of holiday date(s).</b>}
    </div>
  );
};
export default Closures;
