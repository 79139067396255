export const removeUserFile = async (api, partyId, guid, pathName) => {
    const params = {
        FullFilePath: pathName,
        GUID: guid,
        UserPartyID: partyId,
    };

    try {
        const response = await api.transact("RemoveUserFile", params);
        return { data: [response.apiResult], error: null };
    } catch (error){
        return { data: null, error };
    }
};
