import "../Styles/SharedGenericStyles.css"

const TextAreaInputWithLabel = ({
  inputLabel,
  value,
  name,
  charMax,
  handleChange,
  disabled = false,
}) => {
  if (!value) {
    value = "";
  }
  return (
    <div className='clinic-profile-InLine'>
      <label>
        {inputLabel} ({charMax - value.length}/{charMax}):
      </label>
      <textarea
        maxLength={charMax}
        cols={50}
        rows={5}
        value={value}
        name={name}
        onChange={(e) => handleChange(e)}
        className='top-aligned-textarea'
        disabled={disabled}
      />
    </div>
  );
};
export default TextAreaInputWithLabel;
