import { useEffect, useState } from "react";

//api calls
import { getModalities, SaveModalities } from "./Transactions";

//button components
import { Button } from "../../../../SharedComponents/ButtonComponents/Button";

//functions
import { isService } from "./Utils";
import { CheckboxInputWithLabel } from "../../../../SharedComponents/InputComponents/CheckboxInput";
import { handleInputChangeWithinArray } from "../../../../SharedComponents/Utils/InputUtils";

//page management
import { CallStates } from "../../../../SharedComponents/AppStates/Utils";
import PageState from "../../../../SharedComponents/AppStates/PageState";

//styling
import "../../../../SharedComponents/Styles/FadeStyles.css";

const Services = ({ facilityID, api }) => {
  const [services, setServices] = useState(null);

  const [callState, setCallState] = useState(CallStates.INITIAL);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (callState === CallStates.INITIAL || callState === CallStates.SAVED) {
      const modalityArray = [];
      getModalities(api, facilityID, setCallState)
        .then((data) => {
          data.Modalities.forEach((modality) => {
            if (isService(modality.ModalityID)) {
              modalityArray.push(modality);
            }
          });
          setServices(modalityArray);
          setCallState(CallStates.LOADED);
        })
        .catch((e) => {
          setErrorMessage(e.message);
          setCallState(CallStates.ERROR);
        });
    }
  }, [callState]);

  const handleSave = (event) => {
    event.preventDefault();
    SaveModalities(api, services, setCallState).catch((e) => {
      setErrorMessage(e.message);
      setCallState(CallStates.ERROR);
    });
  };

  return (
    <PageState callState={callState} errorMessage={errorMessage}>
      <div className='fade-in container-50' style={{ padding: "4rem" }}>
        {services &&
          services.map((item, index) => (
            <div key={item.ModalityID} style={{ marginBottom: "0.8rem" }}>
              <CheckboxInputWithLabel
                inputLabel={item.ModalityDescription}
                value={item.Available}
                name='Available'
                handleChange={(e) =>
                  handleInputChangeWithinArray(e, services, setServices, index)
                }
              />
            </div>
          ))}
        <div style={{ paddingTop: "2rem" }}>
          <Button
            handleClick={(event) => {
              handleSave(event);
            }}
            label='Save Services'
          />
        </div>
      </div>
    </PageState>
  );
};
export default Services;
