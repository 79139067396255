//label components
import {
  CurrencyLabel,
  DateLabel,
  FormLabel,
} from "../../../../../SharedComponents/FormComponents/FormLabel";

const ReadOnlyFee = ({ fee }) => {
  const {
    OrganisationName = "",
    ServiceChargeDescription = "",
    Amount = null,
    DateFrom = "",
    DateTo = "",
  } = fee;
  return (
    <>
      <td>
        <FormLabel displayValue={ServiceChargeDescription} />
      </td>
      <td>
        {OrganisationName && <FormLabel displayValue={OrganisationName} />}
      </td>
      <td>
        <CurrencyLabel displayValue={Amount} />
      </td>
      <td>
        <DateLabel displayValue={DateFrom ?? ""} />
      </td>
      <td>
        <DateLabel displayValue={DateTo ?? ""} />
      </td>
    </>
  );
};
export default ReadOnlyFee;
