//input components
import { CheckboxInputWithLabel } from "../../../../../../SharedComponents/InputComponents/CheckboxInput";
import { SelectInputWithLabel } from "../../../../../../SharedComponents/InputComponents/SelectInput";
import { TextInputWithLabel } from "../../../../../../SharedComponents/InputComponents/TextInput";

//static data
import { modalityOptions } from "./Utils";

const Clinician = ({ clinician, handleChange }) => {
  const {
    AbleToTreatMinors = false,
    ModalityID = "",
    QualificationYear = "",
    FullTime = false,
    InsuranceDocumentChecked = false,
  } = clinician;

  return (
    <div>
      <CheckboxInputWithLabel
        inputLabel='Able to treat minors?'
        name='AbleToTreatMinors'
        value={AbleToTreatMinors ?? false}
        handleChange={handleChange}
      />
      <SelectInputWithLabel
        inputLabel='Modality'
        name='ModalityID'
        value={ModalityID ?? ""}
        options={modalityOptions}
        labelProperty='name'
        valueProperty='value'
        handleChange={handleChange}
        required={true}
      />
      <TextInputWithLabel
        inputLabel='Qualification year'
        name='QualificationYear'
        value={QualificationYear ?? ""}
        handleChange={handleChange}
      />
      <CheckboxInputWithLabel
        inputLabel='Full time'
        name='FullTime'
        value={FullTime ?? false}
        handleChange={handleChange}
      />
      <CheckboxInputWithLabel
        inputLabel='Insurance document checked'
        name='InsuranceDocumentChecked'
        value={InsuranceDocumentChecked ?? false}
        handleChange={handleChange}
      />
    </div>
  );
};
export default Clinician;
