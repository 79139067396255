import React, { useEffect, useState } from "react";
import ClientSettingsList from "./ClientSettingsList";
import ClientListDropdown from "./ClientListDropdown";

const ClientSettingEditor = ({ api }) => {
    const [clientID, setClientID] = useState(0);
    const [error, setError] = useState("");

    const [settings, setSettings] = useState([]);

    useEffect(() => {
        setError("");
        const params = { IncludeDetail: true, ClientID: clientID };
        api.transact("GetBrandingInfo", params)
            .then(r => r.apiResult)
            .then(settingResult => setSettings(settingResult))
            .catch(e => {
                if (e.apiStatus === "ERROR") {
                    setError(e.message);
                }
            });
    }, [api, clientID, setError]);

    const [saveRequestError, setSaveRequestError] = useState("");

    return (
        <div>
            <h1>Client Settings Editor</h1>
            <div className="ClientSettingEditorDiv">
                <ClientListDropdown
                    api={api}
                    clientID={clientID}
                    setClientID={setClientID}
                />
                {error !== "" ? <p>{error}</p> : <></>}
            </div>
            <hr />
            <div className="saveRequestErrorClientSettingsListDiv">
                {saveRequestError}
            </div>
            {settings?.length !== 0 ? (
                <ClientSettingsList
                    api={api}
                    settings={settings}
                    setSettings={setSettings}
                    clientID={clientID}
                />
            ) : (
                <></>
            )}
        </div>
    );
};

export default ClientSettingEditor;
