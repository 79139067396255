import React from "react";

const DuplicateFileUploadRow = () => {
    return (
        <>
            <div style={{ paddingLeft: "20px" }}>
                <div className="iprsPortalFileTreeDirectoryIconAndNameDiv">
                    <div className="iprsPortalFileTreeDirectoryIconDiv">
                        <div className="iprsPortalFileTreeDirectioryIconsAndName">
                            <div className="iprsPortalFileTreeNameForDirectoryAndFile">
                                <label className="iprsPortalFileTreeAddNewDirectoryRow">
                                    <p>
                                        A file with same name is in this
                                        directory. Delete the file and try
                                        upload again.
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DuplicateFileUploadRow;