import { useEffect, useState } from "react";

//api calls
import { getContacts, saveContacts } from "./Transactions";

//static data
import { contactModel } from "./Utils";

//page components
import ContactDetails from "./SectionComponents/ContactDetails";
import PersonDetails from "./SectionComponents/PersonDetails";

//input components
import { CheckboxInputWithLabel } from "../../../../SharedComponents/InputComponents/CheckboxInput";

//page management
import PageState from "../../../../SharedComponents/AppStates/PageState";
import { CallStates } from "../../../../SharedComponents/AppStates/Utils";

//styling
import "../../../../SharedComponents/Styles/SharedGenericStyles.css";
import { PartitionLine } from "../../../../SharedComponents/PageLayoutComponents/Partition";

//button components
import { Button } from "../../../../SharedComponents/ButtonComponents/Button";

const Contacts = ({ facilityID, api }) => {
  const [director, setDirector] = useState(null);
  const [practiceManager, setPracticeManager] = useState(null);
  const [clinical, setClinical] = useState(null);
  const [financial, setFinancial] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [directorIsPracticeManager, setDirectorIsPracticeManager] =
    useState(null);

  const [callState, setCallState] = useState(CallStates.INITIAL);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (callState === CallStates.INITIAL || callState === CallStates.SAVED) {
      getContacts(api, facilityID, setCallState)
        .then((data) => {
          data.Director
            ? setDirector(data.Director)
            : setDirector(contactModel());
          data.PracticeManager
            ? setPracticeManager(data.PracticeManager)
            : setPracticeManager(contactModel());
          data.Clinician
            ? setClinical(data.Clinician)
            : setClinical(contactModel());
          data.Finance
            ? setFinancial(data.Finance)
            : setFinancial(contactModel());
          data.Feedback
            ? setFeedback(data.Feedback)
            : setFeedback(contactModel());
          setDirectorIsPracticeManager(data.DirectorIsPracticeManager ?? false);
          setCallState(CallStates.LOADED);
        })
        .catch((e) => {
          setErrorMessage(e.message);
          setCallState(CallStates.ERROR);
        });
    }
  }, [callState]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const contacts = {
      FacilityID: facilityID,
      Director: director,
      PracticeManager: practiceManager,
      Clinician: clinical,
      Finance: financial,
      Feedback: feedback,
      DirectorIsPracticeManager: directorIsPracticeManager,
    };

    setCallState(CallStates.SAVING);
    saveContacts(api, contacts, setCallState).catch((e) => {
      setErrorMessage(e.message);
      setCallState(CallStates.ERROR);
    });
  };

  return (
    <PageState callState={callState} errorMessage={errorMessage}>
      <form
        onSubmit={(event) => handleSubmit(event)}
        className='fade-in'
        style={{ padding: "2rem" }}
      >
        <div className='padding2 react-shared-headers'>
          {director && (
            <div className='container-50'>
              <h3>Director</h3>
              <PersonDetails
                person={director.Person}
                handlePersonChange={(person) =>
                  setDirector({
                    ...director,
                    Person: person,
                  })
                }
              />
              <ContactDetails
                contactDetails={director.ContactDetails}
                handleContactDetailsChange={(details) =>
                  setDirector({
                    ...director,
                    ContactDetails: details,
                  })
                }
              />
            </div>
          )}
          {directorIsPracticeManager !== undefined && (
            <div className='container-50'>
              <CheckboxInputWithLabel
                inputLabel='Director is Practice Manager'
                value={directorIsPracticeManager}
                name='DirectorIsPracticeManager'
                handleChange={(e) =>
                  setDirectorIsPracticeManager(e.target.checked)
                }
              />
            </div>
          )}
          <PartitionLine style={{ padding: "2rem 0rem" }} />
          {practiceManager && !directorIsPracticeManager && (
            <>
              <div className='fade-in container-50'>
                <h3>Practice Manager</h3>
                <PersonDetails
                  person={practiceManager.Person}
                  handlePersonChange={(person) =>
                    setPracticeManager({
                      ...practiceManager,
                      Person: person,
                    })
                  }
                />
                <ContactDetails
                  contactDetails={practiceManager.ContactDetails}
                  handleContactDetailsChange={(details) =>
                    setPracticeManager({
                      ...practiceManager,
                      ContactDetails: details,
                    })
                  }
                />
              </div>
              <PartitionLine style={{ padding: "2rem 0rem" }} />
            </>
          )}
          {clinical && (
            <div className='container-50'>
              <h3>Clinical</h3>
              <PersonDetails
                person={clinical.Person}
                handlePersonChange={(person) =>
                  setClinical({
                    ...clinical,
                    Person: person,
                  })
                }
              />
              <ContactDetails
                contactDetails={clinical.ContactDetails}
                handleContactDetailsChange={(details) =>
                  setClinical({
                    ...clinical,
                    ContactDetails: details,
                  })
                }
              />
            </div>
          )}
          <PartitionLine style={{ padding: "2rem 0rem" }} />
          {financial && (
            <div className='container-50'>
              <h3>Financial</h3>
              <PersonDetails
                person={financial.Person}
                handlePersonChange={(person) =>
                  setFinancial({
                    ...financial,
                    Person: person,
                  })
                }
              />
              <ContactDetails
                contactDetails={financial.ContactDetails}
                handleContactDetailsChange={(details) =>
                  setFinancial({
                    ...financial,
                    ContactDetails: details,
                  })
                }
              />
            </div>
          )}
          <PartitionLine style={{ padding: "2rem 0rem" }} />
          {feedback && (
            <div className='container-50'>
              <h3>Feedback</h3>
              <PersonDetails
                person={feedback.Person}
                handlePersonChange={(person) =>
                  setFeedback({
                    ...feedback,
                    Person: person,
                  })
                }
              />
              <ContactDetails
                contactDetails={feedback.ContactDetails}
                handleContactDetailsChange={(details) =>
                  setFeedback({
                    ...feedback,
                    ContactDetails: details,
                  })
                }
              />
            </div>
          )}
          <div className='padding2'>
            <Button
              label='Save contacts'
              handleClick={() => {}}
              type='submit'
            />
          </div>
        </div>
      </form>
    </PageState>
  );
};
export default Contacts;
