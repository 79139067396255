import { CallStates } from "../../../../SharedComponents/AppStates/Utils";

export const getPatients = (facilityID, setCallState, api) => {
  return api
    .transact("FacilityPatientList", { FacilityID: facilityID })
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
