import React, { useEffect, useState } from 'react'

const ReferralMessage = ({        
        caseID,
        checkIcon,
        assessmentValue,
        setAssessmentValue,
        treatmentValue,
        setTreatmentValue,
        referralMessageStatus,
        saveStatus,
        makeReferralResult,
        notesToAPHandler,
        noteLength,
        selectedClinic,
        notesToAP,
        messageMaxCharLength,
        makeReferral,
        setLoaderMessage,
        setReferralMessageOverlay,
        ReferralMessageOverlay,
        setJustification,
        justification,
        availableCaseDocuments,
        setCaseDocumentsToSend
    }) => {
        const [availableDocuments, setAvailableDocuments] = useState(availableCaseDocuments);
        const [selectedDocuments, setSelectedDocuments] = useState([]);

        useEffect(() => {
            setCaseDocumentsToSend(selectedDocuments);
        }, [selectedDocuments])

        //make sure they're in the same order when users remove documents they've added
        useEffect(() => {
            setAvailableDocuments(previousAvailableDocuments => previousAvailableDocuments.sort((a, b) => a.DocumentUploadID - b.DocumentUploadID));
        }, [availableDocuments]);

        // Function for "back to patient board" button
        // It will redirect the browser with URL 'Presentation/Page/PMS/CaseInVoicePage.aspx?CaseID=XXXXXX'
        const backToPatientBoard = (e) => {
            e.preventDefault();
            console.log('going back to /Presentation/Page/PMS/CaseInVoicePage.aspx?CaseID=' + caseID);
            window.location.replace("/Presentation/Page/PMS/CaseInVoicePage.aspx?CaseID=" + caseID);
        }

        const referralMessageOverlayCloseHandler = (e) => {
            e.preventDefault();
            setReferralMessageOverlay(false);
            setJustification('')
        }

        const makeReferralMessageModalCloseButtonHandler = () => {
            return (
                <div className="referralMessageBoardFormCloseButton">
                    <button 
                        onClick={(e)=>referralMessageOverlayCloseHandler(e)}
                        className="referralMessageBoardCloseButton"
                    >
                        <span style={{fontWeight: "600", fontSize: "18px"}}>X</span>
                    </button>
                </div>
            )
        }

        const makeReferralButtonHandler = () => {
            return (
                <div className="onwardReferralSave">
                    <button 
                        onClick={(e)=>makeReferral(e)}
                        className="onwardReferralSaveButton"
                        disabled={selectedClinic.ClosestIndex === false && justification === '' ? true : false}
                    >
                            Save
                    </button>
                </div>
            )            
        }

        const handleSelectDocument = (documentUploadID) => {
            const selectedOption = availableCaseDocuments.find(option => option.DocumentUploadID === documentUploadID);
            if (selectedOption) {
                setAvailableDocuments(prevOptions => prevOptions.filter(opt => opt.DocumentUploadID !== documentUploadID));
                setSelectedDocuments(prevSelected => [...prevSelected, selectedOption]);
            }
        };

        const handleRemoveDocument = (documentUploadID) => {
            const removedOption = selectedDocuments.find(option => option.DocumentUploadID === documentUploadID);
            if (removedOption) {
                setSelectedDocuments(prevSelected => prevSelected.filter(opt => opt.DocumentUploadID !== documentUploadID));
                setAvailableDocuments(prevOptions => [...prevOptions, removedOption]);
            }
        };
    
        return (
            <>
                {ReferralMessageOverlay !== false ?
                <>
                    <div className="makeReferralMessage">
                        <div className ="referralMessageBoard">
                            {/* When clinic is selected, selectedClinic state will not be null
                                and it should have a facilityID save in the state which is needed
                                to use makeReferral API endpoint. */}
                            {selectedClinic !== null && (saveStatus === null || saveStatus !== true) ? 
                                (
                                    <div className="referralMessageBoardForm">
                                        <>
                                            {makeReferralMessageModalCloseButtonHandler()}
                                            <label className="clinicName">
                                                {selectedClinic !== null && selectedClinic !== undefined ? selectedClinic.FacilityName : <></>}
                                            </label>
                                            <label className="notesToAP">
                                                <span>
                                                    NotesToAP 
                                                </span>

                                                <textarea 
                                                    className="makeReferralNoteTextArea"
                                                    value={notesToAP}
                                                    onChange={notesToAPHandler}
                                                    maxLength={messageMaxCharLength}
                                                    rows={10}
                                                    cols={50}
                                                />
                                            </label>

                                            <p className="notesCharacterRemaining ">
                                                Characters remaining: {noteLength + '/' + messageMaxCharLength}
                                            </p>

                                                <label  className="makeReferralMessageInputFields">
                                                    <span>
                                                        Available case documents:
                                                    </span>
                                                    <select style={{ width: '200px', overflowWrap: 'break-word' }} onChange={(event) => handleSelectDocument(parseInt(event.target.value))}>
                                                        <option value="">Select a document</option>
                                                        {availableDocuments.length > 0 && availableDocuments.map((option) => (
                                                            <option key={option.DocumentUploadID} value={option.DocumentUploadID}>
                                                                {option.Name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </label>

                                                {selectedDocuments.length > 0 && <label  className="makeReferralMessageInputFields">
                                                    {/* having this as a paragraph element makes it look nice in pharos but kinda bad in local */}
                                                    <p>
                                                        Selected case documents:
                                                    </p>
                                                    <ul>
                                                        {selectedDocuments.length > 0 && selectedDocuments.map(option => (
                                                        <li key={option.DocumentUploadID}  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                                                            <div style={{paddingTop: '0.2rem'}}>
                                                                {option.Name}
                                                            </div>
                                                                
                                                            <div style={{paddingLeft: '0.3rem'}}>
                                                                <button onClick={(e) => e.preventDefault()(handleRemoveDocument(option.DocumentUploadID))}>Remove</button>
                                                            </div>
                                                            
                                                        </li>
                                                        ))}
                                                    </ul>
                                                </label>}

                                            <label className="makeReferralMessageInputFields">
                                                <span>
                                                    Assessment 
                                                </span>
                                                <select
                                                    defaultValue={assessmentValue} 
                                                    onChange={(e)=>setAssessmentValue(e.target.value)}
                                                >
                                                    <option value={0}>0</option>
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                    <option value={7}>7</option>
                                                    <option value={8}>8</option>
                                                    <option value={9}>9</option>
                                                    <option value={10}>10</option>
                                                </select>
                                            </label>

                                            <label className="makeReferralMessageInputFields">
                                                <span>
                                                    Treatment  
                                                </span>
                                                <select 
                                                    defaultValue={treatmentValue}
                                                    onChange={(e)=>setTreatmentValue(e.target.value)}
                                                >
                                                    <option value={0}>0</option>
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                    <option value={7}>7</option>
                                                    <option value={8}>8</option>
                                                    <option value={9}>9</option>
                                                    <option value={10}>10</option>
                                                </select>
                                            </label>

                                            {selectedClinic.ClosestIndex === false ? (

                                                <label className="makeReferralMessageInputFields justificationDropDownField">
                                                    <span>
                                                        Justification <span style={{color: 'red'}}>(Required)</span>
                                                    </span>
                                                    {/* WARNING: These values need to be aligned with specific values in the backend
                                                    as the backend looks up the IDs */}
                                                    <select onChange={(e)=>setJustification(e.target.value)}>
                                                        <option value={''}></option>
                                                        <option value={'Patient Request - Distance'}>Patient Request - Distance</option>
                                                        <option value={'Patient Request - Clinic hours'}>Patient Request - Clinic hours</option>
                                                        <option value={'Patient Request - Personal recommendation'}>Patient Request - Personal recommendation</option>
                                                        <option value={'Client Request'}>Client Request</option>
                                                        <option value={'No PPS Available'}>No PPS Available</option>
                                                        <option value={'No OPM Available'}>No OPM Available</option>
                                                        <option value={'Clinical Reason'}>Clinical Reason</option>
                                                    </select>
                                                </label>

                                            ) : <></>}

                                            <hr />
                                            {makeReferralButtonHandler()}
                                            {
                                                makeReferralResult !== null ?
                                                <>
                                                    <p className="makeReferralResultErrorMessage">
                                                        {makeReferralResult} - {referralMessageStatus !== null ? referralMessageStatus : <></>} 
                                                    </p>
                                                </> 
                                                :
                                                <></>
                                            }
                                        </>
                                    </div>
                                ) : (
                                    <div className='makeReferralStatusDiv'>
                                        <img src={checkIcon} className="SuccessMessageIcon" alt="Success Icon"/>
                                        <p className="SuccessMessageText">
                                            Make Referral Successful!   
                                        </p>
                                        <button 
                                            className="onwardReferralSaveButton"
                                            onClick={(e)=>backToPatientBoard(e)}
                                        >
                                            Go to service charge page
                                        </button>
                                    </div>
                                )
                            }
                            {
                                saveStatus === 'ERROR' ? (<>{setLoaderMessage}</>) : (<></>)
                            }
                        </div>
                    </div>
                </>
                :
                <></>
                }
            </>
        )
}

export default ReferralMessage