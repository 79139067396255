import "./Style.css";
import { useRef, useEffect } from "react";
import { small, fee, large, email } from "./Utils";

const Modal = ({
  isOpen,
  onClose,
  handleSave = null,
  saveButtonLabel = null,
  size = email,
  children,
}) => {
  const modalRef = useRef(null); //create the ref when component mounts to be adjusted later

  useEffect(() => {
    if (isOpen) {
      adjustModalSize();
      window.addEventListener("resize", adjustModalSize);
    }

    return () => {
      window.removeEventListener("resize", adjustModalSize);
    };
  }, [isOpen]);

  const adjustModalSize = () => {
    if (modalRef.current) {
      //set modal size based on param passed in, modal params can be found in ./Utils.js
      modalRef.current.style.height = size.height;
      modalRef.current.style.width = size.width;
    }
  };

  if (!isOpen) return null;

  return (
    <div className='clinic-profile-modal-overlay'>
      <div className='clinic-profile-modal' ref={modalRef}>
        {/* modalRef is assigned as a ref to this div */}
        <button className='clinic-profile-modal-close' onClick={onClose}>
          &times;
        </button>
        <div className='clinic-profile-modal-content'>
          {children}
          {handleSave && (
            <button onClick={handleSave}>{saveButtonLabel ?? "Save"}</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
