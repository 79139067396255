import { useEffect, useState } from "react";

//input components
import { CheckboxInputWithLabel } from "../../../../../../SharedComponents/InputComponents/CheckboxInput";

//functions
import { handleInputChangeWithinArray } from "../../../../../../SharedComponents/Utils/InputUtils";

//the roles are sent to the back end as an array
const Roles = ({
  parentRoles = null,
  setParentRoles,
  availableOptions,
  disabled,
}) => {
  const [displayedRoles, setDisplayedRoles] = useState(availableOptions); //this happens before the empty dependency arary useEffect

  useEffect(() => {
    if (parentRoles) {
      parentRoles.forEach((role) => {
        if (
          displayedRoles.some((item) =>
            Object.values(item).includes(role.GroupID)
          )
        ) {
          setDisplayedRoles((prevDisplayedRoles) => {
            // Map over the current displayedRoles array and update the specific object
            const updatedRoles = prevDisplayedRoles.map((item) => {
              if (Object.values(item).includes(role.GroupID)) {
                // Modify the object with the matching UserGroupID
                return { ...item, selected: true }; // Update propertyName with the new value
              }
              return item; // Keep other objects unchanged
            });
            return updatedRoles; // Set the updated array as the new state
          });
        }
      });
    }
  }, [parentRoles]);

  const handleChange = (event, item, index) => {
    const isChecked = event.target.checked;
    handleInputChangeWithinArray(
      event,
      displayedRoles,
      setDisplayedRoles,
      index
    );
    setParentRoles((prevRoles) => {
      if (
        isChecked &&
        !prevRoles.some((role) => role.GroupID === item.GroupID)
      ) {
        // If the element is selected and not in parentRoles, add it
        return [...prevRoles, item];
      } else if (!isChecked) {
        // If the element is not selected, remove it
        return prevRoles.filter((role) => role.GroupID !== item.GroupID);
      }

      // No change needed if already in parentRoles and checked
      return prevRoles;
    });
  };

  return (
    <div>
      {displayedRoles &&
        displayedRoles.map((item, index) => (
          <div key={item.GroupID}>
            <CheckboxInputWithLabel
              inputLabel={item.name}
              name='selected'
              value={item.selected}
              handleChange={(e) => handleChange(e, item, index)}
              disabled={disabled}
            />
          </div>
        ))}
    </div>
  );
};
export default Roles;
