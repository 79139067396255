import React, { useState, useEffect } from "react";
import FileTreeDownloadButton from "./FileTreeDownloadButton.jsx";
import FileTreeDeleteButton from "./FileTreeDeleteButton.jsx";

const FileRow = React.memo(
    ({
        file,
        api,
        fetchUpdatedFileTree,
        keyName,
        partyId,
        currentUrl,
        keyIndexCount,
    }) => {
        const [deleted, setDeleted] = useState(false);
        const [fileKey, setFileKey] = useState("");

        useEffect(() => {
            setFileKey(keyIndexCount + keyName + file.FullFilePath);
        }, []);

        const handleDelete = () => {
            setDeleted(true);
        };

        return (
            <div
                className={`iprsPortalFileTreeDirectoryItem ${
                    deleted ? "deleted" : ""
                }`}
                key={file.FullFilePath + fileKey}
            >
                <div className="iprsPortalFileTreeDirectoryFileDiv">
                    <svg width="20" height="20">
                        <use href="#icon-file" />
                    </svg>
                    <span className="iprsPortalFileTreeNameForDirectoryAndFile">
                        <FileTreeDownloadButton
                            api={api}
                            guid={file.GUID}
                            fileName={file.FullFilePath.split("/").pop()}
                        />
                    </span>
                </div>
                <div className="iprsPortalFileTreeDictionaryItem">
                    {currentUrl ? (
                        <FileTreeDeleteButton
                            api={api}
                            guid={file.GUID}
                            pathName={file.FullFilePath}
                            partyId={partyId}
                            fetchUpdatedFileTree={() => {
                                fetchUpdatedFileTree(file.FullFilePath);
                                handleDelete(file.FullFilePath);
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        );
    },
    (prevProps, nextProps) => {
        return prevProps.file.GUID === nextProps.file.GUID; // GUID assumed to be unique and stable
    }
);

export default FileRow;
