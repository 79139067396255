import React from "react";

const IprsPortalSvg = () => {
    return (
        <div>
            <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <symbol id="icon-message" viewBox="0 0 48 32">
                        <title>message</title>
                        <path
                            fill="#9c9c9c"
                            style={{ fill: "var(--color1, #9c9c9c)" }}
                            d="M26.102 18.646c-0.52 0.366-1.167 0.585-1.864 0.585s-1.344-0.219-1.875-0.592l-22.363-14.909v24.082c0 2.486 2.020 4.35 4.35 4.35h39.62c2.486 0 4.35-1.864 4.35-4.35v-24.082l-22.218 15.226z"
                        ></path>
                        <path
                            fill="#9c9c9c"
                            style={{ fill: "var(--color1, #9c9c9c)" }}
                            d="M23.616 16.937h1.088l22.684-15.382c-0.932-0.932-2.020-1.554-3.418-1.554h-39.62c-1.243 0-2.486 0.621-3.263 1.554l22.529 15.537z"
                        ></path>
                    </symbol>
                    <symbol id="icon-notification" viewBox="0 0 28 32">
                        <title>notification</title>
                        <path
                            fill="#9c9c9c"
                            style={{ fill: "var(--color1, #9c9c9c)" }}
                            d="M14.261 32c2.48 0 4.491-2.011 4.491-4.491v0h-8.982c0 2.48 2.011 4.491 4.491 4.491v0zM28.072 24.477l-3.256-3.593c-0.449-0.561-0.786-1.347-0.786-2.021v-5.165c0-4.828-3.368-8.982-7.972-9.768v-2.246c0-0.992-0.804-1.796-1.796-1.796s-1.796 0.804-1.796 1.796v0 2.246c-4.604 0.786-8.084 4.716-8.084 9.432v5.502c0 0.674-0.337 1.46-0.786 2.021l-3.368 3.593c-0.561 0.786 0 2.021 1.011 2.021h25.937c1.123 0 1.684-1.235 0.898-2.021z"
                        ></path>
                    </symbol>
                    <symbol id="icon-person" viewBox="0 0 32 32">
                        <title>person</title>
                        <path
                            fill="#9c9c9c"
                            style={{ fill: "var(--color1, #9c9c9c)" }}
                            d="M16 32c8.837 0 16-7.163 16-16s-7.163-16-16-16v0c-8.837 0-16 7.163-16 16s7.163 16 16 16v0z"
                        ></path>
                        <path
                            fill="#646463"
                            style={{ fill: "var(--color2, #646463)" }}
                            d="M16 22.24c-5.040 0-9.36 2-10.8 5.52 2.836 2.601 6.632 4.195 10.8 4.195s7.964-1.594 10.812-4.206l-0.012 0.011c-1.44-3.52-5.76-5.52-10.8-5.52zM9.040 13.76c0 3.844 3.116 6.96 6.96 6.96s6.96-3.116 6.96-6.96v0c0-3.844-3.116-6.96-6.96-6.96s-6.96 3.116-6.96 6.96v0z"
                        ></path>
                    </symbol>
                    <symbol id="icon-question" viewBox="0 0 32 32">
                        <title>question</title>
                        <path
                            fill="#fff"
                            style={{ fill: "var(--color3, #fff)" }}
                            d="M16.007 32.014c-8.54-0.389-15.316-7.406-15.316-16.007s6.776-15.618 15.281-16.006l0.035-0.001c8.54 0.389 15.316 7.406 15.316 16.007s-6.776 15.618-15.281 16.006l-0.035 0.001zM16.007 3.117c-6.849 0.347-12.271 5.985-12.271 12.89s5.422 12.542 12.24 12.888l0.031 0.001c6.849-0.347 12.271-5.985 12.271-12.89s-5.422-12.542-12.24-12.888l-0.031-0.001z"
                        ></path>
                        <path
                            fill="#fff"
                            style={{ fill: "var(--color3, #fff)" }}
                            d="M18.672 15.228l-0.842 1.091c-0.842 1.091-1.044 1.309-1.044 1.699v0.53c0 0.218-0.249 0.343-0.468 0.343h-1.87c-0.187 0-0.343-0.156-0.343-0.358v-0.514c0-0.966 0.078-1.527 0.779-2.463l0.904-1.185c0.686-0.92 0.857-1.34 0.857-1.87v-0.203c0-0.405-0.265-0.686-0.92-0.686-0.966 0-1.777 0.094-2.665 0.203h-0.062c-0.218 0-0.312-0.156-0.312-0.39v-1.668c0-0.265 0.062-0.421 0.343-0.483 0.879-0.248 1.889-0.39 2.932-0.39 0.021 0 0.043 0 0.064 0h-0.003c2.572 0 4.084 0.764 4.084 3.102v0.312c0 0.842-0.39 1.59-1.434 2.93zM16.568 23.145h-2.198c-0.203 0-0.374-0.156-0.374-0.358v-2.369c0-0.218 0.156-0.374 0.374-0.374h2.198c0.218 0 0.374 0.156 0.374 0.374v2.338c0 0.218-0.156 0.374-0.374 0.374z"
                        ></path>
                    </symbol>
                    <symbol id="icon-assign-clinician" viewBox="0 0 32 32">
                        <title>assign-clinician</title>
                        <path
                            fill="#9d9d9c"
                            style={{ fill: "var(--color1, #9d9d9c)" }}
                            d="M10.56 6.56h0.16c3.84 0 7.787-2.56 7.787-2.56l0.213-0.16 0.107 0.213s0.853 2.080 2.507 2.56h0.32v0.853c0 4.96-2.507 8.907-5.653 8.907-3.2 0-5.653-3.947-5.653-8.907v-0.907h0.213zM16 17.493c3.893 0 6.773-4.267 6.773-10.027v-2.027c0-1.973-1.493-3.84-3.2-3.947-0.64-1.333-2.293-1.493-3.573-1.493-3.573 0-6.773 2.613-6.773 5.44v2.027c0 5.707 2.88 10.027 6.773 10.027zM18.453 30.453l2.613-12.053v-0.267l0.267 0.107c3.040 1.173 6.4 2.507 7.093 2.88 1.075 2.299 1.846 4.967 2.175 7.771l0.012 0.123v0.213h-0.107c-3.517 0.817-7.589 1.337-11.765 1.439l-0.075 0.001h-0.267l0.053-0.213zM1.387 29.013c0.213-2.293 1.44-6.933 2.133-7.84 0.747-0.373 4.107-1.76 7.147-2.933l0.213-0.053 0.053 0.213 2.613 12v0.213h-0.213c-5.6-0.16-10.187-0.96-11.84-1.44h-0.16v-0.213zM29.28 20.107c-0.8-0.587-8.587-3.52-8.907-3.627l-0.267-0.107v0.267l-2.613 11.947v-5.333l-0.96-1.973 1.013-1.227 0.107-0.107-1.6-1.6-1.6 1.6 0.107 0.107 1.013 1.227-1.013 1.867v5.493l-2.613-12v-0.267l-0.267 0.107c-0.32 0.107-8.107 3.040-8.907 3.627-1.227 0.907-2.773 7.68-2.773 9.76 0 1.067 9.387 2.133 16 2.133s16-1.12 16-2.133c0-2.080-1.547-8.853-2.667-9.76z"
                        />
                        <path
                            fill="#8db248"
                            style={{ fill: "var(--color2, #8db248)" }}
                            d="M23.84 21.707h1.813v6.4h-1.813z"
                        />
                        <path
                            fill="#8db248"
                            style={{ fill: "var(--color2, #8db248)" }}
                            d="M21.6 23.947h6.4v1.813h-6.4z"
                        />
                    </symbol>
                    <symbol id="icon-calendar" viewBox="0 0 34 32">
                        <title>calendar</title>
                        <path
                            fill="#9c9c9c"
                            style={{ fill: "var(--color3, #9c9c9c)" }}
                            d="M3.973 23.696h4.885v4.885h-4.885zM10.875 23.696h4.885v4.885h-4.885zM17.776 23.696h4.885v4.885h-4.885zM24.677 23.696h4.885v4.885h-4.885zM3.973 17.221h4.885v4.885h-4.885zM10.875 17.221h4.885v4.885h-4.885z"
                        />
                        <path
                            fill="#8db145"
                            style={{ fill: "var(--color4, #8db145)" }}
                            d="M21.739 21.184h-3.040v-3.040h3.040v3.040zM17.776 22.107h4.885v-4.885h-4.885v4.885z"
                        />
                        <path
                            fill="#9c9c9c"
                            style={{ fill: "var(--color3, #9c9c9c)" }}
                            d="M24.677 17.221h4.885v4.885h-4.885zM10.875 10.747h4.885v4.885h-4.885zM17.776 10.747h4.885v4.885h-4.885zM24.677 10.747h4.885v4.885h-4.885zM10.907 5.040c0 0.725-0.587 1.312-1.312 1.312s-1.312-0.587-1.312-1.312v0-3.728c0-0.725 0.587-1.312 1.312-1.312s1.312 0.587 1.312 1.312v0 3.728z"
                        />
                        <path
                            fill="#9c9c9c"
                            style={{ fill: "var(--color3, #9c9c9c)" }}
                            d="M30.965 30.048h-28.336v-21.104h28.336v21.104zM31.819 3.419h-5.76v1.621c0 1.131-0.917 2.048-2.048 2.048s-2.048-0.917-2.048-2.048v0-1.627h-10.325v1.621c-0.057 1.085-0.951 1.944-2.045 1.944s-1.988-0.858-2.045-1.938l-0-0.005v-1.621h-5.771c0 0 0 0 0 0-0.81 0-1.467 0.657-1.467 1.467 0 0.002 0 0.004 0 0.006v-0 25.653c0.003 0.808 0.658 1.461 1.467 1.461 0 0 0 0 0 0h30.048c0.81 0 1.467-0.657 1.467-1.467v0-25.648c0-0.81-0.657-1.467-1.467-1.467v0z"
                        />
                        <path
                            fill="#9c9c9c"
                            style={{ fill: "var(--color3, #9c9c9c)" }}
                            d="M25.317 5.040c0 0.725-0.587 1.312-1.312 1.312s-1.312-0.587-1.312-1.312v-3.728c0-0.725 0.587-1.312 1.312-1.312s1.312 0.587 1.312 1.312v0 3.728z"
                        />
                    </symbol>
                    <symbol id="icon-corrections" viewBox="0 0 34 32">
                        <title>corrections</title>
                        <path
                            fill="#86b355"
                            style={{ fill: "var(--color5, #86b355)" }}
                            d="M28.384 11.787l2.016-3.083 0.992 0.656-2.016 3.083-0.992-0.656zM26.171 12.245l3.664 2.416 3.765-5.755-3.664-2.416-3.765 5.76z"
                        />
                        <path
                            fill="#929595"
                            style={{ fill: "var(--color6, #929595)" }}
                            d="M0 0v32h26.571v-8.725h-1.6v7.131h-23.371v-28.811h17.355l-0.107 5.499 6.123 0.048v3.109h1.6v-4.693l-6.091-0.048 0.101-5.509z"
                        />
                        <path
                            fill="#929595"
                            style={{ fill: "var(--color6, #929595)" }}
                            d="M19.467 1.227l6.005 5.472 1.077-1.179-6-5.472zM4.816 9.163h17.077v1.595h-17.077zM4.816 13.152h17.077v1.595h-17.077zM4.816 17.141h16.277v1.595h-16.277zM4.816 21.131h11.477v1.595h-11.477z"
                        />
                        <path
                            fill="#86b355"
                            style={{ fill: "var(--color5, #86b355)" }}
                            d="M4.816 25.12h11.477v1.595h-11.477zM21.259 22.523l-0.133 1.28 1.125-0.624 6.085-9.173-0.992-0.656-6.085 9.173zM19.211 26.677l0.496-4.715 7.184-10.827 3.664 2.421-7.184 10.827-4.16 2.293z"
                        />
                    </symbol>
                    <symbol id="icon-follow-up" viewBox="0 0 34 32">
                        <title>follow-up</title>
                        <path
                            fill="#86b355"
                            style={{ fill: "var(--color5, #86b355)" }}
                            d="M13.493 13.867v-2.331h-4.672v-4.667h4.667v-2.331l4.667 4.667-4.661 4.661z"
                        />
                        <path
                            fill="#929595"
                            style={{ fill: "var(--color6, #929595)" }}
                            d="M5.147 1.6h17.307l-0.107 5.493 6.107 0.048v23.259h-23.307v-28.805zM3.547 0v32h26.507v-26.443l-6.080-0.053 0.107-5.504h-20.533z"
                        />
                        <path
                            fill="#929595"
                            style={{ fill: "var(--color6, #929595)" }}
                            d="M22.969 1.228l1.075-1.177 5.989 5.471-1.075 1.177z"
                        />
                        <path
                            fill="#929595"
                            style={{ fill: "var(--color6, #929595)" }}
                            d="M8.357 17.141h17.029v1.595h-17.029zM8.357 21.131h17.029v1.595h-17.029zM8.357 25.12h17.029v1.595h-17.029z"
                        />
                    </symbol>
                    <symbol id="icon-ia" viewBox="0 0 34 32">
                        <title>ia</title>
                        <path
                            fill="#9c9c9c"
                            style={{ fill: "var(--color3, #9c9c9c)" }}
                            d="M5.147 1.6h17.301l-0.101 5.493 6.107 0.048v23.259h-23.307v-28.805zM3.547 0v32h26.507v-26.443l-6.080-0.053 0.107-5.504h-20.533z"
                        />
                        <path
                            fill="#9c9c9c"
                            style={{ fill: "var(--color3, #9c9c9c)" }}
                            d="M22.968 1.223l1.076-1.177 5.988 5.472-1.076 1.177z"
                        />
                        <path
                            fill="#9c9c9c"
                            style={{ fill: "var(--color3, #9c9c9c)" }}
                            d="M8.357 17.141h17.029v1.595h-17.029zM8.357 21.131h17.029v1.595h-17.029zM8.357 25.12h17.029v1.595h-17.029z"
                        />
                        <path
                            fill="#8db145"
                            style={{ fill: "var(--color4, #8db145)" }}
                            d="M15.397 9.296h-1.92l0.784-3.248c0.037-0.128 0.069-0.176 0.176-0.176s0.144 0.048 0.176 0.176l0.784 3.248zM17.76 12.619l-0.011-0.053-1.792-6.853c-0.267-1.013-1.051-1.12-1.52-1.12s-1.259 0.107-1.52 1.12l-1.792 6.853-0.011 0.053c0 0.107 0.091 0.187 0.197 0.187h1.157c0.101 0 0.187-0.091 0.213-0.187l0.496-2.080h2.517l0.501 2.080c0.021 0.096 0.107 0.187 0.213 0.187h1.152c0.107 0 0.197-0.080 0.197-0.187zM10.091 12.587v-7.696c0-0.123-0.101-0.208-0.224-0.208h-1.077c-0.123 0-0.219 0.085-0.219 0.208v7.696c0 0.117 0.096 0.213 0.213 0.213h1.088c0.117 0 0.213-0.096 0.213-0.213z"
                        />
                    </symbol>
                    <symbol id="icon-invoice" viewBox="0 0 34 32">
                        <title>invoice</title>
                        <path
                            fill="#929595"
                            style={{ fill: "var(--color6, #929595)" }}
                            d="M5.147 1.6h17.307l-0.107 5.493 6.107 0.048v23.259h-23.307v-28.805zM3.547 0v32h26.507v-26.443l-6.080-0.053 0.107-5.504h-20.533z"
                        />
                        <path
                            fill="#929595"
                            style={{ fill: "var(--color6, #929595)" }}
                            d="M22.968 1.223l1.076-1.177 5.988 5.472-1.076 1.177z"
                        />
                        <path
                            fill="#929595"
                            style={{ fill: "var(--color6, #929595)" }}
                            d="M8.357 17.141h17.029v1.595h-17.029zM8.357 21.131h17.029v1.595h-17.029zM8.357 25.12h17.029v1.595h-17.029z"
                        />
                        <path
                            fill="#86b355"
                            style={{ fill: "var(--color5, #86b355)" }}
                            d="M14.635 6.075v-1.227c0-0.133-0.016-0.192-0.235-0.24-0.384-0.091-1.333-0.235-2.037-0.235-2.293 0-3.163 0.853-3.163 2.763v1.024h-0.603c-0.139 0-0.245 0.101-0.245 0.235v1.2c0 0.133 0.096 0.235 0.235 0.235h0.613v1.232c0 0.421-0.235 0.912-0.405 1.136-0.053 0.075-0.107 0.149-0.107 0.299v0.864c0 0.133 0.053 0.293 0.229 0.293h5.456c0.139 0 0.251-0.096 0.251-0.235v-1.195c0-0.139-0.112-0.224-0.251-0.224h-3.381c0.133-0.235 0.245-0.533 0.245-0.939v-1.237h2.101c0.133 0 0.235-0.096 0.235-0.229v-1.2c0-0.139-0.112-0.235-0.251-0.235h-2.080v-1.035c0-0.795 0.32-0.976 1.12-0.976 0.656 0 1.52 0.085 2.037 0.149h0.037c0.107 0 0.197-0.064 0.197-0.224z"
                        />
                    </symbol>
                    <symbol id="icon-patient" viewBox="0 0 34 32">
                        <title>patient</title>
                        <path
                            fill="#9c9c9c"
                            style={{ fill: "var(--color3, #9c9c9c)" }}
                            d="M5.147 1.6h17.307l-0.107 5.493 6.107 0.048v23.259h-23.307v-28.805zM3.547 0v32h26.507v-26.443l-6.080-0.053 0.107-5.504h-20.533z"
                        />
                        <path
                            fill="#9c9c9c"
                            style={{ fill: "var(--color3, #9c9c9c)" }}
                            d="M22.969 1.227l1.076-1.177 5.988 5.472-1.076 1.177z"
                        />
                        <path
                            fill="#9c9c9c"
                            style={{ fill: "var(--color3, #9c9c9c)" }}
                            d="M8.357 21.131h17.029v1.595h-17.029zM8.357 25.12h17.029v1.595h-17.029z"
                        />
                        <path
                            fill="#8db145"
                            style={{ fill: "var(--color4, #8db145)" }}
                            d="M17.184 12.101c-0.979 0-1.773-0.794-1.773-1.773s0.794-1.773 1.773-1.773v0c0.979 0 1.773 0.794 1.773 1.773s-0.794 1.773-1.773 1.773v0zM19.024 12.688h-3.803c-0.815 0.003-1.474 0.663-1.477 1.477v3.648h1.301v-2.869h0.427v2.869h3.157v-2.869h0.427v2.869h1.44v-3.648c0 0 0 0 0 0 0-0.814-0.658-1.474-1.472-1.477h-0z"
                        />
                        <path
                            fill="#8db145"
                            style={{ fill: "var(--color4, #8db145)" }}
                            d="M17.12 6.907c-2.725 0.157-4.875 2.404-4.875 5.155s2.15 4.998 4.862 5.154l0.014 0.001c2.725-0.157 4.875-2.404 4.875-5.155s-2.15-4.998-4.862-5.154l-0.014-0.001zM17.12 18.811c-3.728-0.003-6.749-3.024-6.752-6.752v-0c0-3.717 3.029-6.747 6.752-6.747s6.752 3.024 6.752 6.747c0 3.728-3.029 6.752-6.752 6.752z"
                        />
                    </symbol>

                    {/* SVG icons for file explorer in IprsPortalMIReports component */}
                    <symbol id="icon-folder" viewBox="0 0 24 24">
                        <title>folder</title>
                        <path
                            fill="#636362"
                            style={{ fill: "var(--color3, #636362)" }}
                            d="M4 2C3.20435 2 2.44129 2.31607 1.87868 2.87868C1.31607 3.44129 1 4.20435 1 5V19C1 19.7957 1.31607 20.5587 1.87868 21.1213C2.44129 21.6839 3.20435 22 4 22H20C20.7957 22 21.5587 21.6839 22.1213 21.1213C22.6839 20.5587 23 19.7957 23 19V8C23 7.20435 22.6839 6.44129 22.1213 5.87868C21.5587 5.31607 20.7957 5 20 5H11.5352L10.1289 2.8906C9.75799 2.3342 9.13352 2 8.46482 2H4Z"
                        />
                    </symbol>
                    <symbol id="icon-file" viewBox="0 0 24 24">
                        <title>file</title>
                        <path
                            fill="#636362"
                            style={{ fill: "var(--color3, #636362)" }}
                            d="M11 9V1L5 1C3.89543 1 3 1.89543 3 3V21C3 22.1046 3.89543 23 5 23H19C20.1046 23 21 22.1046 21 21V11H13C11.8954 11 11 10.1046 11 9Z"
                        />
                        <path
                            fill="#636362"
                            style={{ fill: "var(--color3, #636362)" }}
                            d="M13 1V9H21L13 1Z"
                        />
                    </symbol>
                    <symbol id="icon-chevron-down" viewBox="0 0 24 24">
                        <title>chevronDown</title>
                        <path
                            fill="#636362"
                            style={{ fill: "var(--color3, #636362)" }}
                            d="M3.29297 6.70711C3.68349 6.31658 4.31666 6.31658 4.70718 6.70711L12.0001 14L19.293 6.70711C19.6835 6.31658 20.3167 6.31658 20.7072 6.70711L21.4143 7.41421C21.8048 7.80474 21.8048 8.4379 21.4143 8.82843L13.0607 17.182C12.4749 17.7678 11.5252 17.7678 10.9394 17.182L2.58586 8.82843C2.19534 8.4379 2.19534 7.80474 2.58586 7.41422L3.29297 6.70711Z"
                        />
                    </symbol>
                    <symbol id="icon-chevron-right" viewBox="0 0 24 24">
                        <title>chevronRight</title>
                        <path
                            fill="#636362"
                            style={{ fill: "var(--color3, #636362)" }}
                            d="M8.08586 5.41412C7.69534 5.80465 7.69534 6.43781 8.08586 6.82834L13.3788 12.1212L8.08586 17.4141C7.69534 17.8046 7.69534 18.4378 8.08586 18.8283L8.79297 19.5354C9.18349 19.926 9.81666 19.926 10.2072 19.5354L16.5607 13.1819C17.1465 12.5961 17.1465 11.6464 16.5607 11.0606L10.2072 4.70702C9.81666 4.31649 9.18349 4.31649 8.79297 4.70702L8.08586 5.41412Z"
                        />
                    </symbol>

                    <symbol id="icon-arrow-download" viewBox="0 0 24 24">
                        <title>arrowDownload</title>
                        <path
                            fill="#636362"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.5 15.5C4.05228 15.5 4.5 15.9477 4.5 16.5V19.5H19.5V16.5C19.5 15.9477 19.9477 15.5 20.5 15.5H21.5C22.0523 15.5 22.5 15.9477 22.5 16.5V20C22.5 21.3807 21.3807 22.5 20 22.5H4C2.61929 22.5 1.5 21.3807 1.5 20V16.5C1.5 15.9477 1.94772 15.5 2.5 15.5H3.5Z"
                        />
                        <path
                            fill="#636362"
                            d="M7.07615 11.6173C7.23093 11.2436 7.59557 11 8.00003 11H10.5V3C10.5 2.44772 10.9477 2 11.5 2H12.5C13.0523 2 13.5 2.44771 13.5 3V11H16C16.4045 11 16.7691 11.2436 16.9239 11.6173C17.0787 11.991 16.9931 12.4211 16.7071 12.7071L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L7.29292 12.7071C7.00692 12.4211 6.92137 11.991 7.07615 11.6173Z"
                        />
                    </symbol>

                    <symbol id="icon-arrow-upload" viewBox="0 0 24 24">
                        <title>arrowUpload</title>
                        <path
                            fill="#636362"
                            d="M8.00003 8C7.59557 8 7.23093 7.75636 7.07615 7.38268C6.92137 7.00901 7.00692 6.57889 7.29292 6.29289L11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L16.7071 6.29289C16.9931 6.57889 17.0787 7.00901 16.9239 7.38268C16.7691 7.75636 16.4045 8 16 8H13.5V16C13.5 16.5523 13.0523 17 12.5 17H11.5C10.9477 17 10.5 16.5523 10.5 16V8H8.00003Z"
                        />
                        <path
                            fill="#636362"
                            d="M4.5 16.5C4.5 15.9477 4.05228 15.5 3.5 15.5H2.5C1.94772 15.5 1.5 15.9477 1.5 16.5V20C1.5 21.3807 2.61929 22.5 4 22.5H20C21.3807 22.5 22.5 21.3807 22.5 20V16.5C22.5 15.9477 22.0523 15.5 21.5 15.5H20.5C19.9477 15.5 19.5 15.9477 19.5 16.5V19.5H4.5V16.5Z"
                        />
                    </symbol>

                    <symbol id="icon-trash" viewBox="0 0 24 24">
                        <title>trash</title>
                        <path
                            fill="#636362"
                            d="M8 1.5V2.5H3C2.44772 2.5 2 2.94772 2 3.5V4.5C2 5.05228 2.44772 5.5 3 5.5H21C21.5523 5.5 22 5.05228 22 4.5V3.5C22 2.94772 21.5523 2.5 21 2.5H16V1.5C16 0.947715 15.5523 0.5 15 0.5H9C8.44772 0.5 8 0.947715 8 1.5Z"
                        />
                        <path
                            fill="#636362"
                            d="M3.9231 7.5H20.0767L19.1344 20.2216C19.0183 21.7882 17.7135 23 16.1426 23H7.85724C6.28636 23 4.98148 21.7882 4.86544 20.2216L3.9231 7.5Z"
                        />
                    </symbol>
                </defs>
            </svg>
        </div>
    );
};

export default IprsPortalSvg;
