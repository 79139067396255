import React from "react";

import ClientSettingsTableContent from "./ClientSettingsTableContent";

const ClientSettingsList = ({ api, settings, clientID }) => {
    return (
        <>
            {settings?.length !== 0 ? (
                <div className="clientSettingsListTableDiv">
                    <table className="clientSettingsListTable">
                        <thead>
                            <tr>
                                <th className="clientSettingsListTableHead">
                                    Default
                                </th>
                                <th className="clientSettingsListTableHead">
                                    Value
                                </th>
                                <th className="clientSettingsListTableHead">
                                    Description
                                </th>
                                <th className="clientSettingsListTableHead"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ClientSettingsTableContent
                                api={api}
                                settings={settings}
                                clientID={clientID}
                            />
                        </tbody>
                    </table>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default ClientSettingsList;
