import { CallStates } from "../../../../SharedComponents/AppStates/Utils";

export const getLatestContract = (organisationID, api, setCallState) => {
  let returnObject = {
    contractInfo: null,
    director: null,
    associatePractice: null,
  };
  return api
    .transact("GetLatestContract", { PartyID: organisationID })
    .then((response) => {
      returnObject.contractInfo = {
        ContractID: response.apiResult.ContractID,
        ContractText: response.apiResult.ContractText,
        DateAdded: response.apiResult.DateAdded,
        DateSigned: response.apiResult.DateSigned,
      };
      returnObject.director = response.apiResult.Director;
      return api.transact("GetAssociatePractice", {
        OrganisationID: organisationID,
      });
    })
    .then((response) => {
      returnObject.associatePractice = response.apiResult;
      setCallState(CallStates.SUCCESS);
      return returnObject;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const unsignContract = (
  contractID,
  organisationID,
  api,
  setCallState
) => {
  return api
    .transact("UnsignedContractByID", {
      ContractID: contractID,
      PartyID: organisationID,
    })
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const savePaymentDays = (associatePractice, api, setCallState) => {
  return api
    .transact("UpdateAssociatePractice", associatePractice)
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      return;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
