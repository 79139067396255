export const CallStates = {
  INITIAL: "initial",
  LOADING: "loading",
  LOADED: "loaded",
  SAVING: "saving",
  SAVED: "saved",
  SUCCESS: "success",
  ERROR: "error",
  SENT: "sent",
  SENDING: "sending",
};
