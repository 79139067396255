import React, {useState} from 'react';
import getAndSaveCaseID from './getAndSaveCaseID';
import {LoadingIndicator} from '../../lib/iprs-react-library/src/index.js';

const F2FSelfReferral = ({api, option='default'}) => {

	const formatDateToCustomString = (timestamp) => {
		const date = new Date(timestamp);
		const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		
		const formattedDate = `${days[date.getDay()]}, ${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()} ${date.getHours()}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
		
		return formattedDate;
	}

	const [messageTextUnderF2FButton, setMessageTextUnderF2FButton] = useState("");
	const [disableF2FButton, setDisableF2FButton] =  useState(false);
	  
	const currentTimestamp = Date.now();
	const DateScheduled = formatDateToCustomString(currentTimestamp);

	const [loaderStatus, setLoaderStatus] = useState(false);

	const selfReferralButtonHandler = (e) => {
		e.preventDefault()
		setMessageTextUnderF2FButton("");
		setLoaderStatus(true);

		const caseID = getAndSaveCaseID();

		const params = {
			'CaseID': caseID,
			'Data': {
				'WorkflowDescription': 'Send Self Referral Email',
				'DateScheduled': DateScheduled
			}
		}

		api.transact('SaveCaseWorkflow', params)
		.then(r=>{
			console.log('r: ',r);
			setLoaderStatus(false);
			setDisableF2FButton(true)
			setMessageTextUnderF2FButton("F2F Self Referral has been made succesfully.");
		})
		.catch(e=>{
			console.log('e: ',e);
			setLoaderStatus(false);
			setMessageTextUnderF2FButton(e.apiStatus + " " + e.apiText);
		})
	}

	const buttonStyleOptions = () => {
		if(option === 'default'){
			return "Button caseControlButton"
		} else if (option === 'onwardReferral'){
			return "searchPracticeButton"
		}
	}

	const buttonDivStyleOptions = () => {
		if(option === 'default'){
			return ""
		} else if (option === 'onwardReferral'){
			return "searchAndMoreButton"
		}
	}

	const buttonWidth = () => {
		if(option === 'default'){
			return {width: '175px'}
		} else if (option === 'onwardReferral'){
			return {width: "270px", marginTop: "0px"}
		}
	}

    return (
		<div>
			<button 
				style={{width:'175px'}}
				onClick={e => selfReferralButtonHandler(e)} 
				className="Button caseControlButton" 
				id="F2FSelfReferralButton"
				type="button" 
				disabled={disableF2FButton} >
					{
						loaderStatus ? 
						<LoadingIndicator loading={loaderStatus} size={10}/> : 
						"F2F Self Referral"
					}
			</button>
			{messageTextUnderF2FButton === "" ? <></> : <p>{messageTextUnderF2FButton}</p>}
		</div>
    )
}

export default F2FSelfReferral