import { CallStates } from "../../../../SharedComponents/AppStates/Utils";

export const getSummary = (api, facilityID, setCallState) => {
  return api
    .transact("GetClinicProfileSummary", { FacilityID: facilityID })
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const saveSummary = async (api, summary, setCallState) => {
  //put all into a date format so the api will recieve it as a DateTime data structure
  summary.FacilityHoursList.forEach((item) => {
    item.StartTime ?? new Date(item.StartTime);
    item.FinishTime ?? new Date(item.FinishTime);
  });
  summary.OnHoldInfo.DateFrom ?? new Date(summary.OnHoldInfo.DateFrom);
  summary.OnHoldInfo.DateTo
    ? new Date(summary.OnHoldInfo.DateTo)
    : (summary.OnHoldInfo = null); //send through the on hold info as null if it doesn't have a DateTo i.e. if it has been left blank by the user

  return api
    .transact("SaveClinicProfileSummary", { Summary: summary })
    .then((response) => {
      setCallState(CallStates.SAVED);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
