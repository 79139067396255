export const handlePrintClick = (event, person, contractInfo) => {
  event.preventDefault();
  const { DateSigned, ContractText } = contractInfo;
  const { FirstName, LastName } = person;

  const mywindow = window.open("", "PRINT", "height=400,width=600");

  if (mywindow) {
    const signedDate = new Date(DateSigned);
    const formattedSignedDate =
      (signedDate.getDate() > 10 ? "0" : "") +
      signedDate.getDate() +
      "/" +
      (signedDate.getMonth() < 10 ? "0" : "") +
      signedDate.getMonth() +
      "/" +
      signedDate.getFullYear() +
      " " +
      (signedDate.getHours() < 10 ? "0" : "") +
      signedDate.getHours() +
      ":" +
      signedDate.getMinutes();

    const header =
      `<p><b>Signed By: </b>${FirstName} ${LastName}</p>` +
      `<p><b>Signed On:</b> ${formattedSignedDate}</p><hr /><br/>`;

    mywindow.document.write(header);
    mywindow.document.write(ContractText);

    mywindow.document.close(); // Necessary for IE >= 10
    mywindow.focus(); // Necessary for IE >= 10

    mywindow.print();
    mywindow.close();
  }

  return;
};

export function escapeHtmlTags(inputString) {
  return inputString.replace(/</g, "&lt;").replace(/>/g, "&gt;");
}
