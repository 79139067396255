export const contactModel = () => {
  return {
    ID: 0,
    ContactDetails: {
      BusinessPhone: {
        ActionFlag: 2,
        AreaCode: "",
        ContactMechanismTypeDescription: "Business phone",
        ContactMechanismTypeID: 2009,
        ContactNumber: "",
        CountryCode: "",
        Extension: "",
      },
      EmailAddress: {
        ActionFlag: 2,
        ContactMechanismTypeDescription: "e-Mail address",
        ContactMechanismTypeID: 2004,
        ElectronicAddressString: "",
      },
      WebAddress: {
        ActionFlag: 2,
        ContactMechanismTypeDescription: "Web address",
        ContactMechanismTypeID: 2007,
        ElectronicAddressString: "",
      },
    },
    Person: {
      ActionFlag: 2,
      FirstName: "",
      LastName: "",
    },
  };
};
