import React, {useEffect, useState} from 'react';

import home_icon from "./img/new/home-icon.svg";
import briefcase_icon from "./img/new/briefcase-icon.svg";
import location_pin from "./img/new/location-pin-icon.svg";

import { CustomPinMarker } from '../SharedComponents/Maps/CustomMarkerGenerator';


const Legend = ({ ranking }) => {
  const legendUserLocationMarkers = [
    { type: 'location', 
      name: 'Home', 
      icon: home_icon,
      color: '000000'
    },
    { type: 'location', 
      name: 'Work', 
      icon: briefcase_icon,
      color: '000000'
    },
    { type: 'location', 
      name: 'Alternative', 
      icon: location_pin,
      color: '000000' 
    }
  ];

  const [legendClinicMarker, setLegendClinicMarker] = useState([]);

  useEffect(() => {
    if (ranking !== []) {
      let markers = []
      
      for (let i = 0; i < ranking.length; i++) {
        markers.push({
          type: 'ranking',
          name: ranking[i].PracticeRankingDescription,
          icon: CustomPinMarker(ranking[i].Colour, "A").url,
          colour: '#' + ranking[i].Colour
        });
      }
      setLegendClinicMarker(markers);
    }
  }, [ranking]);

  return (
    <div className="onwardReferralLegend">

      {/* Legend version 2*/}
      <div className="legendMarkerLabels">
        {legendUserLocationMarkers.map((legend, index) => (
          <div key={index} className="legendMarkerLabel">
            <div className="legendMarkerIcon">
              <img
                src={legend.icon}
                className="legendIcons"
                alt={legend.name}
              />
            </div>
            <div className="legendMarkerIconLabel">{legend.name}</div>
          </div>
        ))}
        {legendClinicMarker.map((legend, index) => (
          <div key={index} className="legendMarkerLabel" style={legend.borderStyle}>
            <div className="legendMarkerIcon">
              <img
                src={legend.icon}
                className="legendIcons"
                alt={legend.name}
              />
            </div>
            <div className="legendMarkerIconLabel">{legend.name}</div>
          </div>
        ))}
      </div>

    </div>
  );
};

export default Legend;