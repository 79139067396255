import { RadioInputBooleanWithLabel } from "../../../../../../SharedComponents/InputComponents/RadioInput";
import { TextInputWithLabel } from "../../../../../../SharedComponents/InputComponents/TextInput";
import { DateInputWithLabel } from "../../../../../../SharedComponents/InputComponents/DateInput";
import TextAreaInputWithLabel from "../../../../../../SharedComponents/InputComponents/TextAreaInput";
import "../../../../AppUtils/Styles/ContainerStyles.css";
import { useState, useEffect } from "react";

function D1Planning({ facility, handleChange }) {
  const {
    D1Planning,
    D1PlanningApplicationNumber,
    D1PlanningApplicationDate,
    D1PlanningReason,
  } = facility;

  const [D1PlanningAcquired, setD1PlanningAcquired] = useState(D1Planning);

  useEffect(() => {
    setD1PlanningAcquired(D1Planning);
  }, [D1Planning]);

  const handleConditionalChange = (e) => {
    setD1PlanningAcquired(e.target.value);
    handleChange(e);
  };

  return (
    <div className='react-shared-flex-container-row'>
      <div className='react-shared-flex-item'>
        <RadioInputBooleanWithLabel
          inputLabel='D1 Planning Approved'
          name='D1Planning'
          value={D1Planning}
          trueLabel='Yes'
          falseLabel='No'
          handleChange={handleConditionalChange}
        />
      </div>
      <div className='react-shared-flex-item'>
        {(D1PlanningAcquired === "true" || D1PlanningAcquired === true) && (
          <div>
            <div>
              <TextInputWithLabel
                inputLabel='D1 Planning Application Number'
                name="D1PlanningApplicationNumber"
                value={D1PlanningApplicationNumber}
                handleChange={handleChange}
              />
            </div>
            <div>
              <DateInputWithLabel
                inputLabel='D1 Planning Application Date'
                name="D1PlanningApplicationDate"
                value={D1PlanningApplicationDate ?? ""}
                handleChange={handleChange}
              />
            </div>
          </div>
        )}
        {(D1PlanningAcquired === "false" || D1PlanningAcquired === false) && (
          <div>
            <TextAreaInputWithLabel
              inputLabel='Reason'
              charMax={150}
              name='D1PlanningReason'
              value={D1PlanningReason}
              handleChange={handleChange}
            />
          </div>
        )}
      </div>
      <div>
        <p>*D1 Non-residential Institutions</p>
        <p>
          Clinics, Health centres, Day nurseries, Day centres, Museums, Public
          libraries, Art galleries, Exhibition Halls, Lav court, Non-residential
          education & training centres, Places of worship, Religious
          instruction, Church halls, Non-residential education & training
          centres, Places of worship, Relligious instruction, Church halls
        </p>
        <p>*Scotland clinics should be registered at Class 2</p>
        <p>
          Class 2; Financial, professional or any other service expected in
          shopping areas e.g. betting office, lawyers, accountants, estate
          agents, health centres, surgeries of dentists, doctors and vets (where
          the principal visitors are members of the public). To check the
          registration of your clinic please log onto your local borough council
          website and search in planning / building registrations, if you do not
          have this information to hand.
        </p>
      </div>
    </div>
  );
}
export default D1Planning;
