import React, { useEffect, useState } from "react";

//api calls
import {
  getInsuranceLegalDetails,
  saveInsuranceLegalDetails,
} from "./Transactions";

//page components
import FacilityInsuranceTable from "./SectionComponents//InsuranceTable/InsuranceTable";
import FacilityPoliciesAndProcedures from "./SectionComponents/PoliciesAndProcedures/FacilityPoliciesAndProcedures";
import D1Planning from "./SectionComponents//D1Planning/D1Planning";
import GovernanceRiskSecurity from "./SectionComponents/GovernanceRiskSecurity/GovernanceRiskSecurity";

//functions
import { handleInputChange } from "../../../../SharedComponents/Utils/InputUtils";

//styling
import { PartitionLine } from "../../../../SharedComponents/PageLayoutComponents/Partition";
import "../../../../SharedComponents/Styles/FadeStyles.css";

//page management
import { CallStates } from "../../../../SharedComponents/AppStates/Utils";
import PageState from "../../../../SharedComponents/AppStates/PageState";
import { Button } from "../../../../SharedComponents/ButtonComponents/Button";

const InsuranceLegal = ({ api, facilityID }) => {
  const [facility, setFacility] = useState(null);

  const [callState, setCallState] = useState(CallStates.INITIAL);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (callState === CallStates.INITIAL || callState === CallStates.SAVED) {
      getInsuranceLegalDetails(api, facilityID, setCallState)
        .then((data) => {
          setFacility(data);
          setCallState(CallStates.LOADED);
        })
        .catch((e) => {
          setErrorMessage(e.message);
          setCallState(CallStates.ERROR);
        });
    }
  }, [callState]);

  const handleSubmit = (event) => {
    event.preventDefault();
    saveInsuranceLegalDetails(api, facility, setCallState).catch((e) => {
      setErrorMessage(e.message);
      setCallState(CallStates.ERROR);
    });
  };

  return (
    <PageState callState={callState} errorMessage={errorMessage}>
      <form
        onSubmit={(event) => {
          handleSubmit(event);
        }}
        style={{ padding: "2rem" }}
        className="fade-in"
      >
        <div className='padding2'>
          <FacilityInsuranceTable
            facility={facility}
            handleChange={(event) =>
              handleInputChange(event, facility, setFacility)
            }
          />
        </div>
        <PartitionLine style={{ padding: "2rem 0rem" }} />
        <div className='padding2'>
          <D1Planning
            facility={facility}
            handleChange={(event) =>
              handleInputChange(event, facility, setFacility)
            }
          />
        </div>
        <div className='padding2'>
          <GovernanceRiskSecurity
            facility={facility}
            handleChange={(event) =>
              handleInputChange(event, facility, setFacility)
            }
          />
        </div>
        <div style={{ padding: "2rem" }}>
          <Button label='Save' handleClick={() => {}} type='submit' />
        </div>
      </form>
    </PageState>
  );
};
export default InsuranceLegal;
