import "../Styles/SharedGenericStyles.css"
import "./CheckboxSliderInput.css";

export const CheckboxSliderInputWithLabel = ({
  inputLabel,
  value,
  name,
  disabled = false,
  handleChange,
}) => {
  return (
    <div className='clinic-profile-InLine container-100-justified-content'>
      <div>
        <label>{inputLabel}: </label>
      </div>
      <div className='container-50-align-left'>
        <label className='clinic-profile-switch'>
          <input
            type='checkbox'
            name={name}
            checked={value}
            onChange={(e) => handleChange(e)}
            disabled={disabled}
          />
          <span className='clinic-profile-slider clinic-profile-round'></span>
        </label>
      </div>
    </div>
  );
};

export const CheckboxSliderInput = ({
  value,
  name,
  handleChange,
  disabled = false,
}) => {
  return (
    <div style={{ marginLeft: "0.2rem" }}>
      <label className='clinic-profile-switch'>
        <input
          type='checkbox'
          name={name}
          checked={value}
          onChange={(e) => handleChange(e)}
          disabled={disabled}
        />
        <span className='clinic-profile-slider clinic-profile-round'></span>
      </label>
    </div>
  );
};
