//button components
import { SendEmailButton } from "../../../../../SharedComponents/ButtonComponents/EmailButtons/SendEmailButton";
import { OpenUrlInNewTab } from "../../../../../SharedComponents/UrlNavigationComponents/UrlNavigationButtons";

//input components
import {
  TextInput,
  TextInputWithLabel,
} from "../../../../../SharedComponents/InputComponents/TextInput";

//label components
import { FormLabel } from "../../../../../SharedComponents/FormComponents/FormLabel";

const ElectronicAddress = ({
  emailDetails = null,
  webAddressDetails = null,
  sendEmailButton = false,
  handleSendEmailClick = null,
  disabled = false,
  handleChange,
}) => {
  const { ElectronicAddressString } = emailDetails ?? webAddressDetails; // prioritise email if both are provided
  return (
    <>
      {!sendEmailButton && emailDetails && (
        <TextInputWithLabel
          inputLabel='Email address'
          name='ElectronicAddressString'
          value={ElectronicAddressString}
          disabled={disabled}
          handleChange={handleChange}
        />
      )}
      {!sendEmailButton && webAddressDetails && (
        <>
          <div
            className='react-shared-InLine container-100-justified-content'
            style={{ alignItems: "center" }}
          >
            <FormLabel displayValue='Web address' />
            <div className='container-50-align-left'>
              <div>
                <TextInput
                  name='ElectronicAddressString'
                  value={ElectronicAddressString}
                  disabled={disabled}
                  handleChange={handleChange}
                />
              </div>
              <div style={{ verticalAlign: "0.2em" }}>
                <OpenUrlInNewTab url={ElectronicAddressString} />
              </div>
            </div>
          </div>
        </>
      )}
      {sendEmailButton && (
        <>
          <div
            className='react-shared-InLine container-100-justified-content'
            style={{ alignItems: "center" }}
          >
            <FormLabel displayValue='Email address:' />
            <div className='container-50-align-left'>
              <div>
                <TextInput
                  name='ElectronicAddressString'
                  value={ElectronicAddressString}
                  disabled={disabled}
                  handleChange={handleChange}
                />
              </div>
              <div style={{ verticalAlign: "0.1em" }}>
                <SendEmailButton handleClick={handleSendEmailClick} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ElectronicAddress;
