import React, { useState, useEffect, useCallback } from "react";
import { LoadingIndicator } from "../../../index.js";
import DirectoryRow from "./DirectoryRow.jsx";
import { fetchUserFileTree } from "./api/fetchUserFileTree";
import { urlCheckForPharos } from './utils/urlCheckForPharos.js';
import "../style.css";

// This is the starting point, it will set the initialFileTree state using the file tree received
// from the API and making the first call to the DirectoryRow component.
const UserFileTree = ({
    partyId,
    api,
    fetchUpdatedFileTree,
    collapseDefault, // Set this to true if you want the folders collapsed by default.
}) => {
    const [initialFileTree, setInitialFileTree] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [collapse, setCollapse] = useState(collapseDefault || false);
    const [currentUrl, setCurrentUrl] = useState(false);

    // Checks the URL to distinguish whether it is being called from webreferral
    // or Pharos to hide or show three buttons (add directory, upload files, delete files).
    // Only Pharos shows all the buttons.
    useEffect(() => {
        const urlCheckBoolean = urlCheckForPharos();
        setCurrentUrl(urlCheckBoolean);
    }, []);

    const fetchTree = useCallback(async () => {
        setLoading(true);
        setError(null); // Clear any previous errors when fetching new data
        try {
            const { data, error } = await fetchUserFileTree(api, partyId);
            if (error) {
                setError(error);
            } else {
                setInitialFileTree(data);
            }
        } catch (err) {
            setError("Unexpected error occurred while fetching the file tree");
        } finally {
            setLoading(false);
        }
    }, [api, partyId]);

    useEffect(() => {
        fetchTree();
    }, [fetchTree]);

    return (
        <div className="user-file-tree">
            {loading && <LoadingIndicator loading={loading} color={"white"} />}
            {!loading && error && (
                <p style={{ color: "red" }} data-testid="error-message">
                    {error}
                </p>
            )}
            {!loading && !error && initialFileTree.length > 0 && (
                <>
                    {initialFileTree.map((node, i) => (
                        <DirectoryRow
                            key={node.Name + i + partyId}
                            keyName={node.Name + i + partyId}
                            directory={node}
                            api={api}
                            parentPath=""
                            partyId={partyId}
                            fetchUpdatedFileTree={fetchUpdatedFileTree}
                            currentUrl={currentUrl}
                            collapse={collapse}
                        />
                    ))}
                </>
            )}
            {!loading && !error && initialFileTree.length === 0 && (
                <p>No files found</p>
            )}
        </div>
    );
};

export default UserFileTree;