export const handleInputChange = (e, state, setState) => {
  const { name, value, type, checked } = e.target;

  // Handle different input types
  if (type === "checkbox") {
    setState({
      ...state,
      [name]: checked,
    });
  } else {
    setState({
      ...state,
      [name]: value,
    });
  }
};

export const handleInputChangeWithinArray = (e, state, setState, index) => {
  const { name, value, type, checked } = e.target;
  const newArray = [...state];

  // Handle different input types
  if (type === "checkbox") {
    newArray[index] = {
      ...newArray[index],
      [name]: checked,
    };
  } else {
    newArray[index] = {
      ...newArray[index],
      [name]: value,
    };
  }
  setState(newArray);
};

export const parseAsFloat = (string) => {
  const returnValue = parseFloat(string);

  if (string === "") {
    return 0;
  } else if (isNaN(returnValue)) {
    throw new Error(
      "Invalid input value. Expected string to represent a float."
    );
  } else {
    return returnValue;
  }
};

export const convertStringToBoolean = (value) => {
  let booleanValue = null;

  if (value.toLowerCase() === "true") {
    booleanValue = true;
  } else if (value.toLowerCase() === "false") {
    booleanValue = false;
  } else if (value.toLowerCase() === "null" || value === "") {
    booleanValue = null;
  } else {
    throw new Error(
      "Invalid input value. Expected 'true', 'false', or 'null'."
    );
  }

  return booleanValue;
};
