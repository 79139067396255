import { CallStates } from "../../../../SharedComponents/AppStates/Utils";

export const getClinicians = (facilityID, api, setCallState) => {
  return api
    .transact("ClinicianData", { ClinicID: facilityID })
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const getClinician = (partyID, api, setCallState) => {
  return api
    .transact("GetClinician", { PartyID: partyID })
    .then((response) => {
      if (response.apiResult.AdditionalProfessionalBodies) {
        response.apiResult.AdditionalProfessionalBodies.forEach((element) => {
          if (element.ExpiryDate === "1900-01-01T00:00:00") {
            element.ExpiryDate = ""; //for some reason this gets changed to 1900 somewhere in the API if it goes through as null and then makes it a pain to change in the front end
          }
        });
      }
      setCallState(CallStates.SUCCESS);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const saveClinicians = (
  facilityID,
  facilityClinicians,
  organisationClinicians,
  setCallState,
  api
) => {
  return api
    .transact("ClinicianDataSave", {
      Data: {
        FacilityID: facilityID,
        ExistingClinicianList: facilityClinicians,
        AvailableClinicianList: organisationClinicians,
      },
    })
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const insertClinician = (api, clinician, facilityID, setCallState) => {
  const {
    Clinician,
    Person,
    ContactMechanism,
    AdditionalProfessionalBodies,
    DBS,
    UserGroups,
  } = clinician;
  return api
    .transact("InsertClinician", {
      FacilityID: facilityID,
      Clinician: Clinician,
      Person: Person,
      ContactMechanism: ContactMechanism,
      AdditionalProfessionalBodies: AdditionalProfessionalBodies,
      DBS: DBS,
      UserGroups: UserGroups,
    })
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const updateClinician = (api, clinician, facilityID, setCallState) => {
  const {
    Clinician,
    Person,
    ContactMechanism,
    AdditionalProfessionalBodies,
    DBS,
    UserGroups,
  } = clinician;
  return api
    .transact("UpdateClinician", {
      FacilityID: facilityID,
      Clinician: Clinician,
      Person: Person,
      ContactMechanism: ContactMechanism,
      AdditionalProfessionalBodies: AdditionalProfessionalBodies,
      DBS: DBS,
      UserGroups: UserGroups,
    })
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const passwordReset = (api, username, setCallState) => {
  return api
    .transact("RequestPasswordReset", {
      username: username,
    })
    .then((response) => {
      return;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
