//input components
import { TextInput } from "../../../../../SharedComponents/InputComponents/TextInput";

//label components
import {
  FormLabelWithLabel,
  FormLabel,
} from "../../../../../SharedComponents/FormComponents/FormLabel";

//styling
import "../../../../../SharedComponents/Styles/SharedGenericStyles.css";

const PhoneNumber = ({ phoneDetails, readOnly, handleChange }) => {
  const { ContactNumber, AreaCode } = phoneDetails;
  return (
    <>
      {!readOnly && (
        <div className='react-shared-InLine container-100-justified-content'>
          <FormLabel displayValue='Contact number:' />
          <div className='container-50-align-left'>
            <div>
              <TextInput
                name='AreaCode'
                value={AreaCode}
                handleChange={handleChange}
              />
            </div>
            <div>
              <TextInput
                name='ContactNumber'
                value={ContactNumber}
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
      )}
      {readOnly && (
        <div>
          <FormLabelWithLabel
            label='Telephone'
            displayValue={AreaCode + " " + ContactNumber}
          />
        </div>
      )}
    </>
  );
};
export default PhoneNumber;
