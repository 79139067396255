export const pageNumberArray = (totalItems, itemsPerView, setLastPage) => {
  const numberOfPages = Math.ceil(totalItems / itemsPerView);
  setLastPage(numberOfPages);

  const viewsArray = [];
  for (let i = 0; i < numberOfPages; i++) {
    viewsArray.push(i + 1);
  }

  return viewsArray;
};

export const setPageList = (allItems, setList, itemsPerView, pageNumber) => {
  const viewArray = [];
  let startIndex = itemsPerView * pageNumber - itemsPerView;
  let endIndex = Math.min(startIndex + itemsPerView, allItems.length);

  if (startIndex < 0) {
    startIndex = 0;
  }

  if (endIndex > allItems.length) {
    endIndex = allItems.length;
  }

  for (let i = startIndex; i < endIndex; i++) {
    viewArray.push(allItems[i]);
  }

  setList(viewArray);
};
