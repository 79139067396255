import "../Styles/SharedGenericStyles.css"
import { useState, useEffect } from "react";
import { convertStringToBoolean } from "../Utils/InputUtils";
import { incorrectDataFormat, troubleLoadingData } from "../Utils/ErrorMessages";
import { validateValue } from "./Utils";

export const SelectYesNoInputWithLabel = ({
  inputLabel,
  name,
  value,
  handleChange,
  includeNullOption = false,
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [booleanValue, setBooleanValue] = useState(null);

  useState(() => {
    try {
      if (value === false) {
        setSelectedValue("false");
      } else if (value === true) {
        setSelectedValue("true");
      } else if (value === null) {
        setSelectedValue("");
      }
    } catch (error) {
      console.log(error.message);
      alert(troubleLoadingData());
    }
  }, [value]);

  const handleSelectChange = (e) => {
    const newValue = e.target.value;
    setSelectedValue(newValue);

    const customEvent = {
      ...e,
      target: {
        ...e.target,
        name: name,
        value: convertStringToBoolean(newValue),
      },
    };
    handleChange(customEvent);
  };

  return (
    <>
      {includeNullOption && (
        <div className='react-shared-InLine container-100-justified-content'>
          <div>
            <label>{inputLabel}: </label>
          </div>
          <div className='container-50-align-left'>
            <select
              name={name}
              value={selectedValue}
              onChange={handleSelectChange}
            >
              <option value='true'>Yes</option>
              <option value='false'>No</option>
              <option value=''>N/A</option>
            </select>
          </div>
        </div>
      )}
      {!includeNullOption && (
        <div className='react-shared-InLine container-100-justified-content'>
          <div>
            <label>{inputLabel}: </label>
          </div>
          <div className='container-50-align-left'>
            <select
              name={name}
              value={selectedValue}
              onChange={handleSelectChange}
            >
              {selectedValue === "" || selectedValue === null ? (
                <option value=''>Select an option</option>
              ) : null}
              <option value='true'>Yes</option>
              <option value='false'>No</option>
            </select>
          </div>
        </div>
      )}
    </>
  );
};

export const SelectBooleanInput = ({
  name,
  value,
  handleChange,
  trueLabel = "Yes",
  falseLabel = "No",
  includeNullOption = false,
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    try {
      if (value === false) {
        setSelectedValue("false");
      } else if (value === true) {
        setSelectedValue("true");
      } else if (value === null) {
        setSelectedValue("");
      }
    } catch (error) {
      console.log(error.message);
      alert(troubleLoadingData());
    }
  }, []);

  const handleSelectChange = (e) => {
    const newValue = e.target.value;
    setSelectedValue(newValue);
    let booleanValue = null;

    try {
      booleanValue = convertStringToBoolean(newValue);
    } catch (error) {
      console.log(error.message);
      alert(incorrectDataFormat());
    }

    const customEvent = {
      ...e,
      target: {
        ...e.target,
        name: name,
        value: booleanValue,
      },
    };
    handleChange(customEvent);
  };

  return (
    <>
      {includeNullOption && (
        <div>
          <select
            name={name}
            value={selectedValue}
            onChange={handleSelectChange}
          >
            <option value='true'>{trueLabel}</option>
            <option value='false'>{falseLabel}</option>
            <option value=''>N/A</option>
          </select>
        </div>
      )}
      {!includeNullOption && (
        <div>
          <select
            name={name}
            value={selectedValue}
            onChange={handleSelectChange}
          >
            {selectedValue === "" || selectedValue === null ? (
              <option value=''>Select an option</option>
            ) : null}
            <option value='true'>{trueLabel}</option>
            <option value='false'>{falseLabel}</option>
          </select>
        </div>
      )}
    </>
  );
};

/*
The SelectInput component is designed to select from a pre-defined list of options where an object property is a link to an object within the list
the name prop is the name of the field on the object referencing the id of watever is in the list
the value prop should be the value of the property you're looking to update with the select component
the options is an array of the available options
the labelProperty is the property on the objects in the array which relate to the description of the object (i.e. a user friendly word instead of an index if one is present, if not then don't use this component)
the valueProperty is the property on the objects in the array which relate to the referenced value from the referencing object i.e. the ID of the whatever is in the list
*/
export const SelectInputWithLabel = ({
  name,
  inputLabel,
  value,
  options,
  labelProperty,
  valueProperty,
  handleChange,
  required = false,
  disabled = false,
}) => {
  const [selectedValue, setSelectedValue] = useState(
    validateValue(value, options, valueProperty)
  );

  const handleSelectChange = (e) => {
    let newValue = e.target.value;
    if (!isNaN(parseInt(newValue))) {
      newValue = parseInt(newValue);
    }

    setSelectedValue(newValue);

    const customEvent = {
      ...e,
      target: {
        ...e.target,
        name: name,
        value: newValue,
      },
    };
    handleChange(customEvent);
  };

  return (
    <div className='react-shared-InLine container-100-justified-content'>
      <div>
        <label>{inputLabel}: </label>
      </div>
      <div className='container-50-align-left'>
        <select
          name={name}
          value={selectedValue}
          required={required}
          onChange={handleSelectChange}
          disabled={disabled}
        >
          {selectedValue === "" || selectedValue === null ? (
            <option value=''>Select an option</option>
          ) : null}
          {options.map((option) => (
            <option key={option[labelProperty]} value={option[valueProperty]}>
              {option[labelProperty]}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export const SelectInput = ({
  name,
  value,
  options,
  labelProperty,
  valueProperty,
  handleChange,
  disabled = false,
  required = false,
}) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleSelectChange = (e) => {
    let newValue = e.target.value;
    if (!isNaN(parseInt(newValue))) {
      newValue = parseInt(newValue);
    }
    setSelectedValue(newValue);

    const customEvent = {
      ...e,
      target: {
        ...e.target,
        name: name,
        value: newValue,
      },
    };
    handleChange(customEvent);
  };

  return (
    <div className='react-shared-InLine'>
      <select
        name={name}
        value={selectedValue}
        onChange={handleSelectChange}
        disabled={disabled}
        required={required}
      >
        {selectedValue === null || selectedValue === "" ? (
          <option value=''>Select an option</option>
        ) : null}
        {options.map((option, index) => (
          <option key={index} value={option[valueProperty]}>
            {option[labelProperty]}
          </option>
        ))}
      </select>
    </div>
  );
};
