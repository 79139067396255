//label components
import {
  DateLabelWithLabel,
  TextWithLabel,
} from "../../../../../SharedComponents/FormComponents/FormLabel";

const Signed = ({ person, contractInfo }) => {
  const { FirstName = "", LastName = "" } = person;
  const { DateSigned = "" } = contractInfo;

  return (
    <div>
      <TextWithLabel
        label='Signed by'
        displayValue={FirstName + " " + LastName}
      />
      <DateLabelWithLabel label='Signed on' displayValue={DateSigned ?? ""} />
    </div>
  );
};
export default Signed;
