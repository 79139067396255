import React, {useState} from 'react'

const ConfigVersionsListToggleWrapper = ({ children }) => {
    const [toggle, setToggle] = useState(false)
    const onClickHandler = (e) => {
        e.preventDefault();
        setToggle(!toggle)
    }
    return (
        <>
            <button
                className="formListItemDivToggleShowHideButton" 
                onClick={(e)=> onClickHandler(e)}>{toggle === true ? "Hide" : "Show"}</button>
            {toggle === true ? 
                <>
                    {children}
                </> : <></>}
        </>
    )
}

export default ConfigVersionsListToggleWrapper