export const CustomPinMarker = (colour, label) => {
  // Create SVG dynamically with the specified colour
  const markerIcon = {
    url: `data:image/svg+xml;charset=UTF-8,
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='25'>
        <path fill='${"%23" + colour}' stroke='%23000000' stroke-width='1.5' 
        d='M16 0C9.373 0 5 2 5 12c0 10 11 20 11 20s11-10 11-20c0-10-4.373-12-11-12z'/>
     
        <text x='50%' y='35%' text-anchor='middle' alignment-baseline='middle' fill='black' font-family='Calibri' font-size='12'>
            ${label}
        </text>
      </svg>`,
  };

  return markerIcon;
};
