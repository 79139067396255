import { useState, useEffect } from "react";

//api calls
import {
  getLatestContract,
  savePaymentDays,
  unsignContract,
} from "./Transactions";

//page components
import ContractText from "./SectionComponents/ContractText";
import Signed from "./SectionComponents/Signed";

//button components
import { Button } from "../../../../SharedComponents/ButtonComponents/Button";

//functions
import { handlePrintClick } from "./Utils";
import { IntegerInputWithLabel } from "../../../../SharedComponents/InputComponents/IntegerInput";
import { handleInputChange } from "../../../../SharedComponents/Utils/InputUtils";

//styling
import "../../../../SharedComponents/Styles/SharedGenericStyles.css";

//page management
import { CallStates } from "../../../../SharedComponents/AppStates/Utils";
import PageState from "../../../../SharedComponents/AppStates/PageState";

//modal components
import Modal from "../../../../SharedComponents/ModalComponents/Modal";
import { small } from "../../../../SharedComponents/ModalComponents/Utils";

const Contract = ({ organisationID, api }) => {
  const [director, setDirector] = useState(null);
  const [contractInfo, setContractInfo] = useState(null);
  const [associatePractice, setAssociatePractice] = useState(null);

  const [callState, setCallState] = useState(CallStates.INITIAL);
  const [errorMessage, setErrorMessage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(null);

  useEffect(() => {
    if (callState === CallStates.INITIAL || callState === CallStates.SAVED) {
      getLatestContract(organisationID, api, setCallState)
        .then((data) => {
          if (data.director) {
            setDirector(data.director);
          }
          if (data.contractInfo) {
            setContractInfo(data.contractInfo);
          }
          if (data.associatePractice) {
            setAssociatePractice(data.associatePractice);
          }
          setCallState(CallStates.LOADED);
        })
        .catch((e) => {
          setErrorMessage(e.message);
          setCallState(CallStates.ERROR);
        });
    }
  }, [callState]);

  const cancelPaymentTermsEdit = (event) => {
    event.preventDefault();
    setModalIsOpen(false);
  };

  const handleSavePaymentDaysClick = (event) => {
    event.preventDefault();
    savePaymentDays(associatePractice, api, setCallState)
      .then((r) => {
        setModalIsOpen(false);
        setCallState(CallStates.SAVED);
      })
      .catch((e) => {
        setModalIsOpen(false);
        setErrorMessage(e.message);
        setCallState(CallStates.ERROR);
      });
  };

  const handleUnsignContractClick = (event) => {
    event.preventDefault();
    unsignContract(contractInfo.ContractID, organisationID, api, setCallState)
      .then((r) => {
        setCallState(CallStates.SAVED);
      })
      .catch((e) => {
        setModalIsOpen(false);
        setErrorMessage(e.message);
        setCallState(CallStates.ERROR);
      });
  };

  return (
    <PageState callState={callState} errorMessage={errorMessage}>
      <div className='fade-in' style={{ padding: "4rem" }}>
        {director && contractInfo && (
          <Signed person={director} contractInfo={contractInfo} />
        )}
        <div className='clinic-profile-InLine' style={{ marginTop: "0.5rem" }}>
          <div style={{ paddingRight: "0.5rem" }}>
            <Button
              label='Print'
              handleClick={(e) => handlePrintClick(e, director, contractInfo)}
            />
          </div>
          <div>
            {director && Object.keys(director).length > 0 && (
              <Button
                label='Unsign Contract'
                handleClick={(e) => handleUnsignContractClick(e)}
              />
            )}
          </div>
        </div>

        <div style={{ marginTop: "0.5rem", marginBottom: "1rem" }}>
          <Button
            label='Edit payment days'
            handleClick={(e) => e.preventDefault()(setModalIsOpen(true))}
          />
        </div>
        {contractInfo && <ContractText contractInfo={contractInfo} />}

        <div>
          <Modal
            isOpen={modalIsOpen}
            onClose={(e) => cancelPaymentTermsEdit(e)}
            size={small}
          >
            <div style={{ padding: "2rem 1rem 0rem 1rem", width: "30rem" }}>
              {associatePractice && (
                <div>
                  <IntegerInputWithLabel
                    inputLabel='Payment days'
                    value={associatePractice.PaymentDays}
                    name='PaymentDays'
                    handleChange={(e) =>
                      handleInputChange(
                        e,
                        associatePractice,
                        setAssociatePractice
                      )
                    }
                  />
                  <Button
                    label='Save'
                    handleClick={(e) => handleSavePaymentDaysClick(e)}
                  />
                </div>
              )}
            </div>
          </Modal>
        </div>
      </div>
    </PageState>
  );
};
export default Contract;
