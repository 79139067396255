import React, { useEffect, useState } from "react";

//api calls
import { getSummary, saveSummary } from "./Transactions";

//input components
import {
  handleInputChange,
  handleInputChangeWithinArray,
} from "../../../../SharedComponents/Utils/InputUtils";

//page management
import PageState from "../../../../SharedComponents/AppStates/PageState";
import { CallStates } from "../../../../SharedComponents/AppStates/Utils";

//styling
import "../../../../SharedComponents/Styles/SharedGenericStyles.css";
import "../../../../SharedComponents/Styles/FadeStyles.css";
import { PartitionLine } from "../../../../SharedComponents/PageLayoutComponents/Partition";

//page components
import ClosuresAdd from "./SectionComponents/Closures/ClosuresAdd";
import OpeningTimes from "./SectionComponents/OpeningHours/OpeningTimes";
import ContactDetails from "../Contacts/SectionComponents/ContactDetails";
import ClinicDetails from "./SectionComponents/GeneralInformation/ClinicDetails";
import OnHoldDetails from "./SectionComponents/GeneralInformation/OnHoldDetails";
import Closures from "./SectionComponents/Closures/Closures";

//functions
import { removeItemByIndex } from "../../../../SharedComponents/Utils/ArrayUtils";
import { flagClinicAsSetAsDisused, validateClosure } from "./Utils";

//modal components
import Modal from "../../../../SharedComponents/ModalComponents/Modal";
import { email, small } from "../../../../SharedComponents/ModalComponents/Utils";
import EmailTemplate from "../Correspondence/SectionComponents/EmailTemplate";

//button components
import { Button } from "../../../../SharedComponents/ButtonComponents/Button";

const Summary = ({ api, facilityID }) => {
  const [facility, setFacility] = useState(null);
  const [practice, setPractice] = useState(null);
  const [practiceRankings, setPracticeRankings] = useState(null);
  const [onHoldInfo, setOnHoldInfo] = useState(null);
  const [contactDetails, setContactDetails] = useState(null);
  const [facilityHoursList, setFacilityHoursList] = useState([]);
  const [closures, setClosures] = useState([]);
  const [onHoldReasons, setOnHoldReasons] = useState([]);

  const [closureAddIsOpen, setClosureAddIsOpen] = useState(false);
  const [emailClinicIsOpen, setEmailClinicIsOpen] = useState(false);
  const [clinicSetToDisused, setClinicSetToDisused] = useState(false);

  const [callState, setCallState] = useState(CallStates.INITIAL);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (callState === CallStates.INITIAL || callState === CallStates.SAVED) {
      getSummary(api, facilityID, setCallState)
        .then((data) => {
          setFacility(data.Facility);
          setPractice(data.Practice);
          setPracticeRankings(data.PracticeRankings);
          setOnHoldInfo(data.OnHoldInfo);
          setContactDetails(data.ContactDetails);
          setFacilityHoursList(data.FacilityHoursList);
          setClosures(data.FacilityClosureDateList);
          setOnHoldReasons(data.OnHoldReasons.Reasons);

          setCallState(CallStates.LOADED);
        })
        .catch((e) => {
          setErrorMessage(e.message);
          setCallState(CallStates.ERROR);
        });
    }
  }, [callState]);

  const handleClosureAdd = (closure) => {
    if (validateClosure(closure, closures)) {
      setClosures([...closures, closure]);
      setClosureAddIsOpen(false);
    } else {
      alert("Closure overlaps with existing closure.");
      return;
    }
  };

  const handleSendEmailClick = (event) => {
    event.preventDefault();
    setEmailClinicIsOpen(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(clinicSetToDisused) {
        const confirmed = window.confirm('This clinic is set to disused, we are unable to refer patients to disused clinics, continue?');
        if (!confirmed) {
            return;
        }
    }
    setCallState(CallStates.SAVING);
    const summary = {
      Facility: facility,
      Practice: practice,
      ContactDetails: contactDetails,
      OnHoldInfo: onHoldInfo,
      FacilityHoursList: facilityHoursList,
      FacilityClosureDateList: closures,
    };
    saveSummary(api, summary, setCallState).catch((e) => {
      setErrorMessage(e.message);
      setCallState(CallStates.ERROR);
    });
  };

  return (
    <PageState callState={callState} errorMessage={errorMessage}>
      <form
        onSubmit={(event) => handleSubmit(event)}
        className='fade-in'
        style={{ padding: "2rem" }}
      >
        <div style={{ alignContent: "center" }}>
          <div className='container-50' style={{}}>
            <div>
              {facility && practice && onHoldInfo && practiceRankings && (
                <div>
                  <div className='padding2'>
                    <ClinicDetails
                      facility={facility}
                      practice={practice}
                      practiceRankings={practiceRankings}
                      handleFacilityChange={(event) =>{
                        flagClinicAsSetAsDisused(event, setClinicSetToDisused);
                        handleInputChange(event, facility, setFacility);
                        }
                      }
                      handlePracticeChange={(event) =>
                        handleInputChange(event, practice, setPractice)
                      }
                    />
                  </div>
                  <div className='padding2'>
                    <OnHoldDetails
                      facility={facility}
                      practice={practice}
                      onHoldInfo={onHoldInfo}
                      onHoldReasons={onHoldReasons}
                      handleFacilityChange={(event) =>
                        handleInputChange(event, facility, setFacility)
                      }
                      handlePracticeChange={(event) =>
                        handleInputChange(event, practice, setPractice)
                      }
                      handleOnHoldInfoChange={(event) =>
                        handleInputChange(event, onHoldInfo, setOnHoldInfo)
                      }
                    />
                  </div>
                </div>
              )}
            </div>
            <div>
              {facility && practice && contactDetails && (
                <div className='padding2'>
                  <ContactDetails
                    showSendEmailButton={true}
                    handleSendEmailButtonClick={(e) => handleSendEmailClick(e)}
                    contactDetails={contactDetails}
                    showWebAddress={true}
                    handleContactDetailsChange={(details) =>
                      setContactDetails(details)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <PartitionLine style={{ padding: "2rem 0rem" }} />

        <div className='clinic-profile-flex-container clinic-profile-headers'>
          {facilityHoursList && (
            <div style={{ padding: "0rem 2rem" }}>
              <h3>Opening times</h3>
              <OpeningTimes
                openingHours={facilityHoursList}
                handleChange={(event, index) =>
                  handleInputChangeWithinArray(
                    event,
                    facilityHoursList,
                    setFacilityHoursList,
                    index
                  )
                }
              />
            </div>
          )}
          <div style={{ padding: "0rem 2rem" }}>
            {closures && (
              <>
                <h3>Clinic holiday dates</h3>
                <Closures
                  closures={closures ?? []}
                  handleRemove={(event, index) => {
                    if (closures[index].Unsaved !== undefined) {
                      removeItemByIndex(closures, setClosures, index);
                    } else {
                      handleInputChangeWithinArray(
                        event,
                        closures,
                        setClosures,
                        index
                      );
                    }
                  }}
                />
              </>
            )}
            <div style={{ paddingTop: "1rem" }}>
              <Button
                label='Add closure'
                handleClick={() => setClosureAddIsOpen(true)}
              />
            </div>
          </div>
        </div>
        <div style={{ padding: "2rem" }}>
          <Button handleClick={() => {}} label='Save summary' type='submit' />
        </div>
      </form>

      <Modal
        isOpen={closureAddIsOpen}
        onClose={() => setClosureAddIsOpen(false)}
        saveButtonLabel='Add'
        size={small}
      >
        <div style={{ paddingTop: "1rem" }}>
          <ClosuresAdd
            facilityID={facilityID}
            api={api}
            handleAdd={(closure) => handleClosureAdd(closure)}
          />
        </div>
      </Modal>

      <Modal
        isOpen={emailClinicIsOpen}
        onClose={() => setEmailClinicIsOpen(false)}
        size={email}
      >
        {contactDetails && (
          <div
            style={{
              padding: "2rem 1rem 0rem 1rem",
            }}
          >
            <EmailTemplate
              recipients={[contactDetails.EmailAddress]}
              setCallState={setCallState}
              setErrorMessage={setErrorMessage}
              closeModal={() => setEmailClinicIsOpen(false)}
              facilityID={facilityID}
              api={api}
            />
          </div>
        )}
      </Modal>
    </PageState>
  );
};
export default Summary;
