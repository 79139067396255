import { useState, useEffect } from "react";

//button components
import { Button } from "../../../../../../SharedComponents/ButtonComponents/Button";

//input components
import { DateInputWithLabel } from "../../../../../../SharedComponents/InputComponents/DateInput";

//page management
import PageState from "../../../../../../SharedComponents/AppStates/PageState";
import { CallStates } from "../../../../../../SharedComponents/AppStates/Utils";

const ClosuresAdd = ({ facilityID, handleAdd }) => {
  const [closureToAdd, setClosureToAdd] = useState({
    ActionFlag: 2,
    ClosureReason: null,
    DateFrom: null,
    DateTo: null,
    FacilityId: facilityID,
    Unsaved: true,
  });
  const [dateFrom, setDateFrom] = useState(""); //empty string makes the picker default to today when clicked and show's nothing, otherwise
  const [dateTo, setDateTo] = useState("");

  const [callState, setCallState] = useState(CallStates.LOADED);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setClosureToAdd({ ...closureToAdd, DateFrom: dateFrom });
  }, [dateFrom]);

  useEffect(() => {
    setClosureToAdd({ ...closureToAdd, DateTo: dateTo });
  }, [dateTo]);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleAdd(closureToAdd);
  };

  return (
    <PageState errorMessage={errorMessage} callState={callState}>
      <form onSubmit={(event) => handleSubmit(event)}>
        <DateInputWithLabel
          inputLabel='Date from'
          name='DateFrom'
          value={dateFrom}
          handleChange={(e) => setDateFrom(e.target.value)}
          required={true}
        />
        <DateInputWithLabel
          inputLabel='Date to'
          name='DateTo'
          value={dateTo}
          handleChange={(e) => setDateTo(e.target.value)}
          required={true}
        />
        <Button label='Add' handleClick={() => {}} type='submit' />
      </form>
    </PageState>
  );
};
export default ClosuresAdd;
