export const firstItemToEndOfArray = (array) => {
  if (array.length >= 1) {
    const firstItem = array.shift();
    array.push(firstItem);
  }
  return array;
};

export const lastItemToStartOfArray = (array) => {
  if (array.length >= 1) {
    const lastItem = array.pop();
    array.unshift(lastItem);
  }
  return array;
};

export const addItem = (initialArray, setUpdatedArray, itemToAdd) => {
  const updatedArray = [...initialArray];
  updatedArray.push(itemToAdd);

  setUpdatedArray(updatedArray);
};

export const removeItemByIdentifierKey = (
  initialArray,
  setUpdatedArray,
  itemToRemove,
  identifierKey
) => {
  const updatedArray = initialArray.filter(
    (item) => item[identifierKey] !== itemToRemove?.[identifierKey]
  );
  setUpdatedArray(updatedArray);
};

export const removeItemByIndex = (
  initialArray,
  setUpdatedArray,
  indexToRemove
) => {
  // Check if the index is within valid bounds
  if (indexToRemove >= 0 && indexToRemove < initialArray.length) {
    // Create a copy of the initial array
    const updatedArray = [...initialArray];

    // Use splice to remove the item at the specified index
    updatedArray.splice(indexToRemove, 1);

    // Update the state with the new array
    setUpdatedArray(updatedArray);
  } else {
    // Handle the case where the index is out of bounds (e.g., show an error)
    console.error("Index is out of bounds.");
  }
};
