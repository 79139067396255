import { CallStates } from "../../../../SharedComponents/AppStates/Utils";

export const getAmenities = (api, facilityID, setCallState) => {
  return api
    .transact("GetClinicAmenities", { facilityID: facilityID })
    .then((response) => {
      setCallState(CallStates.SUCCESS);
      return response.apiResult;
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const saveAmenities = (
  api,
  amenities,
  facility,
  practice,
  setCallState
) => {
  return api
    .transact("SaveClinicAmenities", {
      Amenities: amenities,
      Facility: facility,
      Practice: practice,
    })
    .then((response) => {
      setCallState(CallStates.SAVED);
    })

    .catch((e) => {
      return Promise.reject(e);
    });
};
