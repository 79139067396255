import React, { useState } from "react";
import * as XLSX from "xlsx"; 
import {
  saveExcelData,
  fetchFailedInvoices,
  DownloadFailedInvoices,
} from "./Transactions";
import { CallStates } from "../../../components/SharedComponents/AppStates/Utils";

const FailedInvoiceStep = ({
  api,
  failedRows,
  setFailedRows,
  setCurrentStep,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isRetrySuccess, setRetrySuccess] = useState(false);
  const [callState, setCallState] = useState(CallStates.INITIAL);
  const [failedToProcessIds, setFailedToProcessIds] = useState([]);
  const [downloadList, setDownloadList] = useState([]);

  const handleCheckboxChange = (InvoiceListBulkPaymentID) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(InvoiceListBulkPaymentID)) {
        return prevSelectedRows.filter((id) => id !== InvoiceListBulkPaymentID);
      } else {
        return [...prevSelectedRows, InvoiceListBulkPaymentID];
      }
    });
    setErrorMessage(null);
  };

  const handleSelectAllChange = () => {
    if (selectedRows.length === failedRows.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(failedRows.map((row) => row.InvoiceListBulkPaymentID));
    }
    setErrorMessage(null);
  };

  const handleSubmitSelected = (event) => {
    event.preventDefault();
    const selectedFailedRows = failedRows.filter((row) =>
      selectedRows.includes(row.InvoiceListBulkPaymentID)
    );
    const summary = { invoicelist: selectedFailedRows, module: "Retry" };

    saveExcelData(api, summary, setCallState)
      .then(() => {
        fetchFailedInvoices(api).then((response) => {
          const failedItems = response.InvoiceListItems;
          if (failedItems.length > 0) {
            setFailedRows(failedItems);

            const failedIds = selectedFailedRows
              .map((row) => row.InvoiceListBulkPaymentID)
              .filter((id) =>
                failedItems.some(
                  (failedItem) => failedItem.InvoiceListBulkPaymentID === id
                )
              );

            setFailedToProcessIds(failedIds);
            setRetrySuccess(failedIds.length === 0);
          } else {
            setFailedRows([]);
            setFailedToProcessIds([]);
            setRetrySuccess(true);
            setErrorMessage([]);
          }
        });
      })
      .catch(() => {
        setErrorMessage("Failed to retry processing selected invoices.");
        setRetrySuccess(false);
      });
  };

  const handleDeleteSelected = (InvoiceListBulkPaymentID) => {
    const selectedFailedRows = failedRows.filter((row) =>
      selectedRows.includes(row.InvoiceListBulkPaymentID)
    );
    const summary = { invoicelist: selectedFailedRows, module: "Delete" };

    saveExcelData(api, summary, setCallState)
      .then(() => {
        fetchFailedInvoices(api).then((response) => {
          const failedItems = response.InvoiceListItems;
          if (failedItems.length > 0) {
            setFailedRows(failedItems);
            setRetrySuccess(failedItems.length === 0);
          } else {
            setFailedRows([]);
            setFailedToProcessIds([]);
            setRetrySuccess(true);
            setErrorMessage([]);
          }
        });
      })
      .catch(() => {
        setErrorMessage("Failed to delete selected invoices.");
        setRetrySuccess(false);
      });
  };

  const handleDeleteRow = (InvoiceListBulkPaymentID) => {
    const updatedSelectedRows = selectedRows.includes(InvoiceListBulkPaymentID)
      ? selectedRows.filter((id) => id !== InvoiceListBulkPaymentID)
      : [...selectedRows, InvoiceListBulkPaymentID];
    setSelectedRows(updatedSelectedRows);

    const selectedFailedRows = failedRows.filter((row) =>
      updatedSelectedRows.includes(row.InvoiceListBulkPaymentID)
    );

    const summary = { invoicelist: selectedFailedRows, module: "Delete" };

    saveExcelData(api, summary, setCallState)
      .then(() => {
        fetchFailedInvoices(api).then((response) => {
          const failedItems = response.InvoiceListItems;
          if (failedItems.length > 0) {
            setFailedRows(failedItems);
            setRetrySuccess(failedItems.length === 0);
          } else {
            setFailedRows([]);
            setFailedToProcessIds([]);
            setRetrySuccess(true);
            setErrorMessage(null);
          }
        });
      })
      .catch(() => {
        setErrorMessage("Failed to delete selected invoices.");
        setRetrySuccess(false);
      });
  };

  const handleDownloadExcel = () => {
    DownloadFailedInvoices(api).then((response) => {
      if (response.InvoiceListItems.length > 0) {
        setDownloadList(response.InvoiceListItems);
      } else {
        setErrorMessage("Failed to download invoice list!");
      }
    });

    const workbook = XLSX.utils.book_new();
    const worksheetData = downloadList.map((row) => ({
      "Invoice ID": row.InvoiceId,
      Amount: row.Amount,
      Status: row.Status,
    }));
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Failed Invoices");
    XLSX.writeFile(workbook, "Failed_Invoices.xlsx");
  };

  return (
    <div className="failed-invoices-container">
      <p>
        <a
          href="#"
          className="invoice-table-link"
          onClick={(e) => {
            e.preventDefault();
            setCurrentStep("upload");
          }}
        >
          Go to Upload Page
        </a>
      </p>

      <h2>Failed Invoices</h2>
      {failedRows.length > 0 ? (
        <form onSubmit={handleSubmitSelected}>
          <table className="invoice-table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectedRows.length === failedRows.length}
                    onChange={handleSelectAllChange}
                  />
                </th>
                <th>Invoice Number</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {failedRows.map((row) => (
                <tr
                  key={row.InvoiceListBulkPaymentID}
                  className={
                    failedToProcessIds.includes(row.InvoiceListBulkPaymentID)
                      ? "invoice-failed-row"
                      : ""
                  }
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(
                        row.InvoiceListBulkPaymentID
                      )}
                      onChange={() =>
                        handleCheckboxChange(row.InvoiceListBulkPaymentID)
                      }
                    />
                  </td>
                  <td>{row.InvoiceId}</td>
                  <td>{row.Amount}</td>
                  <td>{row.Status}</td>
                  <td>
                    <button
                      className="invoice-table-button"
                      type="button"
                      onClick={() =>
                        handleDeleteRow(row.InvoiceListBulkPaymentID)
                      }
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="retry-button-container">
            <button className="invoice-table-button" type="submit">
              Retry Selected
            </button>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button
              className="invoice-table-button"
              type="button"
              onClick={handleDeleteSelected}
            >
              Delete Selected
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button
              className="invoice-table-button"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handleDownloadExcel();
              }}
            >
              Download as Excel
            </button>
          </div>

          {isRetrySuccess && (
            <div className="invoice-bulk-message-success">
              Selected invoices processed successfully!
            </div>
          )}
          {errorMessage && (
            <div className="invoice-bulk-error-message">{errorMessage}</div>
          )}
        </form>
      ) : (
        <div className="invoice-bulk-message-success">
          <p>No failed invoices to retry.</p>
        </div>
      )}
    </div>
  );
};

export default FailedInvoiceStep;
